.side-transition-container{
    background-color: white;
    top:0;
    z-index: 10000;
    /*height: 100%;*/
    /*width: 100%;*/
    overflow: auto;
    transition: all 0.4s;
    box-shadow:0 2px 8px rgba(0, 0, 0, 0.135216) ;
}


.side-transition-container div {
    /*width: 100%;*/
    /*height: 100%;*/
}



/******** Transition animation *******/


.right-show-transition .side-transition-container{
    transform: translate3d(0px, 0px, 0px);
}
.right-hide-transition .side-transition-container{
    transform: translate3d(100% , 0px, 0px);
}

.left-show-transition .side-transition-container{
    transform: translate3d(0px, 0px, 0px);
}
.left-hide-transition .side-transition-container{
    transform: translate3d(-100% , 0px, 0px);
}


.top-show-transition .side-transition-container{
    transform: translate3d(0px, 0px, 0px);
}
.top-hide-transition .side-transition-container{
    transform: translate3d(0px , -100%, 0px);
}


.bottom-show-transition .side-transition-container{
    transform: translate3d(0px, 0px, 0px);
}
.bottom-hide-transition .side-transition-container{
    transform: translate3d(0px , 100%, 0px);
}

.side-transition-main{
    position: fixed;
    top:0;
    box-shadow:0px 2px 8px rgba(0, 0, 0, 0.135216) ;
    min-width: 100% !important;
    min-height: 100% !important;
}
.side-transition-overlay{
    overflow: hidden !important;
    position:fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    right: 0;
    bottom: 0;
    background-color: #32323288;
}

.side-transition{
    transition: all 5s;
    opacity:0;

}


.no-overflow {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
