.subscriber-container {
    display: flex;
    flex-direction: column;
    text-align: left !important;
}

.tab-subscriber {
    overflow: hidden !important;
}


th.two.wide.payment-footer-title {
    line-height: 30px;
}

.subscriber-title {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    color: #9fa2b4;
    margin-bottom: 18px;
    margin-top: 18px;
    height: 68px;
}

.empty-input {
    height: 70px;
    display: block;
}

.no-padding-subscriber {
    padding-top: 0 !important;
    padding-bottom: 4px !important;
}

.subscriber-border-bottom {
    /*border-bottom: 1px solid #979797;*/
    border-bottom: 1px solid #dfe0eb;
}

.no-btn-style {
    background-color: transparent !important;
}

.full-width-input {
    width: 100% !important;
}

.download-btn {
    margin-top: 8px !important;
    width: 100px;
    padding: 0 !important;
    height: 24px;
    display: flex !important;
    align-items: center;
    background-color: #9e94ff !important;
    border-radius: 5px !important;
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    letter-spacing: 0.2px;
    color: #ffffff !important;
    cursor: pointer;
}

.row-user {
    display: flex;
    align-items: center;
}

.download-btn > img {
    padding-right: 6px;
    padding-left: 6px;
}

.subscriber-file-input {
    position: absolute;
    left: -9999px;
    /*display: none !important;*/
}

.file-preview {
    display: flex;
}

.file-preview span {
    margin: 1rem;
}

.next-subscriber {
    width: 155px;
    height: 34px;
    background: #72f286 !important;
    border-radius: 100px !important;
    color: white !important;
}

.bottom-controller-subscriber {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.save-subscriber {
    background-color: #15a4fa !important;
}

.round-credit {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 32px;
}

span.modal-reload-credit {
    display: flex;
    margin-top: 12px;
}

.profile-page {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.profile-header {
    display: flex;
    overflow: hidden;
    min-height: 155px !important;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0778245);
}

.dark .profile-header {
    background: #333;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.0778245);
}

.dark .profile-details-items {
    color: #dfe0eb;
}

.profile-details {
    flex: 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.profile-details-items {
    display: flex;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2c2c2c;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
}

button.ui.circular.icon.button.profile-btn {
    background: #fff;
    border: none;
}

.subscriber-btn {
    background: #9e94ff !important;
    color: white !important;
}

.subscriber-btn i {
    font-weight: 200 !important;
    color: #fff !important;
    font-size: 24px !important;
}

button.ui.circular.icon.button.profile-btn:hover {
    background-color: #33333333;
}

.search-custom-dropdown {
    width: 19% !important;
    height: 35px;
    margin: 4px;
    font-size: 16px;
}

.custom-dropdown-container {
    /*min-width: 5em !important;*/
    min-height: 35px !important;
    font-size: 16px !important;
}

.custom-dropdown-main {
    min-height: 35px !important;
}

.custom-dropdown-input {
    height: 85% !important;
    padding-left: 15px !important;
}


.search-it {
    width: 19% !important;
    height: 35px !important;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-it ::placeholder {
    color: #c4c4c4 !important;
}

.search-date input {
    max-width: 100% !important;
    padding: 5px 8px !important;
}

.profile-details img {
    align-self: center;
    min-width: 120px !important;
    min-height: 120px !important;
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 50% !important;
    margin: 0 10px;
    border: 1px solid #cccccc44;
}

.profile-details img.ui.circular.image {
    /* width: 132px !important;
      height: 124px !important; */
}

.profile-info {
    position: relative;
    display: grid;
    flex: 1;
    flex-direction: column;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    z-index: 1;
    color: #ffffff;
    align-items: center;
    justify-content: space-around;
    padding-top: 1% !important;
    padding-left: 8% !important;
}

button.ui.circular.icon.button.profile-btn i {
    padding-top: 4px;
    width: 24px;
    height: 24px;
}

.profile-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.profile-btn i {
    font-weight: 200 !important;
    color: #e0e1e2;
    font-size: 24px !important;
}

.profile-credit {
    position: relative;
    display: flex;
    flex: 0.7;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    z-index: 1;
    letter-spacing: 0.2px;
    color: #444c63;
}

.profile-credit::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #dfe0eb;
    /*background-color: #9f3a38;*/
    content: '';
    z-index: -1;
    width: 200%;
    height: 200%;
    transform-origin: 50% 50%;
    transform: rotateZ(15deg);
}

.profile-credit img {
    width: 25%;
    height: 25%;
}

.profile-info::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #aeb9d6;
    content: '';
    z-index: -1;
    width: 100%;
    height: 350%;
    transform-origin: 50% 50%;
    transform: rotateZ(15deg) translate(0%, -20%);
}

.profile-phone {
    color: #7a97f4;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 992px) {
    .profile-header {
        flex-direction: column;
        min-height: 480px;
    }

    .profile-details {
        flex-direction: column;
        justify-content: space-around;
    }

    .profile-details-items {
        align-items: center;
        flex: 1;
        justify-content: space-around;
    }

    .profile-credit::before {
        transform: rotateZ(0);
    }

    .profile-info::before {
        transform: rotateZ(0);
    }

    .profile-info span {
        padding: 4px 0;
    }

    .profile-credit {
        padding: 10px;
    }
}

.ui.modal .scrolling.content {
    max-height: calc(100vh);
    overflow: auto;
}

.detail-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 480px;
}

.detail-modal-header {
    display: flex;
    justify-content: space-between;
}

.detail-modal-credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 24px; */
}

.detail-modal-date div > span:last-child {
    color: #252733;
}

.detail-modal-main.recharge-modal-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail-modal-main.recharge-modal-main .round-btn {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    width: 120px;
}

.detail-modal-main.recharge-modal-main form {
    display: flex;
    flex-direction: column;
}

.detail-modal-credit img {
    width: 80px;
    height: 80px;
}

.detail-modal-main {
    /* display: flex; */
    /* flex-direction: column; */
    min-height: 300px;
}

.detail-modal-main button {
    margin-bottom: 14px !important;
}

tfoot.sub-footer tr th {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    color: white !important;
    font-size: 18px !important;
}

.detail-modal-main-container thead tr th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* line-height: 28px; */
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4 !important;
    background: white !important;
}

.detail-modal-main-container tbody tr td {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;

    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733 !important;
}

.detail-modal-date {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.detail-modal-date h3 {
    margin-top: 0;
    margin-right: 44px;
}

.detail-modal-date div {
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    text-align: center;
    color: #9fa2b4;
}

.detail-modal-credit span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* or 56% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
    text-align: center;
}

.no-overflow-x {
    overflow-x: hidden !important;
    overflow-y: visible !important;
}

.no-bg {
    background-color: transparent !important;
}

.table-container {
    display: flex !important;
    flex-direction: column;
    flex: 1;
}

.modal-reload-header {
    display: flex;
    padding: 12px 40px;
    position: relative;
    /*min-height: 134px;*/
    width: 100%
}

.modal-reload-main.content h3 {
    margin-bottom: 36px;
    margin-top: 14px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    /* identical to box height, or 67% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
}

span.modal-reload-credit .round-credit {
    margin-top: 12px;
    margin-right: 4px;
    width: 24px;
    height: 24px;
}

button.ui.button.modal-btn-save {
    margin-top: 68px;
    margin-left: 45px;
    background: #9e94ff !important;
    border-radius: 100px !important;
    width: 200px;
    height: 40px;
    color: white;
}

.modal-close-btn {
    color: #979797 !important;
    position: absolute;
    right: 0;
    top: 0;
    font-family: Montserrat, serif !important;
    background-color: transparent !important;
    font-size: 24px !important;
    padding: 0 !important;
}

.modal-reload-complex {
}

.modal-reload-complex {
    /* flex: 1; */
    display: flex;
}

.modal-reload-user {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

span.modals-reload-name {
    padding-top: 12px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    /* identical to box height, or 67% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.modal-reload-main.content {
    display: flex;
    justify-content: center !important;
    text-align: center;
    align-items: center !important;
    width: 80% !important;
    margin: auto;
}

tr.payment-row td div {
    white-space: pre-wrap !important;
}

.modal-reload-date {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    justify-content: flex-end;
}

.modal-reload-user img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

.modal-reload-complex img {
    width: 70px;
    height: 70px;
}

span.modal-reload-complex-name {
    padding-left: 8px;
}

span.modal-reload-complex-name h3 {
    margin-bottom: 0px;
    margin-top: 12px;
}

span.modal-reload-slug {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4;
}

span.modal-reload-date {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    text-align: center;
}

.textArea-input-bg {
    width: 50%;
    background: #eef1f7;
    border-radius: 5px;
    margin-bottom: 24px;
    font-size: 16px !important;
    min-height: 120px;
}

.info-subscriber {
    /*margin-left: 24px;*/
}

.payment-border {
    padding: 12px;
    border-bottom: 1px solid #dfe0eb;
}

.payment-date-container {
    display: flex;
    flex-direction: column;
}

.payment-credits {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-reload-complex.payment-credits > div {
    display: flex;
}

.modal-reload-complex.payment-credits .modal-reload-credit {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}

.modal-reload-complex.payment-credits .modal-reload-credit > span {
    width: 34px;
    height: 34px;
    margin-top: 18px;
    margin-right: 45px;
}

tr.payment-table-header th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    background-color: transparent !important;
    border: none !important;
    /* identical to box height */

    letter-spacing: 0.2px;

    color: #606060 !important;
}

td.payement-index {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 20px;
    /* identical to box height, or 67% */

    text-align: center;
    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.deadlines-bg {
    border-radius: 4px;
    background-color: #eaeaea3b;
    margin-top: 8px;
}

tr.payment-table-header {
    margin-bottom: 8px !important;
}

.deadlines-padding .form {
    margin-bottom: 0 !important;

}

.deadlines-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

tr.payment-row.payment-row-loading td {
    background-color: #fff !important;
    border-color: #fff !important;
}

tr.payment-row.payment-row-loading td:first-child, tr.payment-row.payment-row-loading td:last-child {
    border: none !important;
    border-color: #fff !important;
}

.ui.segment.no-shadow-box {
    box-shadow: none !important;
    border: none !important;
}

tr.payment-row {
    background: #eef1f7;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;

    border-radius: 10px;
    margin-bottom: 26px !important;
}

.payment-row-red {
    background: #ff000030 !important;
}

tr.payment-row.payment-row-loading {
    background-color: white !important;
}

tr.payment-row td {
    padding: 4px !important;
    /* max-height: 10px !important; */
    height: 42px !important;
}

td.payement-index {
    width: 25px !important;
}

.payments-container table.ui.fixed.table {
    /* min-height: 420px !important; */
    border: none;
    border-collapse: separate;
    border-spacing: 0 24px;
    border-radius: 10px !important;
}

tr.payment-row td > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 14px;
    /*text-align: center;*/
    width: 100%;
    height: 100%;
    max-height: 60px !important;
    overflow: hidden;
    vertical-align: middle !important;
    /*margin-bottom: 8px;*/
}

tr.payment-row td {
    height: 60px !important;
    border: none !important;
    line-height: 20px !important;
    vertical-align: middle !important;
}

tr.payment-row {
    margin-bottom: 14px !important;
    /*background: #eef1f7 !important;*/
    border-radius: 10px !important;
}

tfoot.payment-footer {
    width: 100%;
}

tr.payment-footer-row {
    border-radius: 10px !important;
}

tr.payment-footer-row th {
    padding: 20px !important;
    border: none !important;
    background: #9e94ff !important;

    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 20px;

    text-align: center;
    letter-spacing: 0.2px;

    text-overflow: unset !important;

    color: #ffffff !important;
}

.no-overflow {
    overflow: hidden !important;
}

.modal-reload-container {
    min-height: 45% !important;
}

.ui.large.modal.transition.visible.active.modal-reload-container {
    display: flex !important;
    flex-direction: column;
    min-height: 80vh !important;
}

.modal-reload-container .content {
    display: flex !important;
    flex: 1;
    flex-direction: column;
}

.modal-reload-main.content .input-bg {
    width: 100% !important;
}

/*.modal-reload-container tr.payment-row{*/
/*    transform: scale(1);*/
/*}*/

.payments-container .table-container {
    margin-top: 0 !important;
    display: flex !important;
    flex: 1;
}

span.label-round-credit {
    padding: 4px 8px;
    border-radius: 16px;
    color: white;
}

.regulation-modal-container {
    flex: 1;
    display: flex;
    overflow: visible !important;
}

.regulation-modal-input.custom-dropdown-container {
    flex: .86;
}

.regulation-modal-cell {
    overflow: visible !important;
}

tr.payment-row td:first-child {
    /* border: 4px solid #eef1f7 !important; */
    border-radius: 5px 0 0 5px !important
}


tr.payment-row td:last-child {
    /* border: 4px solid #eef1f7 !important; */
    border-radius: 0 5px 5px 0 !important
}

.regulation-input img.filter-img {
    -webkit-filter: invert(100%) !important;
    filter: invert(100%) !important;
}

.regulation-modal-check {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.regulation-modal-container .form {
    margin-bottom: 0 !important;
    flex: 1;
}

.subscriber-alert-msg {
    font-size: 1rem;
    display: flex;
    margin-top: -3rem;
    margin-bottom: 2rem;
    color: #db282899;
}

@media screen and (max-width: 800px) {
    .modal-reload-header {
        flex-direction: column;
        align-items: center;
    }

    .modal-reload-complex,
    .modal-reload-user,
    .payment-date-container {
        padding: 14px;
    }

}

@media screen and (max-width: 991px) {
    .computer-only {
        background-color: red;
        display: none !important;
    }
}