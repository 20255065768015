@media screen and (max-width: 1024px) { 
    .content-main {
        grid-template-columns: 110px 110px auto;
    }
    
    .stat-nav-bar {
        font-size: 12px !important;
        line-height: 14px;
        margin: 0px 1px;
        padding: 6px 5px;
    }

    .stat-bloc {
        width: 110px;
        padding: 5px;
    }

    .stat-client {
        font-size: 10px;
        line-height: 18px;
    }

    .stat-client .image {
        width: 14px;
    }

    .stat-article {
        font-size: 10px;
        line-height: 18px;
    }
    /*********************Menus***************/
    .main-menu-item {
        font-size: 9px !important;
        line-height: 12px !important;
        padding: 5px !important;
        height: 50px;
    }
    
    .main-menu-item .image {
        width: 2.4em;
        height: 2.4em;
    }

    .main-menu-item-bottom{
        height: 44px !important;
    }

    .main-menu-item-bottom .image {
        width: 2.3em !important;
        height: 2.3em !important;
    }

    .child-menu-item {
        height: 49px;
    }

    .child-menu-item span {
        font-size: 14px;
        line-height: 16px;
    }

    .content-menu-item {
        width: 32.5%;
        margin: 1px !important;
        padding: 1px !important;
    }

    .content-menu-item span {
        font-size: 12px;
        line-height: 11px;
    }

    /*************Ticket****************************/
    .ticket-info {
        font-size: 9px;
        line-height: 12px;
    }

    .ticket-avatar {
        width: 35px !important;
        height: 35px !important;
    }

    .ticket-info .image {
        width: 25px;
    }

    .ticket-content-header {
        padding: 0% 1%;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .close-btn{
        font-size: .6878rem !important;
    }

    /************Ticket accordion**************/
    .accordion-label {
        font-size: 10px;
        line-height: 12px;
    }

    .qty label{
        font-size: 10px !important;
    }

    .badge-qte input {
        width: 18px;
        height: 18px;
        font-size: 10px !important;
    }

    .badge-qte-minus {
        margin: 0 2px !important;
        padding: 2px !important;
        font-size: .68571429rem !important;
    }

    .badge-qte-plus {
        margin: 0 2px !important;
        padding: 2px !important;
        font-size: .68571429rem !important;
    }
    .accordion-container div{
        display: flex;
    }

    .ticket-accord-item {
        width: 32%;
        font-size: 9px !important;
        line-height: 10px !important;
        height: 50px !important;
    }

    i.edit.icon.ticket-accord-item-edit {
        font-size: 12px !important;
    }

    .ticket-accord-item-remise {
        width: 20px;
        font-size: 8px !important;
    }
    .ticket-accord-item-remise {
        width: 20px;
        top: 56% !important;
        left: 1% !important;
        font-size: 8px !important;
    }

    .ticket-accord-item-remise .image {
        bottom: 11px !important;
        left: 4% !important;
    }

    .accordion-discount {
        width: 28px;
        font-size: 9px !important;
    }

    .accordion-discount img {
        width: 24px !important;
    }

    .ui.popup {
        font-size: 10px;
    }

    .participant-btn {
        padding: 16px !important;
        font-size: 14px !important;
        margin-left: 10% !important;
    }

    .participant-btn .plus {
        top: 3px;
        left: 6px;
    }

    .participant-btn .minus {
        bottom: 5px;
        right: 7px;
    }

    .participant-btn .separator {
        font-size: 45px !important;
        left: 16px;
        top: 4px;
    }

    /*****************************/
    .ticket-grey-block {
        font-size: 12px !important;
    }

    .ticket-grey-block-price {
        font-size: 12px !important;
    }

    .payment-action-list {
        font-size: 10px;
        line-height: 12px;
    }

    .payment-remainder-label {
        font-size: 10px;
        line-height: 12px;
    }

    .payment-remainder {
        font-size: 10px;
        line-height: 12px;
    }

    .ticket-menu-item {
        font-size: 10px !important;
        line-height: 10px !important;
        padding: 1px !important;
    }

    .ticket-menu-item-img {
        height: 20px !important;
    }

    .btn-grey-off {
        padding: 9px !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .btn-grey-off img {
        height: 18px !important;
    }

    .btn-grey-on {
        padding: 9px !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .btn-grey-on img {
        height: 18px !important;
    }

    /*************Modal********/
    .success-modal {
        width: 250px !important;
        padding: 10px !important;
    }

    .success-modal span {
        font-size: 46px !important;
        line-height: 70px !important;
    }

    .advance-modal{
        width: 365px !important;
        padding: 10px !important;
    }

    .advance-modal span {
        font-size: 40px !important;
        line-height: 70px !important;
    }

    /********ticket details ******/
    .ticket-menu div {
        display: flex;
    }

    .details-btn {
        width: 24% !important;
        height: 40px !important;
        font-size: 12px !important;
        line-height: 18px;
        margin: 14px 2px !important;
    }

    /*********Payment & discount*****************/
    .calculator-container {
        width: 69%;
        margin: 0px auto 4px;
        padding: 0 !important;
    }

    .calculator-btn {
        font-size: 10px;
        margin: 1px;
        padding: 3px;
    }

    .calculator-display {
        height: 30px;
        margin: 2px;
    }
    
    .calculator-input {
        font-size: 14px;
        height: 10px !important;
    }
    
    .payment-title {
        height: 6vh;
        font-size: 24px;
        line-height: 30px;
    }

    .payment-sub-title {
        font-size: 12px;
        padding-left: 24px;
    }

    .payment-content-left {
        padding: 1% !important;
    }

    .payment-content-left-bottom {
        padding: 0 17% !important;
    }

    .solde-btn {
        font-size: 10px !important;
        margin-bottom: 18px !important;
    }

    .payment-content-total {
        font-size: 14px;
        line-height: 20px;
        padding-top: 10px !important;
        margin: 5% !important;
    }

    .discount-content-quantity {
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: rgba(11, 108, 255, 0.65);
        padding-top: 10px !important;
        margin: 8% 0px !important;
    }
    /**************/

    .payment-content-right {
        padding-left: 12% !important;
    }
    .payment-block-input-group{
        height: 62vh;
    }

    .payment-block-input {
        width: 165px !important;
        height: 38px;
        margin: 3px !important;
        font-size: 10px;
    }
    .margin-block-input{
        margin-top: 8px !important;
    }

    .orange-block {
        margin-top: 10px !important;
    }

    .payment-block-input-left input {
        width: 70px;
        font-size: 12px !important;
        height: 30px;
    }
    
    .payment-block-input-right {
        width: 55% !important;
        padding: 5px 0px;
    }

    .payment-block-input-right .image {
        padding-bottom: 3px;
        height: 23px !important;
    }

    .payment-btn-active {
        font-size: 14px !important;
        margin: 1% !important;
    }

    .payment-btn-desactive {
        font-size: 14px !important;
        margin: 1% !important;
    }

    /************ Ticket list ************/
    .ticket-list-header {
        font-size: 24px;
        line-height: 30px;
    }

    .ticket-list-item {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-reservation {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-details-reservation {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-details {
        font-size: 10px;
        line-height: 12px;
    }

    .ticket-information{
        font-size: 10px !important;
        line-height: 12px;
    }

    .ticket-information span{
        font-size: 10px !important;
        line-height: 12px;
    }
    
    .ticket-sub-family {
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .ticket-sub-family .image{
        margin: 7% !important;
    }
    
    .ticket-activity span {
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .ticket-food span{
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .center-img img{
        height: 22px !important;
    }

    .ticket-buttons button {
        font-size: 9px !important;
        line-height: 12px !important;
        width: 56px !important;
        height: 24px !important;
    }
    
    .search-block {
        margin: 20px 10px;
    }

    .ticket-amount{
        font-size: 10px !important;
        
    }

    .ticket-price .image {
        height: 22px !important;
    }

    .ticket-price {
        font-size: 10px !important;
        line-height: 12px !important;
    }
    
    .credits-list-item {
        font-size: 12px;
    }

    .advance-list-item {
      font-size: 12px;
    }
    .advance-list-item-details {
        font-size: 12px;
    }
    .credit_account-list-item {
        font-size: 12px;
    }

    /*******client list******************/
    .client-list-header {
        font-size: 24px;
        line-height: 30px;
    }

    .client-list-body p {
        font-size: 12px;
        line-height: 16px;
    }

    .search-bloc input {
        font-size: 12px !important;
    }

    .add-client {
        font-size: 10px !important;
    }

    .add-client .button {
        margin-right: 1%;
        font-size: .65714286rem !important;
    }

    .search-content {
        margin: 1% auto !important;
        width: 90%;
    }

    .client-row {
        margin: 0px auto;
        font-size: 12px;
        line-height: 14px;
        padding: 0px 25px;
    }

    .client-row span {
        margin: 0px 1%;
        padding: 0px;
    }

    .client-name {
        font-size: 12px;
        line-height: 14px;
    }

    .client-btn-detail {
        font-size: 10px !important;
    }

    .add-client-block {
        margin: 0px auto;
        width: 85%;
    }

    .client-green-btn {
        font-size: 16px !important;
    }

    /**************forms***************/
    .form-label {
        font-size: 12px !important;
    }

    .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
        font-size: 12px;
    }

    .custom-dropdown-container {
        font-size: 12px !important;
        min-width: 5em !important;
    }
    /*******Modal************/
    .player-informations {
        min-height: 320px;
    }

    .add-player {
        font-size: 12px !important;
    }

    .amount-bloc .button {
        font-size: 12px !important;
    }

    .amount-bloc {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-left: 8%;
        padding: 14px 0px;
    }
    
    span.amount-bloc.amount-bloc-reload  button.ui.button.btn-inline-icon {
        margin-left: 18%;
        padding: 0px 10px;
        width: 90px;
    }


}


@media screen and (max-width: 820px) {

}