@import url('https://fonts.googleapis.com/css2?family=Tomorrow:wght@500&display=swap');

.error-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 480px 480px;

}

.error-container p {
   margin-top: 220px !important;
  font-family: 'Tomorrow','Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 34px;
  font-weight: 600;
}

/*.error-logo:after {*/
/*    content: "";*/
/*    position: fixed;*/
/*    top: 228px;*/
/*    right: 416px;*/
/*    width: 84px;*/
/*    height: 84px;*/
/*    border-radius: 50%;*/
/*    background: #db2828;*/
/*    z-index: 1;*/
/*    box-shadow: 0 0 8px 8px #33333311;*/
/*}*/

/*.error-logo:before {*/
/*    content:"";*/
/*    position: fixed;*/
/*    top: 257px;*/
/*    right: 449px;*/
/*    width: 180px;*/
/*    height: 56px;*/
/*    border-radius: 24px;*/
/*    background: #db2828;*/
/*    z-index: -1;*/
/*}*/
/*span.error-close {*/
/*    position: fixed;*/
/*    top: 257px;*/
/*    right: 449px;*/
/*    color: white;*/
/*    font-size: 21px;*/
/*    font-weight: 600;*/
/*    z-index: 2;*/
/*}*/

/*.error-container:after{*/
/*    content:"-";*/
/*    font-size: 48px;*/
/*    font-weight: 600;*/
/*    color: #DB2828;*/
/*    width: 42px;*/
/*    height:42px;*/
/*    border-radius: 50%;*/
/*    text-align: center;*/
/*    vertical-align: middle;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: fixed;*/
/*    background-color: white;*/
/*    top:0;*/
/*    right:0;*/
/*}*/
