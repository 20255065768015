
.notification-container {
    z-index: 80;
    position: absolute;
    top: 64px;
    right: 15%;
    display: flex;
    flex-direction: column;
    width: 420px;
    max-height: 400px;
    overflow-y: hidden;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}


.container-ar .notification-container {
    z-index: 80;
    position: absolute;
    top: 64px;
    left: 15%;
    display: flex;
    flex-direction: column;
    width: 420px;
    max-height: 400px;
    overflow-y: hidden;
    background-color: mediumvioletred;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.notification-title {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e5e588;
}

.notification-content {
    overflow-y: auto;
    padding: 0.1rem;
    min-height: 24px;
}

.feed-item {
    position: relative;
    border-bottom: 1px solid #33323212;
    cursor: pointer;
    margin: 0 !important;
}

.feed-item:hover {
    background-color: #77777722 !important;
}

.feed-text {
    margin-top: 0 !important;
    margin-bottom: 0.4rem !important;
}

.ball {
    position: absolute;
    background-color: mediumseagreen;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    right: 12px;
    bottom: 1em;
}

.ui.feed > .event > .label {
    margin-top: 0.5rem;
    /*align-self: center !important;*/
}

.notification-loading {
    position: relative;
    height: 34px;
    /*margin-top: 20px;*/
}


.notification-loading .notification-loader {
    left: 50% !important;
    border-color: #767676 transparent transparent;
    box-shadow: 0 0 0 1px transparent;
}

.notification-empty {
    font-family: Montserrat, serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #77777744;
    padding: 1rem;
    text-align: center;
    font-style: italic;
}

.notification-content .summary {
    text-transform: lowercase;
}

.notification-content .summary a {
    text-transform: capitalize;
}