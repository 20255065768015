
.client-list {
    font-family: Montserrat,serif !important; ;
    background: rgba(255, 255, 255, 0.97) !important;
}

.client-list-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 20px;
}

.client-list-body p {
    font-style: italic;
    font-weight: 200;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #7eb5e5;
    margin: 40px auto;
}

.search-bloc {
    width: 75%;
    height: auto;
    margin: 2% auto !important;
}

.search-input-client {
    width: 100%;
}

.search-bloc input {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 16px !important;
    color: #989595 !important;
    background: #ffffff !important;
    border: 1px solid #f5f3f3 !important;
    box-sizing: border-box !important;
    border-radius: 30px !important;
}

.add-client {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 14px !important;
    color: #989595 !important;
    margin-top: 1%;
}

.add-client .button {
    margin-right: 1%;
}

.search-content {
    margin: 2% auto !important;
    text-align: center;
    width: 80%;
}

.client-row {
    margin: 0px auto;
    background-color: #ffffff !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: rgba(51, 50, 50, 0.65);
    display: grid;
    grid-template-columns: 10% 30% 25% 25% 100px;
    align-items: center !important;
}

.client-row span {
    padding: 0px 5px;
    text-align: left;
}

.client-name {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #333232 !important;
}

.client-price {
    font-size: 16px;
    line-height: 18px;
    color: rgba(11, 108, 255, 0.65);
}

.client-expire-date {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #00b876;
}

.client-btn-detail {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    color: #ffffff !important;
    background: rgba(11, 108, 255, 0.65) !important;
}

/******add client*****/
.add-client-block {
    margin: 0px auto;
    width: 85%;
}

.client-input .form-label {
    font-style: italic;
    font-weight: 500;
    color: #7eb5e5 !important;
}

.client-green-btn {
    margin-top: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #00b876 !important;
    background: #ccffed !important;
    border-radius: 10px !important;
}

/*********Client details****/
.client-details {
    margin: 15px 30px;
}

.back-btn {
    margin-right: 30px;
    text-align: right;
}
