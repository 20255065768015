.ui.form .field.error input[type=tel]{
    border-left-color: #e0b4b4 !important;
}

.ui.error.left.labeled.input.input-bg input  {
    background: #fff6f6;
    color: #9f3a38;

}

.ui.error.left.labeled.input.input-bg .ui.compact.scrolling.dropdown.label{
    background: #fff6f6;
    color: #9f3a38;
}