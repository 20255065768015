.reccurrence-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.recc-input {
    margin: 4px;
}

.error.field.recc-input, .error.field.recc-input label,
.error.field.recc-input label::before {
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
}
