@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.calculator-container {
    width: 80%;
    margin: 0px auto 10px;
    padding: 0 !important;

}

.calculator-btns {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
}


.calculator-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5EFFE !important;
    border-radius: 4px;
    width: calc(100% / 4);
    height: calc(100% - 4px);
    text-align: center;
    font-family: Montserrat, serif;
    user-select: none;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333232;
    margin: 4px;
    cursor: pointer;
    transition: all 0.4s;
    padding: 4px;
}

.calculator-container:focus {
    outline: transparent !important;
}


.cal-btn-active {
    background-color: #0F97FA !important;
    color: #333232 !important;
}

.cal-btn-white {
    background-color: #FFFFFF !important;
    border: 1.5px solid #E5EFFE;
    color: #333232 !important;
}

.calculator-row {
    height: calc(100% / 5 - 4px);
    display: flex;
}

.container-ar .calculator-row {
    flex-direction: row-reverse !important;
}

.calculator-display {
    height: 40px;
    margin: 4px;
    background-color: #FFFFFF;
    display: flex;
    text-align: right;
    flex-direction: column;
}

.calculator-history {
    color: #FFFFFF !important;
    padding-right: 4px;
}

.calculator-input {
    flex: 1;
    text-align: right;
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px;
    line-height: 30px;
    color: rgba(11, 108, 255, 0.65) !important;
    border: none;
    border-radius: 4px;
    height: 10px !important;
    width: 100% !important;
    background-color: #E5EFFE;
    padding: 10px;

}

.calculator-btn-operator {
    width: calc(100% / 3)
}
