.stats-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 475px);
}

.container-ar .stats-icon {
  flex-direction: row-reverse;
}

.stats-with-scroll {
  flex: 1;
  flex-wrap: nowrap !important;
  overflow-x: hidden;
}

.round-min {
  border-radius: 100% !important;
  background-color: transparent !important;
}

.ca-blocks {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.container-ar .ca-main {
  flex-direction: row-reverse;
}

.ca-main {
  display: flex;
  width: 200px !important;
  min-width: 200px !important;
  height: 100px;
  border-radius: 5px;
  background: linear-gradient(45deg, #7e72f2 0%, #aaa3ea 100%);
  margin: 5px 10px 5px 0px;
  padding: 0.9rem;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.stats-main-container {
  flex: 1;
  display: flex;
}

.container-ar .stats-main-container {
  flex-direction: row-reverse;
}

.ca-total {
  /* margin-right: 6rem !important; */
  width: 220px !important;
}

.ca-icon-container {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ca-block-container {
  flex: 2;
  height: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.container-ar .ca-block-container {
  /*flex-direction: column-reverse;*/
}

.ca-icon-b {
  align-self: center;
  background-color: #ffffff;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ca-icon-b img {
  width: 32px;
  height: 32px;
}

.ca-block-title {
  font-size: 1rem;
}

.ca-block-title-sub,
.ca-block-value-sub {
  font-size: 1rem;
  padding-left: 0.4rem;
  margin-bottom: 0 !important;
}

.container-ar .ca-block-title-sub,
.ca-block-value-sub {
  padding-left: 0rem;
  padding-right: 0.4rem;
}

.ca-activity-name {
  font-size: 1rem;
  padding-top: 0.4rem;
}

.ca-block-value {
  font-size: 2.4rem;
  font-weight: bold;
}

.ca-block-row {
  padding: 0.1rem;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.stats-container {
  width: 100%;
  padding-top: 0.8rem;
  background-color: #ececec10;
}

.stats-row {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0.4rem;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee88;
}

.stats-row h3 {
  margin: 0;
}

.stats-row-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
