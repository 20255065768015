.custom-accordion .main-accordion {
    display: flex;
    font-family: Montserrat,serif ;
    justify-content: space-between;
    background-color: #4f4f4f25;
    color: #767676 !important;
    border-radius: 2px;
    font-size: 18px !important;
    cursor: pointer;
    /*font-weight: 400;*/
    text-transform: capitalize;
    padding: 14px 10px;
    margin-bottom: 10px;
}

.accordion-item-all{
    font-family: Montserrat,serif ;
    background-color: #1b1c29;
    color: white;
}
.accordion-item.accordion-item-all:before{
    content:""
}

label.accordion-item.single-accordion {
    flex: 1;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.custom-accordion .accordion-container {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    background-color: #4f4f4f10;
}


.accordion-item {
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    font-size: 16px !important;
}

.accordion-slider {
    transition: height 0.4s;
}

.accordion-hide {
    height: 0 !important;
    overflow: hidden;
}

.accordion-show {
    height: auto;
}

.accordion-item label {
    flex:1;
}

.accordion-container .accordion-item::before {
    content: '';
    display: inline-block;
    width: 8px;
    margin-right: 4px;
    height: 8px;
    background-color: #2c2c2c44;
    border-radius: 8px;
}

.accordion-container .accordion-item .checkbox {
    display: flex;
    justify-content: flex-end;
}

.dark .main-accordion,
.dark .accordion-container {
    color: white;
    background-color: #212121;
}
