.profile-page {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.profile-header {
    display: flex;
    overflow: hidden;
    min-height: 155px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0778245);
}

.dark .profile-header {
    background: #333;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.0778245);
}

.dark .profile-details-items {
    color: #dfe0eb;
}

.profile-details {
    flex: 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.profile-details-items {
    display: flex;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2c2c2c;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
}

button.ui.circular.icon.button.profile-btn {
    background: #fff;
    border: none;
}

button.ui.circular.icon.button.profile-btn:hover {
    background-color: #33333333;
}

.profile-details img {
    align-self: center;
    min-width: 120px !important;
    min-height: 120px !important;
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 50% !important;
    margin: 0 10px;
    border: 1px solid #cccccc44;
}

.profile-details img.ui.circular.image {
    /* width: 132px !important;
      height: 124px !important; */
}

.profile-info {
    position: relative;
    display: grid;
    flex: 1;
    flex-direction: column;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    z-index: 1;
    color: #ffffff;
    align-items: center;
    justify-content: space-around;
    padding-top: 1% !important;
    padding-left: 8% !important;
}

button.ui.circular.icon.button.profile-btn i {
    padding-top: 2px !important;
    width: 16px !important;
    height: 16px !important;
    font-size: 1em !important;
}

.profile-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.profile-btn i {
    font-weight: 200 !important;
    color: #e0e1e2;
}

.profile-credit {
    position: relative;
    display: flex;
    flex: 0.7;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    z-index: 1;
    letter-spacing: 0.2px;
    color: #444c63;
    text-align: center;
}

.profile-credit::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #dfe0eb;
    /*background-color: #9f3a38;*/
    content: '';
    z-index: -1;
    width: 200%;
    height: 200%;
    transform-origin: 50% 50%;
    transform: rotateZ(15deg);
}

/*.profile-credit img {*/
/*    width: 25%;*/
/*    height: 25%;*/
/*}*/

.profile-info::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #aeb9d6;
    /* background-color: #9f3a38; */
    content: '';
    z-index: -1;
    width: 100%;
    height: 350%;
    transform-origin: 50% 50%;
    transform: rotateZ(15deg) translate(0%, -20%);
}

.profile-phone {
    color: #7a97f4;
}

@media screen and (max-width: 992px) {
    .profile-header {
        flex-direction: column;
        min-height: 480px;
    }

    .profile-details {
        flex-direction: column;
        justify-content: space-around;
    }

    .profile-details-items {
        align-items: center;
        flex: 1;
        justify-content: space-around;
    }

    .profile-credit::before {
        transform: rotateZ(0);
    }

    .profile-info::before {
        transform: rotateZ(0);
    }

    .profile-info span {
        padding: 4px 0;
    }

    .profile-credit {
        padding: 10px;
    }
}

.ui.modal .scrolling.content {
    max-height: calc(100vh);
    overflow: auto;
}

.detail-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 480px;
}

.detail-modal-header {
    display: flex;
    justify-content: space-between;
}

.detail-modal-credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 24px; */
}

.detail-modal-date div > span:last-child {
    color: #252733;
}

.detail-modal-main.recharge-modal-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail-modal-main.recharge-modal-main .round-btn {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    width: 120px;
}

.detail-modal-main.recharge-modal-main form {
    display: flex;
    flex-direction: column;
}

.detail-modal-credit img {
    width: 4rem;
    height: 4rem;
}

.detail-modal-main {
    /* display: flex; */
    /* flex-direction: column; */
    min-height: 300px;
}

.detail-modal-main button {
    margin-bottom: 14px !important;
}

tfoot.sub-footer tr th {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    color: white !important;
    font-size: 18px !important;
}

.detail-modal-main-container thead tr th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* line-height: 28px; */
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4 !important;
    background: white !important;
}

.detail-modal-main-container tbody tr td {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;

    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733 !important;
}

.detail-modal-date {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.detail-modal-date h3 {
    margin-top: 0;
    margin-right: 44px;
}

.detail-modal-date div {
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    text-align: center;
    color: #9fa2b4;
}

.detail-modal-credit span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* or 56% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
    text-align: center;
}
.user-credit{
    margin-right: 14px;
    width: 3rem !important;
    height: 3rem !important;
}
.profile-credit-item{
    font-size: 1rem;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.credit-btn {
    align-self: flex-end;
}
