:root {
    --PLANNING-LEISURE-HEIGHT : 72px;
    --PLANNING-LEISURE-HEIGHT-JS : 72;
    --PLANNING-LEISURE-PARTICIPANT : 40px;
    --PLANNING-LEISURE-PARTICIPANT-MARGIN-TOP : 34px;
}

.container-ar .ui * {
    direction: rtl;
    /*text-align: right;*/
}
.container.mobile-layout {
    overflow-x: auto !important;
}

.version-update {
    z-index: 1000;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #1e2b37;
    padding: 5px;
    justify-content: center;
    align-items: center;
}

.version-update span {
    color: white;
    padding-right: 10px;
    font-size: 1rem;
}

.dark.container {
    background-color: #121212;
}

.dark .table.ui.small.unstackable.compact.padded.left.aligned.table {
    background-color: #333;
    color: white !important;
}

.Toastify__toast--warning {
    background: #ffa900;
}

.dark td.td-text {
    color: white;
}

img {
    image-rendering: auto;
}

.td-img img {
    image-rendering: optimizeQuality;
}

.ui.search.dropdown > .text {
    cursor: text;
    position: relative;
    left: 1px;
    z-index: 3;
    padding-top: 2px;
}

.ui.loader:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
}

.ui.loader:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent !important;
}

.btn-close {
    background-color: #db2828 !important;
}

.btn-close:hover {
    opacity: .9;
    background-color: #db2828 !important;
}

.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img,
.ui.dropdown > .text > .image,
.ui.dropdown > .text > img {
    width: 28px !important;
    height: 28px !important;
}

.dark th.tab-header.bg-tab {
    background: #121212 !important;
    color: rgba(255, 255, 255, 0.9) !important;
}

.dark h3.my-header {
    color: white;
}

button.ui.red.circular.icon.button.close-modal {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -40%);
}

.dark button.ui.basic.button {
    color: white !important;
    border-color: white !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 1) inset;
    background-color: #333 !important;
}

.dark button.ui.basic.button:hover {
    color: #333 !important;
    border-color: white !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 1) inset;
    background-color: #fff !important;
}

.dark .ui.definition.table thead:not(.full-width) th:first-child {
    box-shadow: none !important;
}

.dark span.item {
    color: white !important;
}

.dark .sous-menu {
    background-color: #121212 !important;
}

.dark span.item i {
    color: white !important;
}

.dark i.black.icon {
    color: white !important;
}

.dark .fixed-side {
    background-color: #121212;
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.170509);
}

.dark span.item:hover i.black.icon {
    color: #121212 !important;
}

.dark span.item:hover {
    color: #121212 !important;
}

.input-large-bg input {
    background-color: #eef1f7 !important;
    flex: 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

.dark .navbar {
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.135216) !important;
    background-color: #333;
    color: white;
}

.dark span.header {
    color: white !important;
}

.dark .name {
    color: white !important;
}


.responsive-filter {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.filter-input {
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    font-family: Montserrat, serif;
    font-size: 18px;
    color: #c4c4c4;
}

.search-input {
    width: 21% !important;
    height: 35px;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-input::placeholder {
    color: #c4c4c4 !important;
}

/* .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
    opacity: .9 !important;
} */

@media screen and (max-width: 1000px) {
    .responsive-filter {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .filter-input {
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .filter-input .search-input {
        width: 100% !important;
    }
}

.container-ar {
    direction: rtl;
}