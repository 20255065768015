.custom-dropdown-container {
    display: block;
    position: relative;
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotateZ(0);
    min-width: 14em;
    background: #fff;
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    min-height: 38px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.87);
}

.custom-dropdown-focused {
    z-index: 999;
}

input.custom-dropdown-input:focus {
    outline: none;
}

.custom-dropdown-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    /*min-height: 40px !important;*/
}

.dropdown-custom-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.custom-dropdown-input {
    border: none !important;
    width: 100% !important;
    /*min-height: 99% ;*/
    font-size: 1em !important;
    padding-left: 4px !important;
}

.container-ar .custom-dropdown-input{
    padding-left: 0 !important;
    padding-right: 4px !important;
}

.dropdown-custom-items .custom-dropdown-selected {
    background-color: #ffffff22;
    margin: .4em !important;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}

.custom-dropdown-items {
    max-height: 214px;
    background-color: white;
    position: absolute;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    width: 100%;
    overflow-y: auto;
    z-index: 1000;
}

.custom-dropdown-down {
    top: calc(100% + 2px);
}

.custom-dropdown-up {
    bottom: calc(100% + 2px);
}

.custom-dropdown-not-found {
    color: #c4c4c4;
    padding: 8px;
    text-align: center;
    font-weight: 200;
    font-size: 1rem !important;
    margin: 0 !important;
}

.custom-dropdown-loading {
    position: relative;
    height: 34px;
    /*margin-top: 20px;*/
}

.input-bg.custom-dropdown-container.error-custom-dropdown .custom-dropdown-main, .custom-dropdown-container.error-custom-dropdown .custom-dropdown-main {
    background-color: #fff6f6 !important;
}

.input-bg.custom-dropdown-container.error-custom-dropdown .custom-dropdown-main input, .custom-dropdown-container.error-custom-dropdown .custom-dropdown-main input {
    background-color: #fff6f6 !important;
}

.error-custom-dropdown, .input-bg.error-custom-dropdown {
    border-color: #9f3a38 !important;
}

.custom-dropdown-loading .custom-dropdown-loader {
    left: 50% !important;
    border-color: #767676 transparent transparent;
    box-shadow: 0 0 0 1px transparent;
}

.custom-dropdown-container .ui.loader {
    left: 95%;
}


.container-ar .custom-dropdown-container .ui.loader {
    right: 95%;
    left: 0;
}

.custom-dropdown-container > div {
    height: 100%;
}

.custom-dropdown-item img,
.custom-dropdown-selected img {
    width: 28px;
    height: 28px;
    border-radius: 34px;
    margin-right: 8px;
}

.custom-dropdown-container {
    font-size: 1.1em;
}

.custom-dropdown-selected {
    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.custom-dropdown-item {
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 18px 8px;
    background-color: #fff;
}

.custom-dropdown-item {
    border-bottom: 1px solid #dedede;
}

.custom-dropdown-item:hover {
    background-color: #23232318;
}

.custom-dropdown-item:last-child {
    border-bottom: 1px solid #fff !important;
}

.custom-dropdown-item-selected {
    border-left: 2px solid #979797;
}

input.custom-dropdown-input-search {
    /*margin: 1px 0 !important;*/
    border: none !important;
    max-width: 100% !important;
    /*width: auto !important;*/
}

.custom-dropdown-control-last {
    display: flex !important;
}

input.custom-dropdown-input-search:focus {
    outline: none !important;
    border-bottom: 1px solid #7a97f4 !important;
}

.custom-dropdown-disabled {
    opacity: .4;
}

input.custom-dropdown-input::placeholder {
    opacity: .4;
}

.custom-dropdown-selected span, .custom-dropdown-selected {
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1rem !important;
}

.custom-dropdown-selected .icon {
    align-self: center;
    margin-top: -4px;
    margin-left: 4px;
}