.ticket {
    font-family: Montserrat,serif;
}

.ticket-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 92%;
    height: 65px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #F9FAFB;
}

.container-ar .ticket-info{
    flex-direction: row-reverse;
}

.ticket-avatar {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50% !important;
}

.ticket-info .image {
    width: 35px;
}

.close-btn {
    position: absolute;
    right: 0;
    top: -50px;
    /* transform: translate(50%,-50%); */
}
.container-ar .close-btn{
    right:90%
}

.ticket-block {
    display: flex;
    flex-direction: column;
    flex: 4 1;
    position: relative;
    width: 95%;
    height: 89vh;
    margin: 0 auto;
    background-color: #fffcfc;
    font-style: normal;
}

.ticket-content {
    flex: 4;
    overflow-x: hidden;
    overflow-y: auto;
}

.ticket-content-header {
    width: 100% !important;
    /*padding: 0% 3%;*/
    border-bottom: 1px solid #C4C4C4;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #333232;
}

.ticket-content-header span {
    font-weight: 900;
    font-size: 14px;
    line-height: 23px;
    color: #333232;
}

/************Ticket accordion**************/
.accordion-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    /* color: rgba(11, 108, 255, 0.65); */
}

.accordion-discount {
    display: flex !important;
    /* justify-content: space-evenly !important; */
    align-items: center !important;
    width: 28px;
    font-size: 12px !important;
    color: black;
}

.badge-qte input {
    width: 25px;
    height: 25px;
    color: #FFFFFF !important;
    font-size: 30 !important;
    padding: 0px !important;
    text-align: center !important;
    border-radius: 50% !important;
    background-color: rgba(11, 108, 255, 0.65) !important;
}

.badge-qte-minus {
    margin: 0 5px !important;
    padding: 3px !important;
    color: #FFFFFF !important;
    background: #FF7070 !important;
    border-radius: 5px;
}

.badge-qte-plus {
    margin: 0 5px !important;
    padding: 3px !important;
    color: #FFFFFF !important;
    background: #00B876 !important;
    border-radius: 5px;
}

.main-accordion {
    border-bottom: 1px solid #ccc;
}

.accordion-hide {
    height: 0 !important;
    overflow: hidden;
}

.accordion-show {
    height: auto;
}

.accordion-container {
    width: 99%;
    margin: 4px auto;
}

.accordion-container div{
    display: flex;
}

.ticket-accord-item {
    width: 32%;
    height: 58px !important;
    box-sizing: border-box !important;
    border-radius: 5px;
    margin: 2px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    position: relative;
}



.ticket-accord-item-edit {
    position: absolute !important;
    top: 1% !important;
    left: 4% !important;
}

.ticket-accord-item-delete {
    position: absolute !important;
    top: 4% !important;
    right: 4% !important;
}

.payment-icon-group {
    position: absolute !important;
    bottom: 4% !important;
    right: 4% !important;
}

.ticket-accord-item-payment {
    width: 15px !important;
    /*filter: drop-shadow(2px 2px 2px #00B876);*/
    filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
}

.ticket-accord-item-payment-partial {
    width: 15px !important;
    filter : invert(36%) sepia(65%) saturate(7470%) hue-rotate(211deg) brightness(102%) contrast(108%);
    /* filter: drop-shadow(2px 2px 2px #0066ff); */
}

.ticket-accord-item-payment-edit {
    width: 15px !important;
}

.ticket-accord-item-payment-popup {
    width: 15px !important;
    /* -webkit-filter: invert(1); */
    filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
}

.ticket-accord-item-remise {
    width: 28px;
    position: absolute !important;
    top: 50% !important;
    left: 1% !important;
    font-size: 10px !important;
    color: black;
}

.ticket-accord-item-remise .image {
    position: absolute !important;
    bottom: 11px !important;
    left: 4% !important;

}

.participant-btn {
    padding: 22px !important;
    background: #297EFF !important;
    border-radius: 100% !important;
    font-size: 22px !important;
    color: #FFFFFF !important;
    position: relative;
    margin-left: 10% !important;
}

.participant-btn .plus {
    font-weight: 700;
    top: 5px;
    left: 8px;
    position: absolute;
}

.participant-btn .minus {
    font-weight: 700;
    bottom: 5px;
    right: 8px;
    position: absolute;
}

.participant-btn .separator {
    font-size: 55px !important;
    font-weight: 10 !important;
    color: #fffcfc;
    left: 20px;
    top: 7px;
    position: absolute;
    transform: rotate(45deg);
}

/*********end ticket accordion*************/
.ticket-footer {
    flex: 1;
}

.ticket-grey-block {
    max-height: 45px !important;
    background-color: #C4C4C4;
    position: relative;
    padding: 2px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    color: #FFFFFF;
    text-transform: uppercase !important;
}

.ticket-grey-block-price {
    position: absolute;
    right: 6%;
    font-size: 20px !important;
    line-height: 35px;
    font-weight: 800;
}

.container-ar .ticket-grey-block-price{
    right: 80%;
}

.payment-action-list {
    min-height: 50px;
    padding: 10px 10px 10px 20px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    color: #00B876;
}

.payment-remainder-label {
    padding: 10px 10px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
}
.payment-remainder {
    padding: 10px 5px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
}

.ticket-menu {
    padding: 10px 15px;
    min-height: 100px;
}

.ticket-menu-item {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 12px !important;
    width: 32%;
    border-radius: 5px;
    margin: 2px 1px 1% !important;
    padding: 2px !important;
}

.ticket-menu-item-img {
    height: 25px !important;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-grey-off {
    padding: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #333232 !important;
    background: #DFE0EB !important;
}

.btn-grey-off img {
    height: 27px !important;
    -webkit-filter: invert(1);
    filter: invert(80%);
}

.btn-grey-on {
    padding: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #FFFFFF !important;
    background: #297EFF !important;
}

.btn-grey-on img {
    height: 27px !important;
    filter: none;
}

.btn-white {
    color: #333232 !important;
    border: 1px solid #333232 !important;
    background: #FFFFFF !important;
}

.ticket-menu-cash-on {
    color: #FFFFFF !important;
    background-color: #00B876 !important;
}

.ticket-menu-cash-off {
    color: #FFFFFF !important;
    background-color: #FF7070 !important;
}


/*************Modal********/
button.ui.button.ticket-accord-item span {

    padding: 0.8em;
    flex: 1;
    display: block;
}

i.edit.icon.ticket-accord-item-edit {
    font-size: 14px;
    padding: 2px;
}
i.edit.icon.ticket-accord-item-delete{
    font-size: 14px;
    padding: 2px;
}

button.ui.button.ticket-accord-item {
    padding: 0 !important;
}

.withOpacity {
    opacity: 0.6;
}
.center-modal{
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
}

.success-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.success-modal span {
    font-size: 80px !important;
    line-height: 80px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-ticket {
    justify-content: center !important;
    /*overflow-y: hidden !important;*/
    position: relative;
    height: auto;
    /*max-height: 320px;*/
}

.modal-content {
    padding: 10px !important;
    height: auto !important;
    min-height: 50px !important;
}

.modal-content-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: #7EB5E5;
    padding-top: 10px;
}

/* .modal-btn-close{
	position: absolute;
	right: 10px;
	top:10px;
} */
.modal-content-body p {
    font-style: italic;
    font-weight: 200;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #7EB5E5;
    margin: 40px auto;
}

.btn-group {
    display: flex;
    justify-content: center;
}

.modal-delete-btn {
    background: #CCFFED !important;
    color: #00B876 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    margin: 15px !important;
}

.modal-cancel-btn {
    color: #FFFFFF !important;
    background: #FF7070 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    margin: 15px !important;
}

/********Modal player information*****/
.modal-edit-player {
    height: auto !important;
}

.search-bloc {
    /* text-align: center; */
    width: 75%;
    height: auto;
    margin: 2% auto !important;
}

.search-input-player {
    width: 100%;
}

.search-bloc input {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 16px !important;
    color: #989595 !important;
    background: #FFFFFF !important;
    border: 1px solid #F5F3F3 !important;
    box-sizing: border-box !important;
    border-radius: 30px !important;
}

.add-player {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 14px !important;
    color: #989595 !important;
    margin-top: 1%;
}

.add-player .button {
    margin-right: 1%;
}

.player-informations {
    min-height: 450px;
    background: #F9FAFB !important;
    padding: 10px 0px;
}

.search-list {
    margin: 2% auto !important;
    text-align: center;
    width: 85%;
}

.player-row {
    margin: 15px auto;
    background-color: #FFFFFF !important;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;;
    color: #989595;
    display: grid;
    grid-template-columns: 32% 32% 32%  5%;
}

.player-row span {
    margin: 0px 1%;
    padding: 10px 5px;
    text-align: left;
}

.add-player-bloc {
    margin: 5px auto !important;
    text-align: center;
    width: 30%;
}

.player-input .form-label {
    font-style: italic;
    font-weight: 300;
    color: #7EB5E5 !important;
}

.player-green-btn {
    margin-top: 0px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    color: #00B876 !important;
    background: #CCFFED !important;
    border-radius: 10px !important;
}

.edit-player-bloc {
    margin: 1% auto !important;
    text-align: right;
    width: 95%;
}

.player-input .form, .edit-participant-input .form {
    margin-bottom: 5px;
}

.amount-bloc {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 8%;
    padding: 14px 0px;

}

.reload-span {
    margin-left: 5% !important;
}
span.amount-bloc.amount-bloc-reload  button.ui.button.btn-inline-icon {
    margin-left: 25%;
}
.amount-bloc .button {
    font-style: italic !important;
    font-weight: 200 !important;
    font-size: 14px !important;
    text-align: center !important;
    color: #6BCE9F !important;
    background: #FFFFFF !important;
    border: 1px solid #6BCE9F !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding: 0px !important;
    /*margin-left: 10%;*/
}

.btn-inline-icon {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.group-btn-edit-player {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px auto;
    width: 40%;
}

.btn-select {
    margin-top: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    color: #00B876 !important;
    background: #CCFFED !important;
    border-radius: 10px !important;
}

.btn-payment {
    background: rgba(11, 108, 255, 0.08) !important;
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    color: rgba(11, 108, 255, 0.65) !important;
    padding: 8px 24px !important;
    margin-top: 10px !important;
    border-radius: 10px !important;
}

/********end modal : player information*****/

/*****Modal : add participant****/
.modal-add-participant {
    min-height: 380px !important;
}

.edit-participant-input {
    margin: 45px auto 0px !important;
    width: 50%;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(11, 108, 255, 0.65);
}

.edit-participant-input input {
    font-style: normal !important;
    font-size: 24px !important;
    font-weight: 800 !important;
    text-align: center !important;
    color: rgba(11, 108, 255, 0.65) !important;
    padding: 6px !important;
    width: 120px;
    background: rgba(51, 50, 50, 0.1) !important;
}

/****end modal : add participant***/
.ticket-finished-table {
    width: 85% !important;
    margin: 2px auto !important;
    color: rgba(0, 184, 118, 0.65) !important;
}

.occ-nb-style {
    border-radius: 50% !important;
    background-color: rgba(0, 184, 118, 0.65) !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    padding: 2px 8px !important;
    text-align: center !important;
}


.ticket-loader {
    width: 20px;
    position: relative;
    height: 20px;
}

.loading-ticket::after {
    border-color: darkred !important;
}

span.error-msg {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: darkred;
    /* text-transform: capitalize; */
}

span.amount-bloc-reload {
    display: grid;
    grid-template-columns: 70% 30%;
    margin-left: 0;
    padding: 14px 0;
}

/** ticket details ***/
.details-btn {
    width: 24% !important;
    height: 44px !important;
    border-radius: 8px !important;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF !important;
    padding: 0px !important;
    margin: 16px 1px !important;
}

.purple-details-btn {
    background: rgba(173, 0, 255, 0.65) !important;
}

.orange-details-btn {
    background: #F2994A !important;
}

.green-details-btn {
    background: #00B876 !important;
}

.red-details-btn {
    background: #FF7070 !important;
}

/******************/
.no-padding-grid {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.invoice-information {
    padding-top: 10px;
}

.invoice-information .form-label {
    color: #7EB5E5 !important;
    font-weight: 300;
}
.label-invoice{
    margin-left: 10px;
    padding: 8px;
    color: white;
    background-color: #1678c2;
    border-radius: 2px;
}


/****note division****/
.img-division{
    width: 25px;
    height: 25px;
    border-radius: 4px;
    font-size: 1.4rem;
}
.img-division-inverse{
    width: 25px;
    height: 25px;
    background-color: rgb(0, 184, 118) !important;
}
.no-opacity{
    opacity: 0;
}
.header-note{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding : 3px 10px !important;
    font-size: 14px;
    line-height: 26px;
    color: #252733;
    font-weight: 900;
}

.ticket.ticket-loading {
    position: relative;
}

.ticket.ticket-loading:before {
    background: #eeeeee88 !important;
    content: "";
    position: absolute;
    top: 0;
    bottom: -50px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.ui.active.centered.inline.loader.ticket-loading-loader {
     position: absolute;
     top: 50%;
     left: 50%;
 }

span.note-btn {
    font-size: 1em !important;
    padding: 0.2rem 0.2rem;
    border: 2px solid #333232;
    margin-right: 0.4rem;
    border-radius: 2px;
    cursor: pointer;
}

span.note-active{
    background-color: rgb(0, 184, 118) !important;
    border-color: #33323299 !important;
    color: #ffffff;
}