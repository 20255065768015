.dark .ui.checkbox label,
.dark .ui.checkbox + label {
    color: #9fa2b4 !important;
}

.form {
    margin-bottom: 28px;
}

.timeInput-container.custom-input.input-bg {
    background-color: #eef1f7 !important;
    border: none !important;
    border-radius: 2px !important;
}

.label-image {
    display: flex;
    align-items: center;
}

input.custom-dropdown-input {
    border: white !important;
}

.input-bg.custom-dropdown-container .custom-dropdown-main {
    background-color: #eef1f7 !important;
}

.input-bg.custom-dropdown-container input {
    background-color: #eef1f7 !important;
}

.form-label {
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    /*line-height: 28px !important;*/
    letter-spacing: 0.2px;
    color: #9fa2b4 !important;
}

.container-ar .input-bg {
    text-align: right !important;
}

.input-bg.custom-dropdown-container {
    border: none !important;
}

.dropdown.input-bg {
    background-color: #eef1f7;
    flex: 1;
    /*padding: 0.8em 4px !important;*/
    border-radius: 2px !important;
}

.ui.dropdown.error,
.ui.dropdown.error > .default.text,
.ui.dropdown.error > .text {
    color: #9f3a38 !important;
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
}

.field.error label.form-label {
    color: #9f3a38 !important;
}

textarea.input-bg {
    background-color: #eef1f7 !important;
    flex: 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

.ui.input.input-bg input {
    flex: 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
}

.ui.input.input-bg input:focus {
    background-color: #dfe0eb88;
}

textarea {
    background-color: #eef1f7;
    flex: 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

textarea:focus {
    background-color: #dfe0eb88;
    border: none !important;
}

.dropdown.input-bg {
    flex: 1 1;
    /*padding: 0.8em 8px !important;*/
    background: #eef1f7 !important;
    border-radius: 2px !important;
    border: none !important;
    /*height:41px;*/
}

.ui.compact.selection.dropdown {
    width: 100%;
}

.img-upload {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #d5d5d5d1;
}

.search-input {
    width: 21%;
    height: 35px;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-input ::placeholder {
    color: #c4c4c4 !important;
}

.search-dropdown {
    width: 21% !important;
    min-height: 35px !important;
    padding: 16px !important;
    font-size: 15px !important;
    line-height: 0em !important;
    margin: 4px !important;
}

.search-dropdown i.dropdown.icon {
    top: 6px !important;
}
.ui.input.input-bg input {
    background-color: #eef1f7 !important;
    flex: 1 1;
    padding: 0.8em 4px!important;
    border-radius: 2px!important;
    border: none !important;
}

.group-checkbox{
    flex-wrap: wrap;
    gap:0.6rem;
}


.upload-icon{
    position: absolute;
    right: 1%;
}

.ui.attached.label{
    position: relative !important;
}
