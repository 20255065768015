.fixed-side {
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    transition: background 200ms, width 200ms;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 22px 20px 20px;
    min-height: 100vh;
    -ms-overflow-style: none;
}


.dashboard-welcome {
    margin-bottom: 48px;
    text-align: center;
}

.side-mobile .dashboard-welcome {
    display: none;
}

.welcome {
    width: 200px;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
}

.dashboard-menu-container {
    width: 100%;
    align-items: center;
}

.dashboard-menu {
    background: rgba(126, 114, 242, 0.578726) !important;
}

.dashboard-menu-item {
    margin-bottom: 1.2em;
    align-self: center !important;
    justify-self: center !important;
}

.menu-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #ececec;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu-dashboard img {
    width: 80px;
    margin-bottom: 4px;
    height: 80px;
}

.menu-dashboard h3 {
    font-size: 16px;
    text-align: center;
    padding: 4px;
    margin: 0;
}

.side-mobile .menu-dashboard img {
    width: 40px;
    height: 40px;
}

.side-mobile .menu-dashboard h3 {
    display: none;
}

.side-mobile .menu-dashboard {
    width: 48px;
    height: 48px;
    border-radius: 4px;
}

.logo > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo > a > h4 {
    margin-left: 8px;
}

.overflow-visible {
    -ms-overflow-style: none;
    flex-direction: column;
}

.logo {
    display: flex;
    margin-bottom: 75px;
    justify-content: center;
}

.logo img {
    width: 140px;
    height: 68px;
}

.side-mobile .logo img {
    width: 48px;
    height: 48px;
}

.logo h4 {
    color: #7e72f2;
    font-family: Montserrat, serif;
    font-style: normal;
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.menu {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.menu span.item {
    margin-bottom: 10px;
}

.sous-menu .has-menu .item a {
    display: flex;
}

.sous-menu .has-menu .item a span {
    display: flex;
}

i.black.circle.mini.icon.nav-default-icon {
    align-self: center;
}

.menu span.item a {
    display: block;
    padding-left: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #2c2c2c;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 34px;
    cursor: pointer;
}

span.item a.active i {
    color: white !important;
}

.menu span.item a.active,
.menu span.item a.active {
    background: linear-gradient(188.54deg, #9e94ff 70.01%, #7e72f2 169.21%);
    box-shadow: -1px 1px 8px rgba(126, 114, 242, 0.578726);
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 34px;
    color: #fff;
}

.menu span.item a i {
    margin-right: 4px;
    margin-left: 8px;
}

.menu .item-end {
    /* margin-top: auto; */
}

.menu span.item:hover {
    background: #ececec;
    border-radius: 4px;
}

.fixed-side::-webkit-scrollbar {
    display: none;
}

.overflow-visible::-webkit-scrollbar {
    display: none;
}

.sous-menu {
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    z-index: 44 !important;
}

.has-menu {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    position: relative;
}

.display-none {
    display: none;
}

i.last {
    text-align: right !important;
}

.side-mobile h4 {
    display: none;
}

.overflow-visible {
    display: flex;
    width: 100%;
    flex: 1;
}

.side-mobile {
    overflow-x: visible !important;
}

.side-mobile .menu .sous-menu span.item a {
    display: flex !important;
    text-align: left;
    align-items: center;
    justify-content: center;
}

.side-mobile .menu .sous-menu span.item a i {
    margin-right: 12px;
    font-size: 8px;
}

.side-mobile .menu span.item a i {
    font-size: 18px;
}

.side-mobile .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;
}

.side-mobile .menu span.item a i {
    margin-right: 0;
    margin-left: 0;
}

.side-mobile span.item a {
    padding: 8px !important;
    width: 100%;
    text-align: center;
    border-radius: 0;
}

.side-mobile .menu span.item:hover {
    background: #ececec;
    border-radius: 0px;
}

.side-mobile span.item a:hover {
    border-radius: 0 !important;
    /* position: relative; */
}

.side-mobile span.item a.active {
    border-radius: 0;
    /* position: relative; */
}

.side-mobile .menu .item span {
    display: none;
}

.side-mobile {
    align-items: center;
    padding: 18px 0 0;
}

.side-mobile i {
    font-size: 20px;
}

.side-mobile .menu {
    width: 100%;
    display: flex;
    /*justify-content: center;*/
    flex: 1;
}

/***
* remove this if u want sous menu to show up when user click on the link
*/
.side-mobile .menu .has-menu:last-child:hover .sous-menu.display-none {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu .has-menu:not(:last-child):hover .sous-menu.display-none {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

/**/

.side-mobile .menu .has-menu:last-child:hover .sous-menu {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu .has-menu:not(:last-child):hover .sous-menu {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu span.item {
    margin-bottom: 0;
}

.side-mobile .menu .has-menu .sous-menu {
    display: none;
}

.sous-menu span.item a i {
    vertical-align: middle !important;
}

.side-mobile .menu .has-menu:hover .sous-menu span {
    display: flex;
    width: 250px;
}

.side-mobile .menu .has-menu:hover .sous-menu.display-none span {
    display: flex;
    width: 250px;
}

/* .side-mobile span.item:hover.sous-menu {
    position:absolute;
    left:100px;
    background-color:red;
} */

.dark .item a {
    color: white !important;
}

.dark .item a:hover {
    color: #333 !important;
}
.side-mobile .menu  .menu .has-menu .sous-menu .has-menu .sous-menu.display-none {
    display: none !important;
}

.side-mobile .menu .has-menu .sous-menu .has-menu{
    position:relative;
    display:flex;
    flex-direction:column;
}
.side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu.display-none {
    display:none !important;
}

.side-mobile .menu .has-menu .sous-menu .has-menu .item:hover, .side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu.display-none{
    position:static;

}

.side-mobile .menu .has-menu .sous-menu .has-menu .item:hover, .side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu{
    position:static;
}

.side-mobile .menu .sous-menu span.item a i.black.angle {
    font-size: 12px;
}