.column.player-input p {
    margin: 0;
}

.modal-p-error{
    color:#db2828 !important;
    font-size: 1rem !important;
}
.subscriber-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #eee;
    padding: 4px;
}

.subscriber-msg span i {
    margin: 4px;
}

.subscriber-msg div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
}

