.selected-complex {
    /* padding: 4px; */
    border-radius: 40px;
    border: 1px solid #d4d4d5;
    height: 35px !important;
    padding: 5px;
    width: 35px !important;
    /* background: #ffffff!important; */
}

.no-btn-style {
    background-color: transparent !important;
    align-self: flex-start;
}

.equipment-bg {
    position: relative;
    background-color: #C4C4C411;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.equipment-bg .close-btn {
    position: absolute;
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
}

.div-form-container.colors-tab .div-form {
    flex: 1;
}
