.historique-container .td-text{
    font-size: 1rem !important;
}

.regulation-input img.filter-img {
    -webkit-filter: invert(100%) !important;
    filter: invert(100%) !important;
}
.input-date-small{
    height: 35.8px !important;
}
.text-area-height{
    height:68px !important;
}

.input-modal .ui.input input[type="phone"] {
    width: calc(101% - 67px ) !important;
}

.participant-warning {
    position:absolute;
    right: 0;
    top:1rem;
}

@media only screen and (min-width:1920px){
    .ui.large.modal.reservation-modal{
        width:1450px;
    }
}