.container {
    display: flex;
    overflow-x: hidden;
    min-height: 100%;
    transition: background-color 0.4s;
}

div#root {
    height: 100%;
}
