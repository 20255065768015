@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tomorrow:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
/**********MainMenu********/
.main-menu {
    background: #333232;
    width: 100%;
}

.main-menu-item {
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    width: 100%;
    min-height: 70px;
    border-radius: 0px !important;
    padding: 8px !important;
    margin-bottom: 1% !important;
}

.ui.large.modal > .header {
    display: flex;
    justify-content: center;
}

button.ui.button.main-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-menu-item:hover {
    background-color: #AFD7FA !important;
    color: #000000 !important;
}

.main-menu-item .image {
    width: 3em;
    height: 3em;
    margin: 0px auto 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menu-item:hover .image {
    -webkit-filter: invert(0);
    filter: invert(100%);
}

.main-menu-item-bottom {
    height: 55px !important;
}

.main-menu-item-bottom .image {
    width: 2.3em !important;
    height: 2.3em !important;
}

.main-menu-item:active .image {
    -webkit-filter: invert(0);
    filter: invert(100%);
}

button.ui.button.payment-btn-active.payment-btn-green {
    color: white !important;
    background: #00B876 !important;
}

/**********ChildMenu********/
.child-menu {
    background-color: #AFD7FA !important;
    width: 100%;
}

.child-menu-item {
    width: 98%;
    height: 65px;
    box-sizing: border-box !important;
    border-radius: 0px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 140px auto !important;
    background-color: #AFD7FA !important;
    padding: 1% !important;
    margin: 1% !important;
}

.child-menu-item:hover {
    border: 3px solid #7EB5E5 !important;
}

.child-menu-item span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    /* line-height: 19px; */
    text-align: center;
    color: #FFFFFF !important;
    mix-blend-mode: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/***************ContentMenu*******************/
.content-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 86px;
}

.content-menu-item {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    width: 31%;
    min-width: 180px !important;
    min-height: 160px !important;
    height: auto;
    box-sizing: border-box !important;
    border-radius: 20px 20px 0px 0px !important;
    background-color: #F3F3F3 !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 350px auto !important;
    margin: 2px 5px !important;
    padding: 1px !important;
    border: 3px solid transparent !important;

}

.content-menu-item:hover {
    border: 3px solid #7EB5E5 !important;
}

img.content-menu-item-avatar {
    position: absolute;
    max-width: 64px;
    max-height: 80px;
    bottom: 1.6rem;
    left: 0.4rem;
}


.content-menu-item span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #333333;
}

.content-menu-item-price {
    align-self: flex-end;
    background-color: #AFD7FA !important;
    padding: 1.4rem 0 !important;
}

span.content-menu-item-price span {
    background: #69747E;
    color: white !important;
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius: 50%;
    right: 1.2rem;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-menu-item-name {
    align-self: flex-start;
    padding: 1rem 0;
    width: 100% !important;
}

.content-menu-item-price {
    align-self: flex-end;
    width: 100% !important;
    background: #7E88E5;
    padding: 1rem 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: right !important;
    color: #FFFFFF !important;
    padding-right: 0.4rem !important;
}

/*******Payment & discount*****************/
.payment-title {
    height: 5vh;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: rgba(11, 108, 255, 0.65);
    text-align: center;
    position: relative;
}

.cancel-note {
    position: absolute;
    top: 5px;
    right: 10px;
}

.payment-sub-title {
    position: absolute;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal !important;
    color: rgba(11, 108, 255, 0.65);
    text-align: center;
    padding-left: 20px;
}

.container-ar .payment-sub-title {
    padding-left: 0;
    padding-right: 20px;
}

.payment-content {
    /* background-color: #fffcfc; */
    overflow: hidden;
    padding: 2%;
    height: 76vh;
}

.payment-content-left {
    padding: 1% 3%;
}

.payment-content-left-bottom {
    padding: 0 20%;
}

.solde-btn {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif !important;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #00B876 !important;
    margin-bottom: 20px !important;
}

.payment-content-total {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    border-top: 1px solid rgba(11, 108, 255, 0.65);
    padding-top: 10px !important;
    margin: 8% !important;
}

.discount-content-quantity {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 10px !important;
    margin: 8% 0px !important;
}

.payment-content-right {
    padding-left: 15% !important;
}

.payment-block-input-group {
    overflow: auto;
    height: 67vh;
}

.payment-block-input-disabled {
    /*opacity: 0.4;*/
}

.alert-note {
    color: darkred;
}

span.alert-note {
    position: absolute;
    bottom: calc(50% - 9px);
    right: 124px;
}

span.alert-note img {
    width: 18px;
    height: 18px;
}

.payment-block-input {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center !important;
    width: 210px !important;
    height: 45px;
    margin: 5px !important;
    padding: 1% 2% !important;
    border-radius: 5px !important;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF !important;
    background-color: #297EFF;
}

.margin-block-input {
    margin-top: 13px !important;
}

.return-article-block {
    background-color: #C7C7C7;
}

.orange-block {
    margin-top: 15px !important;
    background-color: #F2994A !important;
}

.payment-block-input-left {
    position: relative;
    width: 45% !important;
    padding: 2px;
    text-align: center;
}

.payment-block-remove {
    position: absolute;
    right: 3px;
    top: 13px;
    background-color: transparent !important;
    color: #db2828 !important;
    padding: 0 !important;
}

.payment-block-input-left input {
    background: #FFFFFF !important;
    border: none !important;
    font-size: 14px !important;
    width: 85px;
    height: 34px;
    color: rgba(11, 108, 255, 0.65) !important;
}

.payment-block-input-right {
    width: 55% !important;
    text-align: center;
    padding: 5px 0px;
}

.payment-block-input-right .image {
    padding-bottom: 5px;
    margin: 1px auto !important;
    height: 25px !important;
}

.center-btn {
    text-align: center;
}

.payment-btn-active {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 14px !important;
    color: #00B876 !important;
    margin: 2% !important;
}

.payment-btn-desactive {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 14px !important;
    margin: 2% !important;
}

/* .payment-btn-active{
	background: rgba(0, 184, 118, 0.1);
	border-radius: 5px;
	color: #00B876;
} */

.loading-input-main {
    display: flex;
    flex: 1 1;
    width: 100%;
    height: 100%;
}

.loading-input-main .ui.loader {
    top: 10%;
}

.discount-btn-group {
    display: flex;
    justify-content: center;
}

.payment-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.advance-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.payment-modal span {
    font-size: 80px !important;
    line-height: 80px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advance-modal span {
    font-size: 42px !important;
    line-height: 70px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*******End payment*************/

i.close.icon.discount-close-btn {
    position: absolute;
    right: 56%;
    color: #333;
    -webkit-transform: translateY(-37%);
            transform: translateY(-37%);
}


/********Credit modal*******/
.modal-ok-btn {
    background: #CCFFED !important;
    color: #00B876 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 15px !important;
    margin: 0px 10px !important;
    height: 45px;
}

.block-credit-modal {
    margin: 20px 90px;
    text-align: center;
}

.block-credit-modal h5 {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #333232;
}

.block-credit-modal .ui.input > input {
    background: #DFE0EB;
    border-radius: 8px;
    height: 45px;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #333232;
}

.block-credit-modal h5, .block-credit-modal span {
    margin-bottom: 8px !important;
}

/******Note division*****/
.header-note-list{
    position : relative;
}
.delete-note-botton{
    margin-left: 4px;
    background-color: transparent !important;
    color: #db2828 !important;
    padding: 0 !important;
}

.edit-note-botton{
    margin-left: 4px;
    background-color: transparent !important;
    color: #1e578d  !important;
    padding: 0 !important;
}

.division {
    position: relative;
    margin-top: 10px;
}

.cancel_note {
    position: absolute;
    top: 0px;
    right: 5px;
}

.division-content {
    padding: 1.2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    max-height: 80vh;
    overflow: auto;
}

.division-list{
}

.orange-arrow {
    width: 50px;
    height: 50px;
    margin-left: 30px;
}

.division-home {
    margin-top: calc(28px);
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #333333;
}

.division-list-user {
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    padding-bottom: 25px;
}


.division-edit-user > button {
    margin-right: 1.4rem !important;
}

.note-item-label {
    font-size: 16px;
    line-height: 26px;
    color: rgba(11, 108, 255, 0.65);
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.qty-modal-note {
    text-align: center;
}

.choose-qty {
    color: #000000 !important;
    font-size: 12px;
}

.total-note {
    border-top: 1px solid #333333;
    font-style: italic !important;
    font-weight: bold !important;
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    color: #333333;
    padding-top: 15px;
    padding-bottom: 0.6rem;
    display: block;

}

.payed-note {
    font-style: italic !important;
    /*font-weight: bold !important;*/
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    color: #00B876;
    padding-top: 20px;

}

.badge-qte-main input {
    height: 30px !important;
}

.note-item-sub-label {
    color: #252733 !important;
    text-align: left !important;
}

.division-edit-user {
    display: flex;
    height: 100px;
    align-items: center;
}

.division-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.column.player-input p {
    margin: 0;
}

.modal-p-error{
    color:#db2828 !important;
    font-size: 1rem !important;
}
.subscriber-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #eee;
    padding: 4px;
}

.subscriber-msg span i {
    margin: 4px;
}

.subscriber-msg div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
}


.dark .ui.checkbox label,
.dark .ui.checkbox + label {
    color: #9fa2b4 !important;
}

.form {
    margin-bottom: 28px;
}

.timeInput-container.custom-input.input-bg {
    background-color: #eef1f7 !important;
    border: none !important;
    border-radius: 2px !important;
}

.label-image {
    display: flex;
    align-items: center;
}

input.custom-dropdown-input {
    border: white !important;
}

.input-bg.custom-dropdown-container .custom-dropdown-main {
    background-color: #eef1f7 !important;
}

.input-bg.custom-dropdown-container input {
    background-color: #eef1f7 !important;
}

.form-label {
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    /*line-height: 28px !important;*/
    letter-spacing: 0.2px;
    color: #9fa2b4 !important;
}

.container-ar .input-bg {
    text-align: right !important;
}

.input-bg.custom-dropdown-container {
    border: none !important;
}

.dropdown.input-bg {
    background-color: #eef1f7;
    flex: 1 1;
    /*padding: 0.8em 4px !important;*/
    border-radius: 2px !important;
}

.ui.dropdown.error,
.ui.dropdown.error > .default.text,
.ui.dropdown.error > .text {
    color: #9f3a38 !important;
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
}

.field.error label.form-label {
    color: #9f3a38 !important;
}

textarea.input-bg {
    background-color: #eef1f7 !important;
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

.ui.input.input-bg input {
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
}

.ui.input.input-bg input:focus {
    background-color: #dfe0eb88;
}

textarea {
    background-color: #eef1f7;
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

textarea:focus {
    background-color: #dfe0eb88;
    border: none !important;
}

.dropdown.input-bg {
    flex: 1 1;
    /*padding: 0.8em 8px !important;*/
    background: #eef1f7 !important;
    border-radius: 2px !important;
    border: none !important;
    /*height:41px;*/
}

.ui.compact.selection.dropdown {
    width: 100%;
}

.img-upload {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #d5d5d5d1;
}

.search-input {
    width: 21%;
    height: 35px;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-input ::-webkit-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input ::-moz-placeholder {
    color: #c4c4c4 !important;
}

.search-input :-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input ::-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input ::placeholder {
    color: #c4c4c4 !important;
}

.search-dropdown {
    width: 21% !important;
    min-height: 35px !important;
    padding: 16px !important;
    font-size: 15px !important;
    line-height: 0em !important;
    margin: 4px !important;
}

.search-dropdown i.dropdown.icon {
    top: 6px !important;
}
.ui.input.input-bg input {
    background-color: #eef1f7 !important;
    flex: 1 1;
    padding: 0.8em 4px!important;
    border-radius: 2px!important;
    border: none !important;
}

.group-checkbox{
    flex-wrap: wrap;
    grid-gap:0.6rem;
    gap:0.6rem;
}


.upload-icon{
    position: absolute;
    right: 1%;
}

.ui.attached.label{
    position: relative !important;
}

.custom-dropdown-container {
    display: block;
    position: relative;
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    min-width: 14em;
    background: #fff;
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    min-height: 38px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.87);
}

.custom-dropdown-focused {
    z-index: 999;
}

input.custom-dropdown-input:focus {
    outline: none;
}

.custom-dropdown-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    /*min-height: 40px !important;*/
}

.dropdown-custom-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.custom-dropdown-input {
    border: none !important;
    width: 100% !important;
    /*min-height: 99% ;*/
    font-size: 1em !important;
    padding-left: 4px !important;
}

.container-ar .custom-dropdown-input{
    padding-left: 0 !important;
    padding-right: 4px !important;
}

.dropdown-custom-items .custom-dropdown-selected {
    background-color: #ffffff22;
    margin: .4em !important;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}

.custom-dropdown-items {
    max-height: 214px;
    background-color: white;
    position: absolute;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    width: 100%;
    overflow-y: auto;
    z-index: 1000;
}

.custom-dropdown-down {
    top: calc(100% + 2px);
}

.custom-dropdown-up {
    bottom: calc(100% + 2px);
}

.custom-dropdown-not-found {
    color: #c4c4c4;
    padding: 8px;
    text-align: center;
    font-weight: 200;
    font-size: 1rem !important;
    margin: 0 !important;
}

.custom-dropdown-loading {
    position: relative;
    height: 34px;
    /*margin-top: 20px;*/
}

.input-bg.custom-dropdown-container.error-custom-dropdown .custom-dropdown-main, .custom-dropdown-container.error-custom-dropdown .custom-dropdown-main {
    background-color: #fff6f6 !important;
}

.input-bg.custom-dropdown-container.error-custom-dropdown .custom-dropdown-main input, .custom-dropdown-container.error-custom-dropdown .custom-dropdown-main input {
    background-color: #fff6f6 !important;
}

.error-custom-dropdown, .input-bg.error-custom-dropdown {
    border-color: #9f3a38 !important;
}

.custom-dropdown-loading .custom-dropdown-loader {
    left: 50% !important;
    border-color: #767676 transparent transparent;
    box-shadow: 0 0 0 1px transparent;
}

.custom-dropdown-container .ui.loader {
    left: 95%;
}


.container-ar .custom-dropdown-container .ui.loader {
    right: 95%;
    left: 0;
}

.custom-dropdown-container > div {
    height: 100%;
}

.custom-dropdown-item img,
.custom-dropdown-selected img {
    width: 28px;
    height: 28px;
    border-radius: 34px;
    margin-right: 8px;
}

.custom-dropdown-container {
    font-size: 1.1em;
}

.custom-dropdown-selected {
    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.custom-dropdown-item {
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 18px 8px;
    background-color: #fff;
}

.custom-dropdown-item {
    border-bottom: 1px solid #dedede;
}

.custom-dropdown-item:hover {
    background-color: #23232318;
}

.custom-dropdown-item:last-child {
    border-bottom: 1px solid #fff !important;
}

.custom-dropdown-item-selected {
    border-left: 2px solid #979797;
}

input.custom-dropdown-input-search {
    /*margin: 1px 0 !important;*/
    border: none !important;
    max-width: 100% !important;
    /*width: auto !important;*/
}

.custom-dropdown-control-last {
    display: flex !important;
}

input.custom-dropdown-input-search:focus {
    outline: none !important;
    border-bottom: 1px solid #7a97f4 !important;
}

.custom-dropdown-disabled {
    opacity: .4;
}

input.custom-dropdown-input::-webkit-input-placeholder {
    opacity: .4;
}

input.custom-dropdown-input::-moz-placeholder {
    opacity: .4;
}

input.custom-dropdown-input:-ms-input-placeholder {
    opacity: .4;
}

input.custom-dropdown-input::-ms-input-placeholder {
    opacity: .4;
}

input.custom-dropdown-input::placeholder {
    opacity: .4;
}

.custom-dropdown-selected span, .custom-dropdown-selected {
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1rem !important;
}

.custom-dropdown-selected .icon {
    align-self: center;
    margin-top: -4px;
    margin-left: 4px;
}
.time-input-dropdown {
  background-color: #323232;
  /*border: 1px solid #32323211;*/
  position: absolute;
  top: 36px;
  z-index: 100;
  left: 0;
  color: #c4c4c4;
  width: 220px;
  display: flex;
  flex-direction: column;
}

.timeInput-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeInput-container .ui.loader {
  left: 90%;
}

.timeInput-container {
  width: 100%;
  position: relative;
  display: flex;
  overflow: visible;
}

.timeInput-disabled {
  opacity: 0.4;
}

.input-modal .timeInput-container.custom-input.input-bg.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.timeInput-container.custom-input.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.custom-input {
  font-family: Montserrat, serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.time-input-dropdown:focus {
  border: none !important;
  outline: none;
}

.time-input-items {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.time-input-items:first-child {
  border-right: 0.5px solid #32323211;
}

.time-input-items:nth-child(2) {
  border-left: 0.5px solid #32323211;
}

.time-input-dropdown > div {
  width: 100%;
  display: flex;
  position: absolute;
}

.time-input-items:hover .time-input-bottom {
  display: flex;
}

.time-input-items:hover .time-input-top {
  display: flex;
}

.timeInput-container {
  position: relative;
}

span.time-input-icon {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  border-top: 1px solid #c4c4c444;
}

.time-input-icon:first-child {
  background-color: green;
}

.time-input-icon:nth-child(2) {
  background-color: darkred;
}

span.time-input-icon:hover {
  opacity: 0.8;
}

.time-input-bottom,
.time-input-top {
  z-index: 200;
  display: none;
  background-color: #32323277;
  flex: 1 1;
  color: white;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.time-input-bottom:hover,
.time-input-top:hover {
  background-color: #323232;
}

.time-input-bottom {
  bottom: 0;
  margin-bottom: 0;
}

.time-input-top {
  top: 0;
  margin-bottom: 0;
}

span.time-input-item:hover {
  background: #979797;
}

.time-input-holder {
  display: block;
  position: absolute;
  top: calc(50% - 65px);
  left: 0;
  right: 0;
  width: 140px;
  height: 40px;
  -webkit-transform: translateY(62%);
          transform: translateY(62%);
  background-color: #7a97f444 !important;
}

span.time-input-item {
  height: 40px;
  text-align: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.time-input-control {
  border-top: 0.5px solid #32323211;
}

.time-input-down,
.time-input-down div {
  top: calc(100% + 2px);
  background-color: #323232;
}

.time-input-down .time-input-control {
  top: calc(100% + 202px);
}

.time-input-up div {
  bottom: calc(100% + 80px);
}
.time-input-up .time-input-control {
  bottom: calc(100% + 42px);
}
.time-input-items {
  background-color: #323232;
}

.time-input-up {
  bottom: calc(100% + 40px);
  background-color: #323232;
}

.time-input-down .time-input-holder {
  top: calc(58px);
  z-index: 99;
}

.time-input-up .time-input-holder {
  top: -224px;
  z-index: 99;
}

.ui.form .field.error input[type=tel]{
    border-left-color: #e0b4b4 !important;
}

.ui.error.left.labeled.input.input-bg input  {
    background: #fff6f6;
    color: #9f3a38;

}

.ui.error.left.labeled.input.input-bg .ui.compact.scrolling.dropdown.label{
    background: #fff6f6;
    color: #9f3a38;
}
.tab-custom {
    display: flex;
    flex: 1 1;
    /*width: 100%;*/
    overflow-x: hidden;
    overflow-y: visible;
}

.tab-custom > div {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.tab-custom .ui.text.menu {
    flex: 0 1;
    display: flex;
    font-family: Montserrat,serif;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #dadfea;
    justify-content: center;
    flex-direction: row;
}

.dark tfoot.full-width th {
    background-color: #121212 !important;
}

.tab-custom a.item {
    color: #dadfea !important;
    margin-right: 24px !important;
}

.tab-custom a.active.item {
    color: #9e94ff !important;
    border-bottom: 2px solid #9e94ff !important;
}

.tab-custom .ui.text.menu .item:hover {
    color: #9e94ff !important;
}

.tab-custom::-webkit-scrollbar {
    width: 10px;
    background-color: #2c2c2c02;
    height: 8px;
}

.tab-custom::-webkit-scrollbar-thumb {
    background: #2c2c2c20;
}

.calculator-container {
    width: 80%;
    margin: 0px auto 10px;
    padding: 0 !important;

}

.calculator-btns {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
}


.calculator-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5EFFE !important;
    border-radius: 4px;
    width: calc(100% / 4);
    height: calc(100% - 4px);
    text-align: center;
    font-family: Montserrat, serif;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333232;
    margin: 4px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    padding: 4px;
}

.calculator-container:focus {
    outline: transparent !important;
}


.cal-btn-active {
    background-color: #0F97FA !important;
    color: #333232 !important;
}

.cal-btn-white {
    background-color: #FFFFFF !important;
    border: 1.5px solid #E5EFFE;
    color: #333232 !important;
}

.calculator-row {
    height: calc(100% / 5 - 4px);
    display: flex;
}

.container-ar .calculator-row {
    flex-direction: row-reverse !important;
}

.calculator-display {
    height: 40px;
    margin: 4px;
    background-color: #FFFFFF;
    display: flex;
    text-align: right;
    flex-direction: column;
}

.calculator-history {
    color: #FFFFFF !important;
    padding-right: 4px;
}

.calculator-input {
    flex: 1 1;
    text-align: right;
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px;
    line-height: 30px;
    color: rgba(11, 108, 255, 0.65) !important;
    border: none;
    border-radius: 4px;
    height: 10px !important;
    width: 100% !important;
    background-color: #E5EFFE;
    padding: 10px;

}

.calculator-btn-operator {
    width: calc(100% / 3)
}

.ticket {
    font-family: Montserrat,serif;
}

.ticket-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 92%;
    height: 65px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #F9FAFB;
}

.container-ar .ticket-info{
    flex-direction: row-reverse;
}

.ticket-avatar {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50% !important;
}

.ticket-info .image {
    width: 35px;
}

.close-btn {
    position: absolute;
    right: 0;
    top: -50px;
    /* transform: translate(50%,-50%); */
}
.container-ar .close-btn{
    right:90%
}

.ticket-block {
    display: flex;
    flex-direction: column;
    flex: 4 1;
    position: relative;
    width: 95%;
    height: 89vh;
    margin: 0 auto;
    background-color: #fffcfc;
    font-style: normal;
}

.ticket-content {
    flex: 4 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.ticket-content-header {
    width: 100% !important;
    /*padding: 0% 3%;*/
    border-bottom: 1px solid #C4C4C4;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #333232;
}

.ticket-content-header span {
    font-weight: 900;
    font-size: 14px;
    line-height: 23px;
    color: #333232;
}

/************Ticket accordion**************/
.accordion-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    /* color: rgba(11, 108, 255, 0.65); */
}

.accordion-discount {
    display: flex !important;
    /* justify-content: space-evenly !important; */
    align-items: center !important;
    width: 28px;
    font-size: 12px !important;
    color: black;
}

.badge-qte input {
    width: 25px;
    height: 25px;
    color: #FFFFFF !important;
    font-size: 30 !important;
    padding: 0px !important;
    text-align: center !important;
    border-radius: 50% !important;
    background-color: rgba(11, 108, 255, 0.65) !important;
}

.badge-qte-minus {
    margin: 0 5px !important;
    padding: 3px !important;
    color: #FFFFFF !important;
    background: #FF7070 !important;
    border-radius: 5px;
}

.badge-qte-plus {
    margin: 0 5px !important;
    padding: 3px !important;
    color: #FFFFFF !important;
    background: #00B876 !important;
    border-radius: 5px;
}

.main-accordion {
    border-bottom: 1px solid #ccc;
}

.accordion-hide {
    height: 0 !important;
    overflow: hidden;
}

.accordion-show {
    height: auto;
}

.accordion-container {
    width: 99%;
    margin: 4px auto;
}

.accordion-container div{
    display: flex;
}

.ticket-accord-item {
    width: 32%;
    height: 58px !important;
    box-sizing: border-box !important;
    border-radius: 5px;
    margin: 2px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    position: relative;
}



.ticket-accord-item-edit {
    position: absolute !important;
    top: 1% !important;
    left: 4% !important;
}

.ticket-accord-item-delete {
    position: absolute !important;
    top: 4% !important;
    right: 4% !important;
}

.payment-icon-group {
    position: absolute !important;
    bottom: 4% !important;
    right: 4% !important;
}

.ticket-accord-item-payment {
    width: 15px !important;
    /*filter: drop-shadow(2px 2px 2px #00B876);*/
    -webkit-filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
            filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
}

.ticket-accord-item-payment-partial {
    width: 15px !important;
    -webkit-filter : invert(36%) sepia(65%) saturate(7470%) hue-rotate(211deg) brightness(102%) contrast(108%);
            filter : invert(36%) sepia(65%) saturate(7470%) hue-rotate(211deg) brightness(102%) contrast(108%);
    /* filter: drop-shadow(2px 2px 2px #0066ff); */
}

.ticket-accord-item-payment-edit {
    width: 15px !important;
}

.ticket-accord-item-payment-popup {
    width: 15px !important;
    /* -webkit-filter: invert(1); */
    -webkit-filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
            filter: invert(80%) sepia(162%) saturate(1267%) hue-rotate(132deg) brightness(171%) contrast(112%);
}

.ticket-accord-item-remise {
    width: 28px;
    position: absolute !important;
    top: 50% !important;
    left: 1% !important;
    font-size: 10px !important;
    color: black;
}

.ticket-accord-item-remise .image {
    position: absolute !important;
    bottom: 11px !important;
    left: 4% !important;

}

.participant-btn {
    padding: 22px !important;
    background: #297EFF !important;
    border-radius: 100% !important;
    font-size: 22px !important;
    color: #FFFFFF !important;
    position: relative;
    margin-left: 10% !important;
}

.participant-btn .plus {
    font-weight: 700;
    top: 5px;
    left: 8px;
    position: absolute;
}

.participant-btn .minus {
    font-weight: 700;
    bottom: 5px;
    right: 8px;
    position: absolute;
}

.participant-btn .separator {
    font-size: 55px !important;
    font-weight: 10 !important;
    color: #fffcfc;
    left: 20px;
    top: 7px;
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

/*********end ticket accordion*************/
.ticket-footer {
    flex: 1 1;
}

.ticket-grey-block {
    max-height: 45px !important;
    background-color: #C4C4C4;
    position: relative;
    padding: 2px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    color: #FFFFFF;
    text-transform: uppercase !important;
}

.ticket-grey-block-price {
    position: absolute;
    right: 6%;
    font-size: 20px !important;
    line-height: 35px;
    font-weight: 800;
}

.container-ar .ticket-grey-block-price{
    right: 80%;
}

.payment-action-list {
    min-height: 50px;
    padding: 10px 10px 10px 20px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    color: #00B876;
}

.payment-remainder-label {
    padding: 10px 10px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
}
.payment-remainder {
    padding: 10px 5px;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
}

.ticket-menu {
    padding: 10px 15px;
    min-height: 100px;
}

.ticket-menu-item {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 12px !important;
    width: 32%;
    border-radius: 5px;
    margin: 2px 1px 1% !important;
    padding: 2px !important;
}

.ticket-menu-item-img {
    height: 25px !important;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-grey-off {
    padding: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #333232 !important;
    background: #DFE0EB !important;
}

.btn-grey-off img {
    height: 27px !important;
    -webkit-filter: invert(1);
    filter: invert(80%);
}

.btn-grey-on {
    padding: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #FFFFFF !important;
    background: #297EFF !important;
}

.btn-grey-on img {
    height: 27px !important;
    -webkit-filter: none;
            filter: none;
}

.btn-white {
    color: #333232 !important;
    border: 1px solid #333232 !important;
    background: #FFFFFF !important;
}

.ticket-menu-cash-on {
    color: #FFFFFF !important;
    background-color: #00B876 !important;
}

.ticket-menu-cash-off {
    color: #FFFFFF !important;
    background-color: #FF7070 !important;
}


/*************Modal********/
button.ui.button.ticket-accord-item span {

    padding: 0.8em;
    flex: 1 1;
    display: block;
}

i.edit.icon.ticket-accord-item-edit {
    font-size: 14px;
    padding: 2px;
}
i.edit.icon.ticket-accord-item-delete{
    font-size: 14px;
    padding: 2px;
}

button.ui.button.ticket-accord-item {
    padding: 0 !important;
}

.withOpacity {
    opacity: 0.6;
}
.center-modal{
    position: absolute;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.success-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.success-modal span {
    font-size: 80px !important;
    line-height: 80px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-ticket {
    justify-content: center !important;
    /*overflow-y: hidden !important;*/
    position: relative;
    height: auto;
    /*max-height: 320px;*/
}

.modal-content {
    padding: 10px !important;
    height: auto !important;
    min-height: 50px !important;
}

.modal-content-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: #7EB5E5;
    padding-top: 10px;
}

/* .modal-btn-close{
	position: absolute;
	right: 10px;
	top:10px;
} */
.modal-content-body p {
    font-style: italic;
    font-weight: 200;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #7EB5E5;
    margin: 40px auto;
}

.btn-group {
    display: flex;
    justify-content: center;
}

.modal-delete-btn {
    background: #CCFFED !important;
    color: #00B876 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    margin: 15px !important;
}

.modal-cancel-btn {
    color: #FFFFFF !important;
    background: #FF7070 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    margin: 15px !important;
}

/********Modal player information*****/
.modal-edit-player {
    height: auto !important;
}

.search-bloc {
    /* text-align: center; */
    width: 75%;
    height: auto;
    margin: 2% auto !important;
}

.search-input-player {
    width: 100%;
}

.search-bloc input {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 16px !important;
    color: #989595 !important;
    background: #FFFFFF !important;
    border: 1px solid #F5F3F3 !important;
    box-sizing: border-box !important;
    border-radius: 30px !important;
}

.add-player {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 14px !important;
    color: #989595 !important;
    margin-top: 1%;
}

.add-player .button {
    margin-right: 1%;
}

.player-informations {
    min-height: 450px;
    background: #F9FAFB !important;
    padding: 10px 0px;
}

.search-list {
    margin: 2% auto !important;
    text-align: center;
    width: 85%;
}

.player-row {
    margin: 15px auto;
    background-color: #FFFFFF !important;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;;
    color: #989595;
    display: grid;
    grid-template-columns: 32% 32% 32%  5%;
}

.player-row span {
    margin: 0px 1%;
    padding: 10px 5px;
    text-align: left;
}

.add-player-bloc {
    margin: 5px auto !important;
    text-align: center;
    width: 30%;
}

.player-input .form-label {
    font-style: italic;
    font-weight: 300;
    color: #7EB5E5 !important;
}

.player-green-btn {
    margin-top: 0px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    color: #00B876 !important;
    background: #CCFFED !important;
    border-radius: 10px !important;
}

.edit-player-bloc {
    margin: 1% auto !important;
    text-align: right;
    width: 95%;
}

.player-input .form, .edit-participant-input .form {
    margin-bottom: 5px;
}

.amount-bloc {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 8%;
    padding: 14px 0px;

}

.reload-span {
    margin-left: 5% !important;
}
span.amount-bloc.amount-bloc-reload  button.ui.button.btn-inline-icon {
    margin-left: 25%;
}
.amount-bloc .button {
    font-style: italic !important;
    font-weight: 200 !important;
    font-size: 14px !important;
    text-align: center !important;
    color: #6BCE9F !important;
    background: #FFFFFF !important;
    border: 1px solid #6BCE9F !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding: 0px !important;
    /*margin-left: 10%;*/
}

.btn-inline-icon {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.group-btn-edit-player {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px auto;
    width: 40%;
}

.btn-select {
    margin-top: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    color: #00B876 !important;
    background: #CCFFED !important;
    border-radius: 10px !important;
}

.btn-payment {
    background: rgba(11, 108, 255, 0.08) !important;
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    color: rgba(11, 108, 255, 0.65) !important;
    padding: 8px 24px !important;
    margin-top: 10px !important;
    border-radius: 10px !important;
}

/********end modal : player information*****/

/*****Modal : add participant****/
.modal-add-participant {
    min-height: 380px !important;
}

.edit-participant-input {
    margin: 45px auto 0px !important;
    width: 50%;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(11, 108, 255, 0.65);
}

.edit-participant-input input {
    font-style: normal !important;
    font-size: 24px !important;
    font-weight: 800 !important;
    text-align: center !important;
    color: rgba(11, 108, 255, 0.65) !important;
    padding: 6px !important;
    width: 120px;
    background: rgba(51, 50, 50, 0.1) !important;
}

/****end modal : add participant***/
.ticket-finished-table {
    width: 85% !important;
    margin: 2px auto !important;
    color: rgba(0, 184, 118, 0.65) !important;
}

.occ-nb-style {
    border-radius: 50% !important;
    background-color: rgba(0, 184, 118, 0.65) !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    padding: 2px 8px !important;
    text-align: center !important;
}


.ticket-loader {
    width: 20px;
    position: relative;
    height: 20px;
}

.loading-ticket::after {
    border-color: darkred !important;
}

span.error-msg {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: darkred;
    /* text-transform: capitalize; */
}

span.amount-bloc-reload {
    display: grid;
    grid-template-columns: 70% 30%;
    margin-left: 0;
    padding: 14px 0;
}

/** ticket details ***/
.details-btn {
    width: 24% !important;
    height: 44px !important;
    border-radius: 8px !important;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF !important;
    padding: 0px !important;
    margin: 16px 1px !important;
}

.purple-details-btn {
    background: rgba(173, 0, 255, 0.65) !important;
}

.orange-details-btn {
    background: #F2994A !important;
}

.green-details-btn {
    background: #00B876 !important;
}

.red-details-btn {
    background: #FF7070 !important;
}

/******************/
.no-padding-grid {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.invoice-information {
    padding-top: 10px;
}

.invoice-information .form-label {
    color: #7EB5E5 !important;
    font-weight: 300;
}
.label-invoice{
    margin-left: 10px;
    padding: 8px;
    color: white;
    background-color: #1678c2;
    border-radius: 2px;
}


/****note division****/
.img-division{
    width: 25px;
    height: 25px;
    border-radius: 4px;
    font-size: 1.4rem;
}
.img-division-inverse{
    width: 25px;
    height: 25px;
    background-color: rgb(0, 184, 118) !important;
}
.no-opacity{
    opacity: 0;
}
.header-note{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding : 3px 10px !important;
    font-size: 14px;
    line-height: 26px;
    color: #252733;
    font-weight: 900;
}

.ticket.ticket-loading {
    position: relative;
}

.ticket.ticket-loading:before {
    background: #eeeeee88 !important;
    content: "";
    position: absolute;
    top: 0;
    bottom: -50px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.ui.active.centered.inline.loader.ticket-loading-loader {
     position: absolute;
     top: 50%;
     left: 50%;
 }

span.note-btn {
    font-size: 1em !important;
    padding: 0.2rem 0.2rem;
    border: 2px solid #333232;
    margin-right: 0.4rem;
    border-radius: 2px;
    cursor: pointer;
}

span.note-active{
    background-color: rgb(0, 184, 118) !important;
    border-color: #33323299 !important;
    color: #ffffff;
}
.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1;
}

.loader-table-body {
    position: relative !important;
}

th.tab-header.bg-tab {
     padding: 8px !important;
 }

.dark tfoot.full-width tr th {
    background-color: #121212 !important;
}

.td-text {
    font-size: 1.2rem;
    min-height: 75px;
    padding-left: 8px !important;
    word-break:keep-all;
    text-overflow: ellipsis;
}

.tools a {
    align-self: center;
}

.with-dimmer {
    min-height: 80px;
    padding: 14px 8px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAnEAAABCCAMAAAAPInAgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZFOEY5NDREQTMxMUU0OEI0MDhCMEM5RjBBNDFEMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZFOEY5NTREQTMxMUU0OEI0MDhCMEM5RjBBNDFEMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0NkU4RjkyNERBMzExRTQ4QjQwOEIwQzlGMEE0MUQzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0NkU4RjkzNERBMzExRTQ4QjQwOEIwQzlGMEE0MUQzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+jI2B7AAAAF1QTFRFvL7AwMLEyMnL5ufoycrMxsjKwcPFzc7Q8PDx+fn68fHy0dLUyszNxsjJvsDB7+/w5OXmxMXH6uvrxsfJ+vr7wsTG+fn50NHTvsDCxcbIysvN7e3u4+Tl1dfY////DQ3b9AAAAB90Uk5T////////////////////////////////////////AM0ZdhAAAADiSURBVHja7NrHDcMwEEVBOknOOaf+y/TdhnkiFwI0U8Li3fany2kIUZpXmiSI0yoOxaE4UByKA8WhOBQHgcWtHIHQ4vbbEUR5PtIbIikOxaE4UByKA8WhOBQHikNxoDgUB4qjU8Xd0wD+Ws9LF2cDTNZUcSgOxSkOxaE4xaE4FIfiFIfiUJzi6GJxNzclZ1O6uKVPPhnX4p988xns41AcKA7FgeJQHIoDxaE4UByKA8WhOPpb3MECjHqa809xNsDULG6mOBSH4kBxKA4Uh+JQnOJQHL0pbrEbQy3t8bu4jwADAOWOuJvLET/EAAAAAElFTkSuQmCC);
}

.table-footer-component .text {
    color: #9fa2b4;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
    margin-left: 8px;
}

.table-footer span {
    color: #9fa2b4;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
}
.table-footer-component {
    white-space: nowrap;
}

.table-footer .table-footer-component {
    margin-right: 60px;
}

.table-footer-component .text,
.table-footer-component {
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
}

.table-footer-component i {
    color: #9fa2b4;
}

.full-width th {
    background: white !important;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: center;
}


/***  tabler pagibnation **/


.table-footer-component.table-pagination .ui.mini.pagination.menu {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    border: none;

    background: none;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a.active.item {
    border-radius: 4px !important;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a {
    color: #9fa2b4 !important;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
    border: none !important;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a::before {
    content: none
}


tr.empty-row img {
    width: 54px;
    height: 54px;
    opacity: .5;
    /* fill: red; */
    /* display: flex; */
}

tr.empty-row td {
}

.empty-row td p {
    font-family: Montserrat,serif;
    font-size: 18px;
    font-weight: 500;
    opacity: .7;
    color: #323232;
}

.empty-row {
    background-color: #25273303;
}

.table-footer-component.table-footer-total {
    /* margin-right: 54%; */
    display: flex;
    flex: 1 1;
}

tfoot.full-width th {
    padding: 12px !important;
}

.dark .empty-row td p {
    color: #fff;
}

.dark .empty-row td img {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.client-list {
    font-family: Montserrat,serif !important; ;
    background: rgba(255, 255, 255, 0.97) !important;
}

.client-list-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 20px;
}

.client-list-body p {
    font-style: italic;
    font-weight: 200;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #7eb5e5;
    margin: 40px auto;
}

.search-bloc {
    width: 75%;
    height: auto;
    margin: 2% auto !important;
}

.search-input-client {
    width: 100%;
}

.search-bloc input {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 16px !important;
    color: #989595 !important;
    background: #ffffff !important;
    border: 1px solid #f5f3f3 !important;
    box-sizing: border-box !important;
    border-radius: 30px !important;
}

.add-client {
    font-style: italic !important;
    font-weight: 100 !important;
    font-size: 14px !important;
    color: #989595 !important;
    margin-top: 1%;
}

.add-client .button {
    margin-right: 1%;
}

.search-content {
    margin: 2% auto !important;
    text-align: center;
    width: 80%;
}

.client-row {
    margin: 0px auto;
    background-color: #ffffff !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: rgba(51, 50, 50, 0.65);
    display: grid;
    grid-template-columns: 10% 30% 25% 25% 100px;
    align-items: center !important;
}

.client-row span {
    padding: 0px 5px;
    text-align: left;
}

.client-name {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #333232 !important;
}

.client-price {
    font-size: 16px;
    line-height: 18px;
    color: rgba(11, 108, 255, 0.65);
}

.client-expire-date {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #00b876;
}

.client-btn-detail {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    color: #ffffff !important;
    background: rgba(11, 108, 255, 0.65) !important;
}

/******add client*****/
.add-client-block {
    margin: 0px auto;
    width: 85%;
}

.client-input .form-label {
    font-style: italic;
    font-weight: 500;
    color: #7eb5e5 !important;
}

.client-green-btn {
    margin-top: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #00b876 !important;
    background: #ccffed !important;
    border-radius: 10px !important;
}

/*********Client details****/
.client-details {
    margin: 15px 30px;
}

.back-btn {
    margin-right: 30px;
    text-align: right;
}

.profile-page {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.profile-header {
    display: flex;
    overflow: hidden;
    min-height: 155px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0778245);
}

.dark .profile-header {
    background: #333;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.0778245);
}

.dark .profile-details-items {
    color: #dfe0eb;
}

.profile-details {
    flex: 1 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.profile-details-items {
    display: flex;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2c2c2c;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
}

button.ui.circular.icon.button.profile-btn {
    background: #fff;
    border: none;
}

button.ui.circular.icon.button.profile-btn:hover {
    background-color: #33333333;
}

.profile-details img {
    align-self: center;
    min-width: 120px !important;
    min-height: 120px !important;
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 50% !important;
    margin: 0 10px;
    border: 1px solid #cccccc44;
}

.profile-details img.ui.circular.image {
    /* width: 132px !important;
      height: 124px !important; */
}

.profile-info {
    position: relative;
    display: grid;
    flex: 1 1;
    flex-direction: column;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    z-index: 1;
    color: #ffffff;
    align-items: center;
    justify-content: space-around;
    padding-top: 1% !important;
    padding-left: 8% !important;
}

button.ui.circular.icon.button.profile-btn i {
    padding-top: 2px !important;
    width: 16px !important;
    height: 16px !important;
    font-size: 1em !important;
}

.profile-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.profile-btn i {
    font-weight: 200 !important;
    color: #e0e1e2;
}

.profile-credit {
    position: relative;
    display: flex;
    flex: 0.7 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    z-index: 1;
    letter-spacing: 0.2px;
    color: #444c63;
    text-align: center;
}

.profile-credit::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #dfe0eb;
    /*background-color: #9f3a38;*/
    content: '';
    z-index: -1;
    width: 200%;
    height: 200%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
}

/*.profile-credit img {*/
/*    width: 25%;*/
/*    height: 25%;*/
/*}*/

.profile-info::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #aeb9d6;
    /* background-color: #9f3a38; */
    content: '';
    z-index: -1;
    width: 100%;
    height: 350%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotateZ(15deg) translate(0%, -20%);
            transform: rotateZ(15deg) translate(0%, -20%);
}

.profile-phone {
    color: #7a97f4;
}

@media screen and (max-width: 992px) {
    .profile-header {
        flex-direction: column;
        min-height: 480px;
    }

    .profile-details {
        flex-direction: column;
        justify-content: space-around;
    }

    .profile-details-items {
        align-items: center;
        flex: 1 1;
        justify-content: space-around;
    }

    .profile-credit::before {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    .profile-info::before {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    .profile-info span {
        padding: 4px 0;
    }

    .profile-credit {
        padding: 10px;
    }
}

.ui.modal .scrolling.content {
    max-height: calc(100vh);
    overflow: auto;
}

.detail-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 480px;
}

.detail-modal-header {
    display: flex;
    justify-content: space-between;
}

.detail-modal-credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 24px; */
}

.detail-modal-date div > span:last-child {
    color: #252733;
}

.detail-modal-main.recharge-modal-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail-modal-main.recharge-modal-main .round-btn {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    width: 120px;
}

.detail-modal-main.recharge-modal-main form {
    display: flex;
    flex-direction: column;
}

.detail-modal-credit img {
    width: 4rem;
    height: 4rem;
}

.detail-modal-main {
    /* display: flex; */
    /* flex-direction: column; */
    min-height: 300px;
}

.detail-modal-main button {
    margin-bottom: 14px !important;
}

tfoot.sub-footer tr th {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    color: white !important;
    font-size: 18px !important;
}

.detail-modal-main-container thead tr th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* line-height: 28px; */
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4 !important;
    background: white !important;
}

.detail-modal-main-container tbody tr td {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;

    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733 !important;
}

.detail-modal-date {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
}

.detail-modal-date h3 {
    margin-top: 0;
    margin-right: 44px;
}

.detail-modal-date div {
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    text-align: center;
    color: #9fa2b4;
}

.detail-modal-credit span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* or 56% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
    text-align: center;
}
.user-credit{
    margin-right: 14px;
    width: 3rem !important;
    height: 3rem !important;
}
.profile-credit-item{
    font-size: 1rem;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.credit-btn {
    align-self: flex-end;
}

.withloader-container {
    padding: 18px;
    width: 100%;
}

.withloader-container .ui.segment {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}
.modal-description {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: 380px;
    /* font-family: Roboto !important; */
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #979797;
}

.modal-description p {
     margin: 0 auto;
}

.modal-description button {
    background-color: #7e72f2 !important;
    color: white !important;
    font-size: 18px !important;
    margin-top: 5% !important;
}
.client-reset-modal{
    padding:1fr !important;
    margin: 0 !important;
}

.modal-btn-save{
    margin-top:20px !important;
}
.subscriber-container {
    display: flex;
    flex-direction: column;
    text-align: left !important;
}

.tab-subscriber {
    overflow: hidden !important;
}


th.two.wide.payment-footer-title {
    line-height: 30px;
}

.subscriber-title {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    color: #9fa2b4;
    margin-bottom: 18px;
    margin-top: 18px;
    height: 68px;
}

.empty-input {
    height: 70px;
    display: block;
}

.no-padding-subscriber {
    padding-top: 0 !important;
    padding-bottom: 4px !important;
}

.subscriber-border-bottom {
    /*border-bottom: 1px solid #979797;*/
    border-bottom: 1px solid #dfe0eb;
}

.no-btn-style {
    background-color: transparent !important;
}

.full-width-input {
    width: 100% !important;
}

.download-btn {
    margin-top: 8px !important;
    width: 100px;
    padding: 0 !important;
    height: 24px;
    display: flex !important;
    align-items: center;
    background-color: #9e94ff !important;
    border-radius: 5px !important;
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    letter-spacing: 0.2px;
    color: #ffffff !important;
    cursor: pointer;
}

.row-user {
    display: flex;
    align-items: center;
}

.download-btn > img {
    padding-right: 6px;
    padding-left: 6px;
}

.subscriber-file-input {
    position: absolute;
    left: -9999px;
    /*display: none !important;*/
}

.file-preview {
    display: flex;
}

.file-preview span {
    margin: 1rem;
}

.next-subscriber {
    width: 155px;
    height: 34px;
    background: #72f286 !important;
    border-radius: 100px !important;
    color: white !important;
}

.bottom-controller-subscriber {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.save-subscriber {
    background-color: #15a4fa !important;
}

.round-credit {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 32px;
}

span.modal-reload-credit {
    display: flex;
    margin-top: 12px;
}

.profile-page {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.profile-header {
    display: flex;
    overflow: hidden;
    min-height: 155px !important;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0778245);
}

.dark .profile-header {
    background: #333;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.0778245);
}

.dark .profile-details-items {
    color: #dfe0eb;
}

.profile-details {
    flex: 1 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.profile-details-items {
    display: flex;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2c2c2c;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
}

button.ui.circular.icon.button.profile-btn {
    background: #fff;
    border: none;
}

.subscriber-btn {
    background: #9e94ff !important;
    color: white !important;
}

.subscriber-btn i {
    font-weight: 200 !important;
    color: #fff !important;
    font-size: 24px !important;
}

button.ui.circular.icon.button.profile-btn:hover {
    background-color: #33333333;
}

.search-custom-dropdown {
    width: 19% !important;
    height: 35px;
    margin: 4px;
    font-size: 16px;
}

.custom-dropdown-container {
    /*min-width: 5em !important;*/
    min-height: 35px !important;
    font-size: 16px !important;
}

.custom-dropdown-main {
    min-height: 35px !important;
}

.custom-dropdown-input {
    height: 85% !important;
    padding-left: 15px !important;
}


.search-it {
    width: 19% !important;
    height: 35px !important;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-it ::-webkit-input-placeholder {
    color: #c4c4c4 !important;
}

.search-it ::-moz-placeholder {
    color: #c4c4c4 !important;
}

.search-it :-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-it ::-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-it ::placeholder {
    color: #c4c4c4 !important;
}

.search-date input {
    max-width: 100% !important;
    padding: 5px 8px !important;
}

.profile-details img {
    align-self: center;
    min-width: 120px !important;
    min-height: 120px !important;
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 50% !important;
    margin: 0 10px;
    border: 1px solid #cccccc44;
}

.profile-details img.ui.circular.image {
    /* width: 132px !important;
      height: 124px !important; */
}

.profile-info {
    position: relative;
    display: grid;
    flex: 1 1;
    flex-direction: column;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    z-index: 1;
    color: #ffffff;
    align-items: center;
    justify-content: space-around;
    padding-top: 1% !important;
    padding-left: 8% !important;
}

button.ui.circular.icon.button.profile-btn i {
    padding-top: 4px;
    width: 24px;
    height: 24px;
}

.profile-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.profile-btn i {
    font-weight: 200 !important;
    color: #e0e1e2;
    font-size: 24px !important;
}

.profile-credit {
    position: relative;
    display: flex;
    flex: 0.7 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    z-index: 1;
    letter-spacing: 0.2px;
    color: #444c63;
}

.profile-credit::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #dfe0eb;
    /*background-color: #9f3a38;*/
    content: '';
    z-index: -1;
    width: 200%;
    height: 200%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
}

.profile-credit img {
    width: 25%;
    height: 25%;
}

.profile-info::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #aeb9d6;
    content: '';
    z-index: -1;
    width: 100%;
    height: 350%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotateZ(15deg) translate(0%, -20%);
            transform: rotateZ(15deg) translate(0%, -20%);
}

.profile-phone {
    color: #7a97f4;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 992px) {
    .profile-header {
        flex-direction: column;
        min-height: 480px;
    }

    .profile-details {
        flex-direction: column;
        justify-content: space-around;
    }

    .profile-details-items {
        align-items: center;
        flex: 1 1;
        justify-content: space-around;
    }

    .profile-credit::before {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    .profile-info::before {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    .profile-info span {
        padding: 4px 0;
    }

    .profile-credit {
        padding: 10px;
    }
}

.ui.modal .scrolling.content {
    max-height: calc(100vh);
    overflow: auto;
}

.detail-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 480px;
}

.detail-modal-header {
    display: flex;
    justify-content: space-between;
}

.detail-modal-credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 24px; */
}

.detail-modal-date div > span:last-child {
    color: #252733;
}

.detail-modal-main.recharge-modal-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail-modal-main.recharge-modal-main .round-btn {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    width: 120px;
}

.detail-modal-main.recharge-modal-main form {
    display: flex;
    flex-direction: column;
}

.detail-modal-credit img {
    width: 80px;
    height: 80px;
}

.detail-modal-main {
    /* display: flex; */
    /* flex-direction: column; */
    min-height: 300px;
}

.detail-modal-main button {
    margin-bottom: 14px !important;
}

tfoot.sub-footer tr th {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    color: white !important;
    font-size: 18px !important;
}

.detail-modal-main-container thead tr th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* line-height: 28px; */
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4 !important;
    background: white !important;
}

.detail-modal-main-container tbody tr td {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;

    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733 !important;
}

.detail-modal-date {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
}

.detail-modal-date h3 {
    margin-top: 0;
    margin-right: 44px;
}

.detail-modal-date div {
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    text-align: center;
    color: #9fa2b4;
}

.detail-modal-credit span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* or 56% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
    text-align: center;
}

.no-overflow-x {
    overflow-x: hidden !important;
    overflow-y: visible !important;
}

.no-bg {
    background-color: transparent !important;
}

.table-container {
    display: flex !important;
    flex-direction: column;
    flex: 1 1;
}

.modal-reload-header {
    display: flex;
    padding: 12px 40px;
    position: relative;
    /*min-height: 134px;*/
    width: 100%
}

.modal-reload-main.content h3 {
    margin-bottom: 36px;
    margin-top: 14px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    /* identical to box height, or 67% */
    letter-spacing: 0.2px;

    /* grayscale / black */
    color: #252733;
}

span.modal-reload-credit .round-credit {
    margin-top: 12px;
    margin-right: 4px;
    width: 24px;
    height: 24px;
}

button.ui.button.modal-btn-save {
    margin-top: 68px;
    margin-left: 45px;
    background: #9e94ff !important;
    border-radius: 100px !important;
    width: 200px;
    height: 40px;
    color: white;
}

.modal-close-btn {
    color: #979797 !important;
    position: absolute;
    right: 0;
    top: 0;
    font-family: Montserrat, serif !important;
    background-color: transparent !important;
    font-size: 24px !important;
    padding: 0 !important;
}

.modal-reload-complex {
}

.modal-reload-complex {
    /* flex: 1; */
    display: flex;
}

.modal-reload-user {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

span.modals-reload-name {
    padding-top: 12px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    /* identical to box height, or 67% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.modal-reload-main.content {
    display: flex;
    justify-content: center !important;
    text-align: center;
    align-items: center !important;
    width: 80% !important;
    margin: auto;
}

tr.payment-row td div {
    white-space: pre-wrap !important;
}

.modal-reload-date {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    justify-content: flex-end;
}

.modal-reload-user img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

.modal-reload-complex img {
    width: 70px;
    height: 70px;
}

span.modal-reload-complex-name {
    padding-left: 8px;
}

span.modal-reload-complex-name h3 {
    margin-bottom: 0px;
    margin-top: 12px;
}

span.modal-reload-slug {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4;
}

span.modal-reload-date {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    text-align: center;
}

.textArea-input-bg {
    width: 50%;
    background: #eef1f7;
    border-radius: 5px;
    margin-bottom: 24px;
    font-size: 16px !important;
    min-height: 120px;
}

.info-subscriber {
    /*margin-left: 24px;*/
}

.payment-border {
    padding: 12px;
    border-bottom: 1px solid #dfe0eb;
}

.payment-date-container {
    display: flex;
    flex-direction: column;
}

.payment-credits {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-reload-complex.payment-credits > div {
    display: flex;
}

.modal-reload-complex.payment-credits .modal-reload-credit {
    flex: 1 1;
    align-items: center;
    justify-content: center;
    display: flex;
}

.modal-reload-complex.payment-credits .modal-reload-credit > span {
    width: 34px;
    height: 34px;
    margin-top: 18px;
    margin-right: 45px;
}

tr.payment-table-header th {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    background-color: transparent !important;
    border: none !important;
    /* identical to box height */

    letter-spacing: 0.2px;

    color: #606060 !important;
}

td.payement-index {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 20px;
    /* identical to box height, or 67% */

    text-align: center;
    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.deadlines-bg {
    border-radius: 4px;
    background-color: #eaeaea3b;
    margin-top: 8px;
}

tr.payment-table-header {
    margin-bottom: 8px !important;
}

.deadlines-padding .form {
    margin-bottom: 0 !important;

}

.deadlines-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

tr.payment-row.payment-row-loading td {
    background-color: #fff !important;
    border-color: #fff !important;
}

tr.payment-row.payment-row-loading td:first-child, tr.payment-row.payment-row-loading td:last-child {
    border: none !important;
    border-color: #fff !important;
}

.ui.segment.no-shadow-box {
    box-shadow: none !important;
    border: none !important;
}

tr.payment-row {
    background: #eef1f7;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;

    border-radius: 10px;
    margin-bottom: 26px !important;
}

.payment-row-red {
    background: #ff000030 !important;
}

tr.payment-row.payment-row-loading {
    background-color: white !important;
}

tr.payment-row td {
    padding: 4px !important;
    /* max-height: 10px !important; */
    height: 42px !important;
}

td.payement-index {
    width: 25px !important;
}

.payments-container table.ui.fixed.table {
    /* min-height: 420px !important; */
    border: none;
    border-collapse: separate;
    border-spacing: 0 24px;
    border-radius: 10px !important;
}

tr.payment-row td > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 14px;
    /*text-align: center;*/
    width: 100%;
    height: 100%;
    max-height: 60px !important;
    overflow: hidden;
    vertical-align: middle !important;
    /*margin-bottom: 8px;*/
}

tr.payment-row td {
    height: 60px !important;
    border: none !important;
    line-height: 20px !important;
    vertical-align: middle !important;
}

tr.payment-row {
    margin-bottom: 14px !important;
    /*background: #eef1f7 !important;*/
    border-radius: 10px !important;
}

tfoot.payment-footer {
    width: 100%;
}

tr.payment-footer-row {
    border-radius: 10px !important;
}

tr.payment-footer-row th {
    padding: 20px !important;
    border: none !important;
    background: #9e94ff !important;

    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 20px;

    text-align: center;
    letter-spacing: 0.2px;

    text-overflow: unset !important;

    color: #ffffff !important;
}

.no-overflow {
    overflow: hidden !important;
}

.modal-reload-container {
    min-height: 45% !important;
}

.ui.large.modal.transition.visible.active.modal-reload-container {
    display: flex !important;
    flex-direction: column;
    min-height: 80vh !important;
}

.modal-reload-container .content {
    display: flex !important;
    flex: 1 1;
    flex-direction: column;
}

.modal-reload-main.content .input-bg {
    width: 100% !important;
}

/*.modal-reload-container tr.payment-row{*/
/*    transform: scale(1);*/
/*}*/

.payments-container .table-container {
    margin-top: 0 !important;
    display: flex !important;
    flex: 1 1;
}

span.label-round-credit {
    padding: 4px 8px;
    border-radius: 16px;
    color: white;
}

.regulation-modal-container {
    flex: 1 1;
    display: flex;
    overflow: visible !important;
}

.regulation-modal-input.custom-dropdown-container {
    flex: .86 1;
}

.regulation-modal-cell {
    overflow: visible !important;
}

tr.payment-row td:first-child {
    /* border: 4px solid #eef1f7 !important; */
    border-radius: 5px 0 0 5px !important
}


tr.payment-row td:last-child {
    /* border: 4px solid #eef1f7 !important; */
    border-radius: 0 5px 5px 0 !important
}

.regulation-input img.filter-img {
    -webkit-filter: invert(100%) !important;
    filter: invert(100%) !important;
}

.regulation-modal-check {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
}

.regulation-modal-container .form {
    margin-bottom: 0 !important;
    flex: 1 1;
}

.subscriber-alert-msg {
    font-size: 1rem;
    display: flex;
    margin-top: -3rem;
    margin-bottom: 2rem;
    color: #db282899;
}

@media screen and (max-width: 800px) {
    .modal-reload-header {
        flex-direction: column;
        align-items: center;
    }

    .modal-reload-complex,
    .modal-reload-user,
    .payment-date-container {
        padding: 14px;
    }

}

@media screen and (max-width: 991px) {
    .computer-only {
        background-color: red;
        display: none !important;
    }
}

.ticket-list {
    font-family: Montserrat, serif !important;
    height: 100vh;
}

.container-ar .empty-row td.left.aligned {
    text-align: right;
}

.container-ar .empty-row td.right.aligned {
    text-align: left;
}

.ticket-list-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 20px;
}

.ticket-list-body {
    padding: 10px;
}

.ticket-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 31% 6% 8% 7% 7% 7% 7% 8%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-activity-container {
    display: flex;
}

.ticket-list-item-reservation {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 16% 6% 8% 7% 7% 7% 7% 8%;
    margin-bottom: 14px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item-details-reservation {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 27% 28% 12% 12% 15%;
    margin-bottom: 14px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 55% 12% 12% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
}

.ticket-list-item-pending div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
}

.ticket-family {
    height: 100% !important;
    margin: 0px !important;
    border-radius: 4px 0px 0px 4px;
    color: #ffffff !important;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    -webkit-text-orientation: upright;
            text-orientation: upright;
    padding: 3px !important;
    align-items: center;
    border-right: none !important;
}

.ticket-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-information span {
    font-size: 12px !important;
    color: #252733 !important;
    font-weight: 400 !important;
}

.list-ticket-loading {
    display: flex;
    width: 100%;
    flex: 1 1;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.ticket-sub-family {
    display: flex;
    align-items: center;
    text-align: left;
    color: #252733 !important;
    font-weight: 600 !important;
    font-size: 13px;
}

.ticket-sub-family .image {
    margin-left: 8% !important;
    margin-right: 5% !important;
}

.ticket-amount {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-amount span {
    color: #252733 !important;
    font-weight: 600 !important;
    margin-top: 15px !important;
}

.ticket-activity {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
}

.ticket-activity span {
    color: #252733 !important;
    font-weight: 600 !important;
}

.ticket-article {
    color: rgba(173, 0, 255, 0.65) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-article-pending {
    border: none !important;
}

.ticket-article span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-price {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-price .image {
    margin: 0 auto !important;
    height: 25px !important;
}

.ticket-price span {
    color: #252733 !important;
    font-weight: 600 !important;
    margin-top: 12px !important;
}

.ticket-food {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #252733;
    flex: 1 1;
}

.center-img {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ticket-buttons {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    padding: 8px 16px !important;
    margin: 0 !important;
    /* text-align: right !important; */
    border-right: none !important;
}

.ticket-buttons button {
    font-size: 12px !important;
    line-height: 14px !important;
    color: #ffffff !important;
    align-items: center !important;
    width: 72px !important;
    height: 30px;
    padding: 0px !important;
    margin-bottom: 3px !important;
}

.ticket-btn-detail {
    background: rgb(0, 184, 118) !important;
}

.ticket-btn-edit {
    background: rgba(11, 108, 255, 0.65) !important;
}

.ticket-btn-payment {
    background: #ff7070 !important;
}

/* css for pagination */
.ticket-list-body {
    min-height: calc(80vh - 60px);
}

.ticket-pagination {
    display: flex;
    flex: 1 1;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.ticket-pagination .ui.mini.pagination.menu {
    display: flex;
    flex: 1 1;
    padding: 8px 0;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row;
}

.ticket-pagination .ui.mini.pagination.menu a {
    border-color: transparent !important;
    margin: 4px;
}

.ticket-pagination .ui.mini.pagination.menu a:after, .ticket-pagination .ui.mini.pagination.menu a:before {
    background-color: transparent !important;
    border-color: transparent !important;
}

.search-block {
    margin: 20px 10px;
}

/*** reload *****/
.reload-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 16% 12% 10% 10% 10% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

.reload-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 30% 30% 20% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

/*** credit list ****/
.credits-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 25% 24% 24% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    margin-bottom: 14px;
}

.credits-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
}

/***Advance list*****/
.advance-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 20% 19% 19% 15%;
    margin-bottom: 15px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}

.advance-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 24% 28% 13% 14% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}


.link-to-reservation-dropdown {
    width: 20em !important;
}

/***Invoice list ****/
.invoices-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 25% 25% 18% 10%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}

.invoices-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
}

/**credit account*/
.credit_account-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 20% 13% 13% 12% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

/* 
@media screen and (max-width: 1000px) {
    .ticket-list-item {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-reservation {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-details-reservation {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-details {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-buttons button {
        width: 50px;
        font-size: 10px !important;
    }
}


@media screen and (max-width: 800px) {
    .ticket-list-item {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-list-item-reservation {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-list-item-details-reservation {
        font-size: 10px;
        line-height: 10px;
    }

    .ticket-list-item-details {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-buttons button {
        width: 40px;
        font-size: 8px !important;
    }
} */

.caisse-input {
    height: 35px !important;
}

.ticket-list-item-note {
    width: 96%;
    border: 0.5px solid #cccccc88 !important;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 18px 21% 18% 10% 10% 10% 7% 7% 7% 8%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}
.ticket-list-item-note-details {
    width: 90%;
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 40% 20% 20% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-main {
    margin-bottom: 14px;
    position: relative;
}

.ticket-list-notes {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 1;
    flex-direction: column;
}


span.ticket-icon-note {
    position: absolute;
    right: 10px;
}
.right-side {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  margin: 1.6rem;
  flex: 1 1;
  /*width: 100%;*/
  min-height: 100vh;
  /*padding-bottom: 200px;*/

}

.page-padding {
  flex: 1 1;
  display: flex;
  /*align-items: flex-start;*/
  flex-direction: column;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  /*line-height: 29px;*/
  color: #2c2c2c;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.dark .navbar-complex-container {
    background-color: #323232;
    /* border: 1px solid #323232; */
}

.text.navbar-lang {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 900;
    /*height: 20px;*/
    padding-bottom: 8px;
}

.navbar-lang span {
    padding: 4px;
}

.header-date {
    flex: 2 1;
    font-family: Montserrat, serif;
    font-weight: 300;
    font-size: 1.6rem;
}

.navbar-lang span:hover {
    color: #7a97f4;
}

.navbar-lang-active {
    color: #7e72f2;
}

.navbar-avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.dark .navbar-complex-container {
    background: #121212;
}

.custom-navbar-item:hover {
    background-color: white !important;
}

.ui.dropdown .menu > .item.custom-navbar-item {
    padding: 0 !important;
}

.navbar-left,
.navbar-right {
    display: flex;
    flex: 2 1;
    align-items: center;
}

img.ui.circular.image.navbar-avatar {
    min-width: 34px;
}

.navbar-right {
    flex: 1 1;
    justify-content: flex-end;
    margin-right: 12px;
}

.navbar-icon {
    position: relative;
    margin-right: 12px !important;
    margin-left: 12px !important;
}
.badge-nb{
    position: absolute;
    top: -1px;
    right: 1px;
    background-color: #db2828;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.name {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 0 12px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* identical to box height, or 121% */

    color: #2c2c2c;
}

.header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 17px;
    /* or 71% */

    color: #2c2c2c;
}

.navbar-icon {
    font-weight: 400;
    font-size: 1.3em !important;
}

i {
    cursor: pointer;
}

.switch-mode {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-ar .switch-mode {
    flex-direction: row-reverse;
}

.navbar-container {
    width: 100%;
    position: relative;
}

.profile-dropdown .left.visible.menu.transition {
    margin-top: 4px;
}

.navbar-ellipsis {
    position: relative;
}

.navbar-complex-container {
    z-index: 80;
    position: absolute;
    top: 45px;
    left: -330px;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.container-ar .navbar-complex-container {
    z-index: 80;
    position: absolute;
    top: 45px;
    right: -330px;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.navbar-complex-header {
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e5e588;
}

.navbar-complex-item {
    display: flex;
    align-items: center;
    padding: 14px 8px;
    border-bottom: 1px solid #e5e5e588;
    cursor: pointer;
}
.container-ar .navbar-complex-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 14px 8px;
    border-bottom: 1px solid #e5e5e588;
    cursor: pointer;
}

.navbar-complex-item:hover {
    background-color: #e5e5e577;
}

.navbar-complex-item:last-child {
    border-bottom: none;
}

.navbar-complex-item span {
    padding-left: 12px;
    font-family: Montserrat, serif;
    font-size: 16px;
    flex: 3 1;
    text-align: left;
}

.navbar-complex-selected {
    text-align: right !important;
    flex: 1 1;
}


.side-menu-item-link {
    margin: 0.8em;
}

.side-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80px !important;
    height: 80px !important;
    color: #ececec;
    -webkit-transition: all .2s;
    transition: all .2s;

    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.side-menu-item-link :hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.side-menu-item h3 {
    word-break: break-word;
}

.side-menu-item-link :hover h3 {
    display: block;
}

.side-menu-item h3 {
    margin: 2px 0 0;
    display: none;
    font-size: 10px;
    -webkit-transition: all .5s;
    transition: all .5s;
    padding: 4px;
    text-align: center;
}

.side-menu-item img {
    width: 30px;
    height: 30px;
}

.module-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}

.module-container a {
    display: block;
    margin: .8em;
}
.side-transition-container{
    background-color: white;
    top:0;
    z-index: 10000;
    /*height: 100%;*/
    /*width: 100%;*/
    overflow: auto;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    box-shadow:0 2px 8px rgba(0, 0, 0, 0.135216) ;
}


.side-transition-container div {
    /*width: 100%;*/
    /*height: 100%;*/
}



/******** Transition animation *******/


.right-show-transition .side-transition-container{
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}
.right-hide-transition .side-transition-container{
    -webkit-transform: translate3d(100% , 0px, 0px);
            transform: translate3d(100% , 0px, 0px);
}

.left-show-transition .side-transition-container{
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}
.left-hide-transition .side-transition-container{
    -webkit-transform: translate3d(-100% , 0px, 0px);
            transform: translate3d(-100% , 0px, 0px);
}


.top-show-transition .side-transition-container{
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}
.top-hide-transition .side-transition-container{
    -webkit-transform: translate3d(0px , -100%, 0px);
            transform: translate3d(0px , -100%, 0px);
}


.bottom-show-transition .side-transition-container{
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}
.bottom-hide-transition .side-transition-container{
    -webkit-transform: translate3d(0px , 100%, 0px);
            transform: translate3d(0px , 100%, 0px);
}

.side-transition-main{
    position: fixed;
    top:0;
    box-shadow:0px 2px 8px rgba(0, 0, 0, 0.135216) ;
    min-width: 100% !important;
    min-height: 100% !important;
}
.side-transition-overlay{
    overflow: hidden !important;
    position:fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    right: 0;
    bottom: 0;
    background-color: #32323288;
}

.side-transition{
    -webkit-transition: all 5s;
    transition: all 5s;
    opacity:0;

}


.no-overflow {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}


.notification-container {
    z-index: 80;
    position: absolute;
    top: 64px;
    right: 15%;
    display: flex;
    flex-direction: column;
    width: 420px;
    max-height: 400px;
    overflow-y: hidden;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}


.container-ar .notification-container {
    z-index: 80;
    position: absolute;
    top: 64px;
    left: 15%;
    display: flex;
    flex-direction: column;
    width: 420px;
    max-height: 400px;
    overflow-y: hidden;
    background-color: mediumvioletred;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.notification-title {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e5e588;
}

.notification-content {
    overflow-y: auto;
    padding: 0.1rem;
    min-height: 24px;
}

.feed-item {
    position: relative;
    border-bottom: 1px solid #33323212;
    cursor: pointer;
    margin: 0 !important;
}

.feed-item:hover {
    background-color: #77777722 !important;
}

.feed-text {
    margin-top: 0 !important;
    margin-bottom: 0.4rem !important;
}

.ball {
    position: absolute;
    background-color: mediumseagreen;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    right: 12px;
    bottom: 1em;
}

.ui.feed > .event > .label {
    margin-top: 0.5rem;
    /*align-self: center !important;*/
}

.notification-loading {
    position: relative;
    height: 34px;
    /*margin-top: 20px;*/
}


.notification-loading .notification-loader {
    left: 50% !important;
    border-color: #767676 transparent transparent;
    box-shadow: 0 0 0 1px transparent;
}

.notification-empty {
    font-family: Montserrat, serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #77777744;
    padding: 1rem;
    text-align: center;
    font-style: italic;
}

.notification-content .summary {
    text-transform: lowercase;
}

.notification-content .summary a {
    text-transform: capitalize;
}
.container {
    display: flex;
    overflow-x: hidden;
    min-height: 100%;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
}

div#root {
    height: 100%;
}

.configuration-planning-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 600px;
  width: 80%;
  margin-inline: auto;
}

.configuration-planning-btns {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.history-item-status {
  width: 55px !important;
  height: 30px !important;
  background: #babbbc;
  border: 1px solid #eee;
  margin-right: 18px;
  border-radius: 4px;
}

.historique-container .td-text{
    font-size: 1rem !important;
}

.regulation-input img.filter-img {
    -webkit-filter: invert(100%) !important;
    filter: invert(100%) !important;
}
.input-date-small{
    height: 35.8px !important;
}
.text-area-height{
    height:68px !important;
}

.input-modal .ui.input input[type="phone"] {
    width: calc(101% - 67px ) !important;
}

.participant-warning {
    position:absolute;
    right: 0;
    top:1rem;
}

@media only screen and (min-width:1920px){
    .ui.large.modal.reservation-modal{
        width:1450px;
    }
}
.reccurrence-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.recc-input {
    margin: 4px;
}

.error.field.recc-input, .error.field.recc-input label,
.error.field.recc-input label::before {
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
}

.venu,
.round-btn.venu {
  background-color: #72f286 !important;
}

.pas-venu,
.round-btn.pas-venu {
  background-color: #4f4f4f !important;
  color: white !important;
}

.supprimer:hover {
  background-color: #fef9ff !important;
  color: #292929 !important;
}

.venu:hover,
.round-btn.venu:hover {
  background-color: #67f2a2 !important;
}

.pas-venu:hover,
.round-btn.pas-venu:hover {
  background-color: #494a4f !important;
}
/***Planning Page***/
.planning-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 34px;
}

.container-ar .tab-custom.modal-tab .ui.text.menu {
  flex-direction: row-reverse !important;
}

.container-ar .planning-container {
  flex-direction: row-reverse;
}

.competition-icon {
  background-color: mediumseagreen !important;
  width: calc(1.4rem - 2px) !important;
  height: calc(1.4rem - 2px) !important;
}

.vertical-padding {
  padding-top: 0 !important;
}

.header-search .custom-dropdown-main {
  min-height: 40px !important;
}

div#sticky-header th.fc-axis.fc-widget-header {
  width: 41px !important;
  background-color: transparent !important;
}

.recurrence-icon {
  position: absolute;
  bottom: 0.8rem;
  left: 0.2rem;
}

.input-modal .timeInput-container.custom-input.input-bg {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem !important;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

label.label-modal.no-padding.participant-margin {
  margin-left: 20px;
}

.planning-list {
  display: flex;
  width: 100%;
}

.tab-th {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  -webkit-transform: translateY(35px);
          transform: translateY(35px);
  background-color: #ececec;
  cursor: pointer;
  height: 35px;
}

.tab-th.active {
  background-color: #9e94ff !important;
  color: #fff !important;
}

.planning-left {
  max-width: 230px;
  width: 230px;
  display: flex;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  flex-direction: column;
}

.fc th,
.fc td {
  white-space: nowrap;
  text-overflow: ellipsis;
}

th.fc-resource-cell.header-res {
  overflow: hidden;
}

.modal-container-column .timeInput-container.custom-input.input-bg.false {
  height: 40.7px !important;
  display: flex;
  align-items: center;
}

.planning-right {
  position: relative;
}

.event-overflow {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.input-modal .date-dropdown {
  min-width: 20px;
  width: 100% !important;
}

.reservation-modal .custom-dropdown-main {
  min-height: 36px !important;
}

.reservation-modal .custom-dropdown-container {
  width: 105% !important;
}

.planning-right-header {
  position: absolute;
  left: 50%;
  top: 40px;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-ar .planning-right-header {
  flex-direction: row-reverse;
}

.planning-right-header h2 {
  display: block;
  border: none !important;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem !important;
  text-align: center;
  vertical-align: center;
  color: #444c63;
  margin: 4px;
}

.dark .planning-header,
.dark .fc-toolbar {
  background-color: #333 !important;
}

.dark .planning-right-header h2 {
  color: white;
}

.dark .react-calendar {
  background-color: #2c2c2c !important;
  color: white;
  padding: 4px;
}

.planning-header-toggle {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #444c63;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
}

.container-ar .planning-header-toggle {
  flex-direction: row-reverse;
}

.planning-header {
  margin: 0 !important;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef1f7 !important;
  height: 90px;
}

.ajourdhui {
  background: #97b3ce !important;
  border-radius: 20px;
}

.ajourdhui:hover {
  background: #8da7ce !important;
  border-radius: 20px;
}

.error-msg-div {
  color: #9f3a38 !important;
  width: 30%;
  margin-left: auto;
  padding-bottom: 1rem;
}

.error-msg-div-opening {
  color: #9f3a38 !important;
  width: 30%;
  margin-left: 25%;
  padding-bottom: 1rem;
}

.planning-terrain {
  display: flex;
  flex-direction: column;
}

.planning-container-left {
  margin: 24px;
}

.display-none {
  display: none !important;
}

.planning-right-toggle {
  position: absolute;
  left: 10px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #444c63;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  top: 90px;
  z-index: 10;
}

.planning-terrain-header {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #444c63;
}

.planning-terrain-items {
  display: flex;
  flex-direction: column;
}

.container-ar .planning-terrain-items {
}

.planning-terrain-item {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8c97b2;
}

.container-ar .terrain-round {
  margin-right: 0;
  margin-left: 8px;
}

.terrain-title {
  flex: 1 1;
}

.terrain-round {
  display: inline-block;
  height: 12px !important;
  width: 12px !important;
  border-radius: 10px;
  margin-right: 8px;
  border: 1px solid #2c2c2c36;
}

.stats-round {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px;
  margin-right: 8px;
  padding-top: 2px;
}

.container-ar .stats-round {
  margin-right: 0;
  margin-left: 8px;
}

.planning_information_container span {
  display: block;
  text-align: center;
}

.planning-information-loader {
  margin-top: 23px;
}

/**Calendar**/
.react-calendar {
  font-family: Roboto, serif;
  width: 300px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #444c63;
  border: none !important;
  padding-bottom: 20px;
}

.react-calendar__month-view__weekdays {
  font-size: 12px !important;
  line-height: 14px;
  color: #8c97b2;
  opacity: 0.4;
  border-bottom: 2px solid #97b3ce;
}

.react-calendar button {
  height: 26px !important;
  /*margin: 3px 0 !important;*/
}

.react-calendar__tile--active {
  border-radius: 50%;
  background-color: #9e94ff !important;
}

button.ui.button.round-btn.ajourdhui.right-side-btn {
  position: absolute;
  left: 30px;
  top: 30px;
}

.react-calendar__tile--active:hover {
  background-color: #928ae6 !important;
}

.react-calendar__tile {
  padding: 0 !important;
  /*padding:18px 2px !important;*/
}

button.react-calendar__navigation__label {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #444c63;
}

/*** Full Calendar Layout     **/
.fc-event {
  border-radius: 3px;
  border: none !important;
  /*background: transparent !important;*/
}

.fc-axis {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border: none !important;
  color: #8c97b2;
  text-align: center;
  /*width: 65px !important;*/
}

.fc-row.fc-widget-header {
  margin-right: 0 !important;
}

.fc-scroller.fc-time-grid-container {
  overflow-y: hidden !important;
  height: auto !important;
}

.header-res {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #8c97b2;
  /*position: relative;*/
  border-color: #dadfea !important;
  padding: 10px !important;
}

.fc-time-grid .fc-slats td {
  /* height: 1.5em !important; */
}

.fc-center > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fc-toolbar.fc-header-toolbar {
  margin: 0 !important;
  padding: 24px;
  background-color: #eef1f7;
  height: 90px;
}

button.fc-next-button.fc-button.fc-button-primary,
button.fc-prev-button.fc-button.fc-button-primary,
button.fc-prev-button.btn.btn-primary,
button.fc-next-button.btn.btn-primary {
  padding: 4px 12px !important;
  border-radius: 100%;
  color: #8c97b2;
  margin: 8px;
  background-color: white;
  border: none;
}

button.fc-customNext-button.fc-button.fc-button-primary,
button.fc-customPrev-button.fc-button.fc-button-primary {
  padding: 1px 10px !important;
  border-radius: 100%;
  background-color: white;
  border-color: white !important;
}

button.fc-customHeader-button.fc-button.fc-button-primary {
  background-color: transparent !important;
  border: none !important;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em !important;
  line-height: 42px;
  /*display: flex;*/
  /*align-items: flex-end;*/
  text-align: right;
  color: #444c63;
}

.fc-button-primary:hover {
  color: #fff;
  background-color: #1e2b37;
  border-color: transparent !important;
}

.fc-icon {
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 14px;
  color: #8c97b2;
}

.actions.modal-history-footer {
  height: 64px !important;
}

.actions.reverse-modal-history-footer {
  height: 64px !important;
}

button.ui.secondary.button.btn-enregister {
  margin-left: 2.75em !important;
  margin-bottom: 0 !important;
  margin-top: 0.2em !important;
}

/********Event ****/
.event-container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Roboto, serif;
  height: 100%;
  font-style: normal;
  font-weight: 900;
  padding: 2px 2px;
  font-size: 10px;
  /* line-height: 20px; */
  color: #fff;
  overflow: hidden !important;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.event-container span {
  display: inline;
  padding-bottom: 0;
  font-size: 0.8em;
  font-weight: 400;
  line-snap: contain;
  white-space: pre-line;
}

.event-description {
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  height: 94%;
  width: calc(100% - 1.5rem);
  word-break: break-all;
  overflow-wrap: break-word;
  line-break: anywhere;
  overflow: hidden;
}

.event-description.venu-text {
  color: #000;
}

.event-container span {
  padding-bottom: 0;
}

.img-container {
  display: flex;
  flex-direction: column;
  width: 1.4rem;
  height: 94%;
  justify-content: space-between;
}

.event-container img {
  width: calc(1.4rem - 3px);
  height: calc(1.4rem - 3px);
  border-radius: 50%;
  padding: 2px;
  background-color: white;
}

.fc-nonbusiness.fc-bgevent {
  background: #ffafaf;
  opacity: 1;
}

.img-event {
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 24%!important;*/
  max-width: 25px;
  height: 24% !important;
  max-height: 25px !important;
  padding: 0.28em 0.05em;
  align-self: flex-end;
  border-radius: 50%;
}

.price-input {
  margin-left: 0 !important;
  padding-right: 0px !important;
}

.modal-tab .ui.bottom.attached.segment.active.tab {
  border: none;
}

.loading-deleted-reservation {
  position: relative;
  height: 52px;
  padding-top: 40px;
}

.reservation-modal-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-direction: row !important;
}

.reservation-modal-title span {
  max-width: 25%;
  color: #25273388;
  font-size: 1.2rem;
}

.reservation-modal-title h2 {
  flex: 1 1 !important;
  margin: 0 !important;
}

.history-modal-dropdown {
  width: 50% !important;
}

.tab-custom.modal-tab.modal-tab-player .item {
  border-bottom: none !important;
  /* background: #12121266; */
  /* color: white; */
}

.code-input {
  background: radial-gradient(
    ellipse at center,
    rgba(76, 76, 76, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(17, 17, 17, 1) 100%
  );
  background-size: cover;
  color: white !important;
  width: 100%;
  line-height: 1em;
  white-space: normal;
  font-size: 1em !important;
  outline: 0;
  -webkit-transform: rotateZ(0);
          transform: rotateZ(0);
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  letter-spacing: 1.8px;
  -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

.top.aligned.row.row-player.border-bottom.no-padding-right {
  padding-bottom: 0;
  padding-top: 0;
}

.search-part .custom-dropdown-container {
  width: 87% !important;
}

img.modal-team-logo {
  width: 84px !important;
  height: 84px !important;
  border-radius: 50%;
}

.-close.planning-search-btn-focused {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

img.ui.image.modal-team-logo {
  width: 45px;
  height: 45px;
}

.calender-loading {
  width: 100%;
  height: 40px;
  background-color: #33333308;
  z-index: 1000;
  position: absolute;
  top: 130px;
}

.animated-border {
  position: relative;
}

.animated-border::before {
  content: "";
  top: 0;
  left: 0;
  border: 10px dashed transparent;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  -webkit-animation-name: border-animation;
          animation-name: border-animation;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

@-webkit-keyframes border-animation {
  0% {
    border-color: transparent;
  }
  25% {
    border-color: white;
  }
  50% {
    border-color: transparent;
  }
  75% {
    border-color: white;
  }
  100% {
    border-color: transparent;
  }
}

@keyframes border-animation {
  0% {
    border-color: transparent;
  }
  25% {
    border-color: white;
  }
  50% {
    border-color: transparent;
  }
  75% {
    border-color: white;
  }
  100% {
    border-color: transparent;
  }
}

.center-row {
  justify-content: center !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

/****Label ******/
.label-container {
  min-width: 240px;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.label-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #eef1f7;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px 10px 0 0;
  color: #000;
}

.label-content {
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  background-color: #f9f9f9;
}

.container-ar .label-content {
  flex-direction: column-reverse !important;
}

.label-content span {
  margin-bottom: 4px;
  font-weight: 400;
}

.label-name {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 14px;
  color: #444c63;
}

.bold-label {
  font-weight: 600 !important;
}

.label-footer {
  display: flex;
  flex-direction: column;
  background: #eef1f7;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 0 0 10px 10px;
}

.label-footer div {
  padding: 4px;
}

.supprimer {
  background-color: white !important;
  color: black !important;
}

.venu,
.round-btn.venu {
  background-color: #72f286 !important;
}

.pas-venu,
.round-btn.pas-venu {
  background-color: #4f4f4f !important;
  color: white !important;
}

.supprimer:hover {
  background-color: #fef9ff !important;
  color: #292929 !important;
}

.venu:hover,
.round-btn.venu:hover {
  background-color: #67f2a2 !important;
}

.pas-venu:hover,
.round-btn.pas-venu:hover {
  background-color: #494a4f !important;
}

.planning-btn-right {
  position: absolute;
  right: 0;
  top: 40px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
}

.btn-right-plan {
  width: 40px;
  height: 40px;
  margin-right: 8px !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.btn-right-plan:hover {
  box-shadow: none !important;
  background: transparent !important;
  border: none !important;
}

.__react_component_tooltip {
  opacity: 1 !important;
  padding: 0 !important;
  border-radius: 10px;
}

.modal-history-container {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", serif;
}

.modal-history-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.modal-history-header > span {
  margin: 10px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #979797;
}

.search-input input {
  border-radius: 4px !important;
}

.reservation-modal .modal-history-footer,
.reservation-modal .reverse-modal-history-footer {
  padding: 0 !important;
  height: 75px;
  justify-content: center;
}

.modal-history-footer {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column !important;
  align-items: flex-start;
}

.reverse-modal-history-footer {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse !important;
  align-items: flex-start;
}

.actions.modal-history-footer .pagination {
  display: flex;
  flex-direction: row;
  box-shadow: none !important;
}

.modal-history-content {
  display: flex;
  flex-direction: column;
  min-height: 250px;
}

.modal-history-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.history-content {
  padding: 0 !important;
}

.history-item-status {
  width: 65px;
  height: 40px;
  /*background: forestgreen;*/
  border: 1px solid #eee;
  margin-right: 18px;
  border-radius: 4px;
}

.modal-history-item {
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
}

.history-item-right {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.history-item-right > div {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.history-item-right > button {
  justify-self: flex-end;
}

.history-item-right span {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 4px;
  color: #2c2c2c !important;
}

.centered-title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.95) !important;
}

.input-modal {
  padding: 12px 0 12px 12px;
}

.input-modal .form {
  margin-bottom: 0 !important;
}

.prev-btn,
.next-btn {
  position: absolute;
  height: 100%;
  color: white !important;
  margin: 0 !important;
  border-radius: 0 !important;
  min-width: 134px;
  background-color: #dadfea !important;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 17px;
}

.input-modal .form-input-margin {
  margin-bottom: 0px !important;
}

.modal-history-footer .prev-btn {
  left: 0;
  top: 0;
  bottom: 0;
  border-bottom-left-radius: 4px !important;
}

.modal-history-footer .next-btn {
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom-right-radius: 4px !important;
}

.reverse-modal-history-footer .prev-btn {
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom-left-radius: 4px !important;
}

.reverse-modal-history-footer .next-btn {
  left: 0;
  top: 0;
  bottom: 0;
  border-bottom-right-radius: 4px !important;
}

.reverse-modal-history-footer .next-btn i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.reverse-modal-history-footer .prev-btn i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.input-modal input {
  padding: 8px 4px !important;
  width: 100% !important;
}

.input-modal .field label {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4e4c4c !important;
}

.input-modal .ui.grid + .grid {
  margin-top: 0;
}

.input-modal .ui.input {
  flex: 1 1;
  width: 101% !important;
}

.input-modal .ui.input input {
  width: 101% !important;
  height: 38px !important;
}

.input-modal .ui.input input[type="tel"] {
  width: auto !important;
}

.form-input-modal-line .modal-input .field input {
  width: 90% !important;
}

.form-input-inline-modal .field label {
  display: none !important;
}

.tab-custom.modal-tab > .menu {
  margin: 0 !important;
  display: flex !important;
  flex: 1 1 !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.tab-custom.modal-tab .menu a {
  flex: 1 1 !important;
  display: flex;
  justify-content: center;
  margin: 2px !important;
  border-bottom: 2px solid #dadfea;
}

.modal-col .ui.selection.dropdown {
  width: 100% !important;
  min-width: 100% !important;
}

.modal-title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #979797 !important;
}

.react-calendar__tile--active abbr {
  padding-right: 1px !important;
}

.modal-input textarea {
  max-width: 100%;
  flex: 1 1;
  width: 100% !important;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.btn-description {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
}

.no-style,
.no-style:focus {
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 0.28571429rem !important;
  width: 101% !important;
}

.modal-btn-close {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.venir {
  background-color: #7e72f2 !important;
  color: white !important;
}

.form-input-inline-modal {
  display: flex;
}

.header-search {
  padding-left: 12px;
  padding-top: 12px;
}

.header-search .ui.search.search-input {
  flex: 1 1 !important;
  padding-right: 18px;
}

.adding-client {
  display: flex;
  width: 70%;
  flex: 0.4 1 !important;
  justify-content: center;
}

.adding-client button {
  border: none !important;
}

.header-search .ui.right.icon.input {
  width: 100%;
}

.input-modal
  .form-input-inline-modal
  .form-input-inline:first-of-type
  .field
  div
  > input {
  margin-left: -10px !important;
}

.form-input-modal-line {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
}

.form-input-modal-line .form-input-inline {
  margin-bottom: 0 !important;
}

.form-input-inline-modal {
  margin-bottom: 0 !important;
}

.box-info {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fdf9ff;
  padding: 8px 14px;
  border: 1px solid #dadfea;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.reservation-warning {
  position: absolute;
  right: 10%;
  top: 50%;
  color: black;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 2rem !important;
}

.reservation-modal .tab-custom {
  min-height: 480px;
  overflow-y: hidden !important;
}

.input-modal .ui.selection.dropdown {
  min-width: 12px !important;
  width: 104.8% !important;
}

.historique-container {
  padding: 12px;
}

.btn-enregister {
  align-self: center;
  justify-self: center;
  background: #7e72f2 !important;
  width: 160px;
  /*height: 32px;*/
  font-family: Montserrat, serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border-radius: 4px !important;
}

.info-title {
  padding-bottom: 11px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  justify-self: center;
  align-self: center;
  color: #979797;
}

.info-description {
  padding-bottom: 11px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.link-btn {
  margin-top: 8px !important;
  margin-left: 8px;
}

.form-input-inline-modal > span,
.label-modal {
  font-family: Montserrat, serif;
  font-style: normal;
  font-size: 14px;
  color: #4e4c4c !important;
}

.results .transition {
  height: 320px !important;
  overflow-y: auto !important;
}

.modal-container-column {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: wrap;
}

.modal-right,
.modal-left {
  display: flex;
  /*padding-bottom:120px;*/
  min-height: 100%;
  flex: 1 1;
  flex-direction: column;
  position: relative;
}

.btn-add-player {
  text-align: center;
}

.modal-col {
  display: flex;
  /*justify-content: space-between;*/
}

.padding-modal {
  margin: 0 !important;
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 2px !important;
  padding-right: 0 !important;
}

.no-padding {
  padding: 4px !important;
}

.modal-tab .ui.grid {
  width: 100%;
}

.modal .input {
  width: 100% !important;
}

.padding-modal {
}

span.info-description.btn-description button {
  margin-bottom: 2px;
}

.modal-tab .ui.text.menu {
  margin: 0 !important;
  flex: 0.1 1 !important;
  min-height: 50px;
}

.ui.middle.aligned.sixteen.column.grid {
  width: 100%;
}

.eight.wide.column.link-btn {
  margin: 0 !important;
  padding-top: 4px !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.modal-border-left {
  border-right: 1px solid rgba(34, 36, 38, 0.09);
}

.modal-border-right {
  border-left: 1px solid rgba(34, 36, 38, 0.09);
}

.modal-right {
  border-left: 0.5px solid rgba(34, 36, 38, 0.09);
}

.modal-input.bottom-modal {
  position: absolute;
  bottom: 18px;
  left: 18px;
  right: 18px;
}

/*Custom Context Menu */
.menu-context {
  width: 140px;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.02);
  position: absolute;
  overflow: hidden;
  display: none;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  z-index: 100;
  /*color:white ;*/
  background-color: white;
}

.menu-context .menu-options {
  list-style: none;
  padding-top: 8px;
}

.menu-context .menu-option {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  color: #2c2c2c;
}

.menu-context .menu-option i {
  color: #2c2c2c;
  opacity: 0.8;
}

.menu-context .menu-option:hover {
  background: rgba(0, 0, 0, 0.2);
}

li.menu-option.menu-disabled {
  color: darkgray;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: not-allowed;
}

span.menu-spinner {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
}

li.menu-option.menu-disabled:hover {
  background: transparent;
}

li.menu-option.menu-disabled i {
  color: darkgray;
}

/**/

.fc-bg {
  z-index: 1 !important;
}

@media screen and (max-width: 980px) {
  .modal-border-left,
  .modal-border-right {
    border-left: none;
    border-right: none;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(35, 35, 35, 0.09);
  }
}

@media only screen and (max-width: 1024px) {
  .caisse-main div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 8%) !important;
    width: calc(100% - (3.2rem + 0px + 8%)) !important;
    display: flex;
  }

  .caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 220px + 8%) !important;
    width: calc(100% - (3.2rem + 220px + 8%)) !important;
    display: flex;
  }
}

@media only screen and (max-width: 840px) {
  .planning-container {
    flex-direction: column;
  }

  .mobile-layout .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 60px) !important;
    width: calc(100% - (3.2rem + 0px + 60px)) !important;
    display: flex;
  }

  .caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 8%) !important;
    width: calc(100% - (3.2rem + 0px + 8%)) !important;
    display: flex;
  }

  .planning-left {
    max-width: 100%;
    width: 100% !important;
    display: flex;
    flex: 1 1 !important;
    flex-direction: row !important;
    /*display: none !important;*/
  }

  .planning-container-left {
    margin-top: 24px;
  }

  .planning-container-left > div {
    margin-left: auto;
    margin-right: auto;
  }

  .planning-right-header {
    position: absolute;
    left: 50%;
    top: 40px;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
            transform: translateX(-50%) translateY(-50%) !important;
    display: flex;
    width: 320px;
    justify-content: flex-start;
    align-items: center;
  }
}

.input-planning-name {
  display: flex;
}

.input-planning-name *:first-child {
  margin-right: 8px;
}

.input-planning-name *:last-child {
  margin-left: 8px;
}

.container-ar .input-planning-name *:first-child {
  margin-right: 0px;
  margin-left: 8px;
}

.container-ar .input-planning-name *:last-child {
  margin-right: 8px;
  margin-left: 0px;
}

.reccurence-planning {
  background: #ffffff;
  padding: 8px 14px;
  border: 1px solid #dadfea;
  margin-top: 14px;
  margin-bottom: 14px;
}

.inline.fields.choice_recurrence {
  display: flex;
}

.inline.fields.choice_recurrence .field {
  margin: 14px 4px;
}

.five.wide.column {
  padding-right: 0px !important;
}

.padded-top {
  padding-top: 18px !important;
}

.padding-bottom {
  padding-top: 12px !important;
  padding-bottom: 35px !important;
}

.planning-search-btn {
  width: 100%;
  height: 37px;
  background: #1c1c1ccc;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 5px;
  margin-left: 0 !important;
  border-radius: 2px;
}

.planning-search-btn:focus {
  border: 1px solid #7e72f2;
}

i.ellipsis.vertical.icon.padding-dots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7e72f2;
}

.padding-dots > i {
  display: flex;
  color: #7e72f2;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-size: 1em;
  margin-top: 8px !important;
}

.planning-search-btn-focused {
  background-color: #ffffff44;
  color: #000;
  height: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.capteur-drp {
  width: 100% !important;
  min-width: 100% !important;
}

.search-part {
  display: flex;
}

.search-part .planning-search-btn.planning-search-btn-focused {
  display: flex;
  flex: 1 1;
  background-color: #323232;
  border-radius: 0;
  height: 100%;
  min-height: 40px;
  max-width: 46px;
  color: white;
}

.row-user {
  display: flex;
  align-items: center;
  font-size: 1em !important;
}

.row-user img {
  width: 24px;
  height: 24px;
}

.search-part .search-input {
  min-width: 80%;
}

.capteur-drp .custom-dropdown-control-last i {
  font-size: 0.8em !important;
}

.search-part .custom-dropdown-main {
  max-height: 39px !important;
}

img.ui.image.modal-team-logo {
  width: 84px;
  height: 84px;
  border-radius: 84px;
}

.label-header img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

@media screen and (max-width: 1200px) {
  .padding-bottom {
    margin-left: 12px;
  }
}

.currency-status {
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-bottom: 1px;
  text-align: center;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 670px) {
  .planning-right-header {
    display: flex;
    width: 145px;
  }

  .planning-right-header h2 {
    font-size: 1.4rem !important;
  }
}

div#sticky-header .fc-row.fc-widget-header {
  position: fixed;
  background: #fff;
  top: 0;
  left: calc(1.6rem + 0px + 250px);
  width: calc(100% - (3.2rem + 0px + 250px));
  display: flex;
  z-index: 1000;
}

div#sticky-header .fc-row.fc-widget-header table {
  width: 100%;
  table-layout: fixed;
  background-color: #dadfea !important;
}

div#sticky-header .fc-row.fc-widget-header table td {
  width: 100%;
}

div#sticky-header {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.caisse-main div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.4rem + 0px + 6.5%);
  width: calc(100% - (3rem + 0px + 6.5%));
  display: flex;
}

.mobile-layout div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 0px + 60px);
  width: calc(100% - (3.2rem + 0px + 60px));
  display: flex;
}

.mobile-layout .planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 230px + 60px);
  width: calc(100% - (3.2rem + 230px + 60px));
  display: flex;
}

.planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 230px + 250px);
  width: calc(100% - (3.2rem + 230px + 250px));
  display: flex;
}

div#sticky-header .fc-row.fc-widget-header {
  height: 36px;
  margin-top: -4px;
}

div#sticky-header .fc-row.fc-widget-header .header-res {
  background-color: #fff;
}

div#sticky-header .fc-axis.fc-widget-header {
  background-color: #fff;
  margin-left: -4px;
  width: 38px;
}

.mobile-layout div#sticky-header .fc-row.fc-widget-header .header-res {
}

.caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.4rem + 230px + 6.5%);
  width: calc(100% - (3rem + 230px + 6.5%));
  display: flex;
}

@media only screen and (max-width: 480px) {
  .planning-btn-right {
    display: none;
  }
  .planning-right-header {
    margin-left: 2.8rem;
  }
}

.stats-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 475px);
}

.container-ar .stats-icon {
  flex-direction: row-reverse;
}

.stats-with-scroll {
  flex: 1 1;
  flex-wrap: nowrap !important;
  overflow-x: hidden;
}

.round-min {
  border-radius: 100% !important;
  background-color: transparent !important;
}

.ca-blocks {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.container-ar .ca-main {
  flex-direction: row-reverse;
}

.ca-main {
  display: flex;
  width: 200px !important;
  min-width: 200px !important;
  height: 100px;
  border-radius: 5px;
  background: linear-gradient(45deg, #7e72f2 0%, #aaa3ea 100%);
  margin: 5px 10px 5px 0px;
  padding: 0.9rem;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.stats-main-container {
  flex: 1 1;
  display: flex;
}

.container-ar .stats-main-container {
  flex-direction: row-reverse;
}

.ca-total {
  /* margin-right: 6rem !important; */
  width: 220px !important;
}

.ca-icon-container {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ca-block-container {
  flex: 2 1;
  height: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.container-ar .ca-block-container {
  /*flex-direction: column-reverse;*/
}

.ca-icon-b {
  align-self: center;
  background-color: #ffffff;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ca-icon-b img {
  width: 32px;
  height: 32px;
}

.ca-block-title {
  font-size: 1rem;
}

.ca-block-title-sub,
.ca-block-value-sub {
  font-size: 1rem;
  padding-left: 0.4rem;
  margin-bottom: 0 !important;
}

.container-ar .ca-block-title-sub,
.ca-block-value-sub {
  padding-left: 0rem;
  padding-right: 0.4rem;
}

.ca-activity-name {
  font-size: 1rem;
  padding-top: 0.4rem;
}

.ca-block-value {
  font-size: 2.4rem;
  font-weight: bold;
}

.ca-block-row {
  padding: 0.1rem;
  display: flex;
  width: 100%;
  flex: 1 1;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.stats-container {
  width: 100%;
  padding-top: 0.8rem;
  background-color: #ececec10;
}

.stats-row {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0.4rem;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee88;
}

.stats-row h3 {
  margin: 0;
}

.stats-row-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.welcome-caisse {
    margin: 10% auto;
    text-align: center;
}

.welcome-caisse h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 70px;
    letter-spacing: 0.2px;
    color: #626262;
}

.welcome-caisse button {
    border-radius: 100px !important;
    font-size: 32px !important;
    line-height: 35px !important;
    margin-top: 4rem !important;
}

button.ui.button.round-btn.btn-blue, button.ui.button.round-btn.btn-light-green {
    border-radius: 4px !important;
    margin: 2rem;
}

button.ui.button.round-btn.btn-blue:hover, button.ui.button.round-btn.btn-light-green:hover {
    border-radius: 4px !important;
    opacity: 0.8 !important;
}

button.ui.button.round-btn.btn-blue p, button.ui.button.round-btn.btn-light-green p {
    font-size: 1.2rem;
    width: 60%;
    margin: auto;
    padding: 1rem;
}

.round-btn.btn-blue {
    background-color: #15A4FA !important;
}

.round-btn.btn-light-green {
    background-color: #72F286 !important;
}

.round-btn.btn-blue:disabled,.round-btn.btn-light-green:disabled{
    -webkit-filter: contrast(0.5);
            filter: contrast(0.5);
}


.round-btn.btn-blue:hover {
    background-color: #15A4FA !important;
}

.round-btn.btn-light-green:hover {
    background-color: #72F286 !important;
}

.caisse-main {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    width: 100vw;
    height: 100vh;
    background-color: #fffcfc;
    overflow: hidden;
}

.caisse-main-content {
    grid-column: 2/17;
    display: grid;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-menu-first {
    display: flex;
    flex-direction: column;
    grid-column: 1/2;
    background-color: #333232;
    flex: 2 1;
    height: 100vh;
}

.caisse-content {
    grid-column: 2/11;
    height: 100vh;
    display: grid;
    position: relative;
}

.content-main {
    /*height: 100vh;*/
    display: grid;
    grid-template-columns: 130px 130px auto;
}

.caisse-main-menu-second {
    grid-column: 1/2;
    background-color: #AFD7FA;
}

.caisse-main-menu-third {
    grid-column: 2/3;
    background-color: #fffcfc;
}

.caisse-main-menu-middle-activity {
    grid-column: 3/17;
    background-color: #fffcfc;
}

.caisse-main-menu-middle {
    grid-column: 2/17;
    background-color: #fffcfc;
}

.caisse-main-body {
    grid-column: 1/11;
    background-color: rgba(255, 255, 255, 0.97);;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-body.with-margin {
    height: calc(100% - 66px);
}

.stat-nav-bar {
    position: absolute;
    bottom: 0;
    background-color: #333232;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin: 0px 1px;
    padding: 10px;
    width: 100%;
}

.stat-bloc {
    width: 130px;
    background: #7EB5E5;
    border-radius: 5px;
    padding: 3px;
}

.stat-client {
    font-weight: 200;
    font-size: 14px;
    text-align: center;
}

.stat-client .image {
    margin: 0 auto !important;
    width: 20px;
}

.stat-article {
    font-weight: 200;
    font-size: 14px;
}


.caisse-main-menu-right {
    grid-column: 11/17;
    background: #333232;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-menu-bottom {
    border-top: 1px solid #81818138;
}

.caisse-main-menu-top {
    flex: 1 1;
    height: 50%;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 1px;
}

.caisse-main *::-webkit-scrollbar {
    width: 1px;
    background-color: #e40f0f44;
}

/* Print Ticket*/
.print-ticket {
    width: 44mm !important;
    padding: 0 !important;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    flex-wrap: wrap;
}

.round-btn.btn-green {
    color: white !important;
    background-color: #21ba45 !important;
}

.round-btn.btn-green:hover {
    background-color: #21ba45 !important;
}

.round-btn.btn-red {
    color: white !important;
    background-color: #db2828 !important;
}

.round-btn.btn-red:hover {
    background-color: #db2c1f !important;
}


.sold {
    font-style: italic;
    font-weight: 200;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #7EB5E5;
}

.welcome-caisse a {
    text-align: center;
}

.homepage-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

@media screen and (max-width: 1024px) { 
    .content-main {
        grid-template-columns: 110px 110px auto;
    }
    
    .stat-nav-bar {
        font-size: 12px !important;
        line-height: 14px;
        margin: 0px 1px;
        padding: 6px 5px;
    }

    .stat-bloc {
        width: 110px;
        padding: 5px;
    }

    .stat-client {
        font-size: 10px;
        line-height: 18px;
    }

    .stat-client .image {
        width: 14px;
    }

    .stat-article {
        font-size: 10px;
        line-height: 18px;
    }
    /*********************Menus***************/
    .main-menu-item {
        font-size: 9px !important;
        line-height: 12px !important;
        padding: 5px !important;
        height: 50px;
    }
    
    .main-menu-item .image {
        width: 2.4em;
        height: 2.4em;
    }

    .main-menu-item-bottom{
        height: 44px !important;
    }

    .main-menu-item-bottom .image {
        width: 2.3em !important;
        height: 2.3em !important;
    }

    .child-menu-item {
        height: 49px;
    }

    .child-menu-item span {
        font-size: 14px;
        line-height: 16px;
    }

    .content-menu-item {
        width: 32.5%;
        margin: 1px !important;
        padding: 1px !important;
    }

    .content-menu-item span {
        font-size: 12px;
        line-height: 11px;
    }

    /*************Ticket****************************/
    .ticket-info {
        font-size: 9px;
        line-height: 12px;
    }

    .ticket-avatar {
        width: 35px !important;
        height: 35px !important;
    }

    .ticket-info .image {
        width: 25px;
    }

    .ticket-content-header {
        padding: 0% 1%;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .close-btn{
        font-size: .6878rem !important;
    }

    /************Ticket accordion**************/
    .accordion-label {
        font-size: 10px;
        line-height: 12px;
    }

    .qty label{
        font-size: 10px !important;
    }

    .badge-qte input {
        width: 18px;
        height: 18px;
        font-size: 10px !important;
    }

    .badge-qte-minus {
        margin: 0 2px !important;
        padding: 2px !important;
        font-size: .68571429rem !important;
    }

    .badge-qte-plus {
        margin: 0 2px !important;
        padding: 2px !important;
        font-size: .68571429rem !important;
    }
    .accordion-container div{
        display: flex;
    }

    .ticket-accord-item {
        width: 32%;
        font-size: 9px !important;
        line-height: 10px !important;
        height: 50px !important;
    }

    i.edit.icon.ticket-accord-item-edit {
        font-size: 12px !important;
    }

    .ticket-accord-item-remise {
        width: 20px;
        font-size: 8px !important;
    }
    .ticket-accord-item-remise {
        width: 20px;
        top: 56% !important;
        left: 1% !important;
        font-size: 8px !important;
    }

    .ticket-accord-item-remise .image {
        bottom: 11px !important;
        left: 4% !important;
    }

    .accordion-discount {
        width: 28px;
        font-size: 9px !important;
    }

    .accordion-discount img {
        width: 24px !important;
    }

    .ui.popup {
        font-size: 10px;
    }

    .participant-btn {
        padding: 16px !important;
        font-size: 14px !important;
        margin-left: 10% !important;
    }

    .participant-btn .plus {
        top: 3px;
        left: 6px;
    }

    .participant-btn .minus {
        bottom: 5px;
        right: 7px;
    }

    .participant-btn .separator {
        font-size: 45px !important;
        left: 16px;
        top: 4px;
    }

    /*****************************/
    .ticket-grey-block {
        font-size: 12px !important;
    }

    .ticket-grey-block-price {
        font-size: 12px !important;
    }

    .payment-action-list {
        font-size: 10px;
        line-height: 12px;
    }

    .payment-remainder-label {
        font-size: 10px;
        line-height: 12px;
    }

    .payment-remainder {
        font-size: 10px;
        line-height: 12px;
    }

    .ticket-menu-item {
        font-size: 10px !important;
        line-height: 10px !important;
        padding: 1px !important;
    }

    .ticket-menu-item-img {
        height: 20px !important;
    }

    .btn-grey-off {
        padding: 9px !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .btn-grey-off img {
        height: 18px !important;
    }

    .btn-grey-on {
        padding: 9px !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .btn-grey-on img {
        height: 18px !important;
    }

    /*************Modal********/
    .success-modal {
        width: 250px !important;
        padding: 10px !important;
    }

    .success-modal span {
        font-size: 46px !important;
        line-height: 70px !important;
    }

    .advance-modal{
        width: 365px !important;
        padding: 10px !important;
    }

    .advance-modal span {
        font-size: 40px !important;
        line-height: 70px !important;
    }

    /********ticket details ******/
    .ticket-menu div {
        display: flex;
    }

    .details-btn {
        width: 24% !important;
        height: 40px !important;
        font-size: 12px !important;
        line-height: 18px;
        margin: 14px 2px !important;
    }

    /*********Payment & discount*****************/
    .calculator-container {
        width: 69%;
        margin: 0px auto 4px;
        padding: 0 !important;
    }

    .calculator-btn {
        font-size: 10px;
        margin: 1px;
        padding: 3px;
    }

    .calculator-display {
        height: 30px;
        margin: 2px;
    }
    
    .calculator-input {
        font-size: 14px;
        height: 10px !important;
    }
    
    .payment-title {
        height: 6vh;
        font-size: 24px;
        line-height: 30px;
    }

    .payment-sub-title {
        font-size: 12px;
        padding-left: 24px;
    }

    .payment-content-left {
        padding: 1% !important;
    }

    .payment-content-left-bottom {
        padding: 0 17% !important;
    }

    .solde-btn {
        font-size: 10px !important;
        margin-bottom: 18px !important;
    }

    .payment-content-total {
        font-size: 14px;
        line-height: 20px;
        padding-top: 10px !important;
        margin: 5% !important;
    }

    .discount-content-quantity {
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: rgba(11, 108, 255, 0.65);
        padding-top: 10px !important;
        margin: 8% 0px !important;
    }
    /**************/

    .payment-content-right {
        padding-left: 12% !important;
    }
    .payment-block-input-group{
        height: 62vh;
    }

    .payment-block-input {
        width: 165px !important;
        height: 38px;
        margin: 3px !important;
        font-size: 10px;
    }
    .margin-block-input{
        margin-top: 8px !important;
    }

    .orange-block {
        margin-top: 10px !important;
    }

    .payment-block-input-left input {
        width: 70px;
        font-size: 12px !important;
        height: 30px;
    }
    
    .payment-block-input-right {
        width: 55% !important;
        padding: 5px 0px;
    }

    .payment-block-input-right .image {
        padding-bottom: 3px;
        height: 23px !important;
    }

    .payment-btn-active {
        font-size: 14px !important;
        margin: 1% !important;
    }

    .payment-btn-desactive {
        font-size: 14px !important;
        margin: 1% !important;
    }

    /************ Ticket list ************/
    .ticket-list-header {
        font-size: 24px;
        line-height: 30px;
    }

    .ticket-list-item {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-reservation {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-details-reservation {
        font-size: 10px;
        line-height: 12px;
    }
    
    .ticket-list-item-details {
        font-size: 10px;
        line-height: 12px;
    }

    .ticket-information{
        font-size: 10px !important;
        line-height: 12px;
    }

    .ticket-information span{
        font-size: 10px !important;
        line-height: 12px;
    }
    
    .ticket-sub-family {
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .ticket-sub-family .image{
        margin: 7% !important;
    }
    
    .ticket-activity span {
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .ticket-food span{
        font-size: 10px !important;
        line-height: 12px !important;
    }

    .center-img img{
        height: 22px !important;
    }

    .ticket-buttons button {
        font-size: 9px !important;
        line-height: 12px !important;
        width: 56px !important;
        height: 24px !important;
    }
    
    .search-block {
        margin: 20px 10px;
    }

    .ticket-amount{
        font-size: 10px !important;
        
    }

    .ticket-price .image {
        height: 22px !important;
    }

    .ticket-price {
        font-size: 10px !important;
        line-height: 12px !important;
    }
    
    .credits-list-item {
        font-size: 12px;
    }

    .advance-list-item {
      font-size: 12px;
    }
    .advance-list-item-details {
        font-size: 12px;
    }
    .credit_account-list-item {
        font-size: 12px;
    }

    /*******client list******************/
    .client-list-header {
        font-size: 24px;
        line-height: 30px;
    }

    .client-list-body p {
        font-size: 12px;
        line-height: 16px;
    }

    .search-bloc input {
        font-size: 12px !important;
    }

    .add-client {
        font-size: 10px !important;
    }

    .add-client .button {
        margin-right: 1%;
        font-size: .65714286rem !important;
    }

    .search-content {
        margin: 1% auto !important;
        width: 90%;
    }

    .client-row {
        margin: 0px auto;
        font-size: 12px;
        line-height: 14px;
        padding: 0px 25px;
    }

    .client-row span {
        margin: 0px 1%;
        padding: 0px;
    }

    .client-name {
        font-size: 12px;
        line-height: 14px;
    }

    .client-btn-detail {
        font-size: 10px !important;
    }

    .add-client-block {
        margin: 0px auto;
        width: 85%;
    }

    .client-green-btn {
        font-size: 16px !important;
    }

    /**************forms***************/
    .form-label {
        font-size: 12px !important;
    }

    .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
        font-size: 12px;
    }

    .custom-dropdown-container {
        font-size: 12px !important;
        min-width: 5em !important;
    }
    /*******Modal************/
    .player-informations {
        min-height: 320px;
    }

    .add-player {
        font-size: 12px !important;
    }

    .amount-bloc .button {
        font-size: 12px !important;
    }

    .amount-bloc {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-left: 8%;
        padding: 14px 0px;
    }
    
    span.amount-bloc.amount-bloc-reload  button.ui.button.btn-inline-icon {
        margin-left: 18%;
        padding: 0px 10px;
        width: 90px;
    }


}


@media screen and (max-width: 820px) {

}
.modal-reservation-border{
    border-left: 1px solid #DFE0EB;
    height:100%;
}

.modal-caisse-reservation{
    width: 80% !important;
    padding:12px !important;
    min-height: 100%;
}
.modal-caisse-reservation .input-bg,.modal-caisse-reservation .custom-dropdown-container {
    width: 104% !important;
}

.modal-caisse-reservation .ui.input{
    width: 101% !important;
}
.modal-caisse-reservation .ui.disabled.input{
    width: 101% !important;
}


.modal-caisse-reservation .timeInput-container.custom-input.input-bg.false {
    width: 102% !important;
}

.modal-caisse-left{
    padding-right:30px !important;
}
.modal-caisse-reservation .eight.wide.column.link-btn {
    margin: 8px !important;
    padding-top: 4px !important;
}

.modal-caisse-reservation .full-width-input{
    width: 100% !important;
}

.modal-caisse-reservation .ui.middle.aligned.sixteen.column.grid {
    width: auto;
}

@media screen and (max-width: 1000px ){
    .modal-reservation-border{
        border-left: none;
        border-top: 1px solid #DFE0EB;
    }
}
.my-header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height, or 121% */
    color: #2c2c2c;
}

.divider-div {
    padding-bottom: 0.21428571rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    margin-bottom: 45px;
}

.dark .divider-div {
    padding-bottom: 0.21428571rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 45px;
}

.header-main {
    width: 100%;
    display: flex;
    margin-top: 56px;
    height: 62px;
    flex-direction: column;
}

.header-row {
    display: flex;
    padding-bottom: 12px;
    justify-content: space-between;
    flex-direction: row;
}

.custom-text-input-container div.input-bg {
    background-color: #eef1f7;
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none;
}

.custom-p{
    min-height:19px;
}

.custom-text-input-container{
    position: relative;
}

.custom-text-input-dropdown{
    position: absolute;
    top: 16%;
    right: 20px;
}
.selected-complex {
    /* padding: 4px; */
    border-radius: 40px;
    border: 1px solid #d4d4d5;
    height: 35px !important;
    padding: 5px;
    width: 35px !important;
    /* background: #ffffff!important; */
}

.no-btn-style {
    background-color: transparent !important;
    align-self: flex-start;
}

.equipment-bg {
    position: relative;
    background-color: #C4C4C411;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.equipment-bg .close-btn {
    position: absolute;
    right: -60px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.div-form-container.colors-tab .div-form {
    flex: 1 1;
}

.custom-accordion .main-accordion {
    display: flex;
    font-family: Montserrat,serif ;
    justify-content: space-between;
    background-color: #4f4f4f25;
    color: #767676 !important;
    border-radius: 2px;
    font-size: 18px !important;
    cursor: pointer;
    /*font-weight: 400;*/
    text-transform: capitalize;
    padding: 14px 10px;
    margin-bottom: 10px;
}

.accordion-item-all{
    font-family: Montserrat,serif ;
    background-color: #1b1c29;
    color: white;
}
.accordion-item.accordion-item-all:before{
    content:""
}

label.accordion-item.single-accordion {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.custom-accordion .accordion-container {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    background-color: #4f4f4f10;
}


.accordion-item {
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    font-size: 16px !important;
}

.accordion-slider {
    -webkit-transition: height 0.4s;
    transition: height 0.4s;
}

.accordion-hide {
    height: 0 !important;
    overflow: hidden;
}

.accordion-show {
    height: auto;
}

.accordion-item label {
    flex:1 1;
}

.accordion-container .accordion-item::before {
    content: '';
    display: inline-block;
    width: 8px;
    margin-right: 4px;
    height: 8px;
    background-color: #2c2c2c44;
    border-radius: 8px;
}

.accordion-container .accordion-item .checkbox {
    display: flex;
    justify-content: flex-end;
}

.dark .main-accordion,
.dark .accordion-container {
    color: white;
    background-color: #212121;
}

.error-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 480px 480px;

}

.error-container p {
   margin-top: 220px !important;
  font-family: 'Tomorrow','Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 34px;
  font-weight: 600;
}

/*.error-logo:after {*/
/*    content: "";*/
/*    position: fixed;*/
/*    top: 228px;*/
/*    right: 416px;*/
/*    width: 84px;*/
/*    height: 84px;*/
/*    border-radius: 50%;*/
/*    background: #db2828;*/
/*    z-index: 1;*/
/*    box-shadow: 0 0 8px 8px #33333311;*/
/*}*/

/*.error-logo:before {*/
/*    content:"";*/
/*    position: fixed;*/
/*    top: 257px;*/
/*    right: 449px;*/
/*    width: 180px;*/
/*    height: 56px;*/
/*    border-radius: 24px;*/
/*    background: #db2828;*/
/*    z-index: -1;*/
/*}*/
/*span.error-close {*/
/*    position: fixed;*/
/*    top: 257px;*/
/*    right: 449px;*/
/*    color: white;*/
/*    font-size: 21px;*/
/*    font-weight: 600;*/
/*    z-index: 2;*/
/*}*/

/*.error-container:after{*/
/*    content:"-";*/
/*    font-size: 48px;*/
/*    font-weight: 600;*/
/*    color: #DB2828;*/
/*    width: 42px;*/
/*    height:42px;*/
/*    border-radius: 50%;*/
/*    text-align: center;*/
/*    vertical-align: middle;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: fixed;*/
/*    background-color: white;*/
/*    top:0;*/
/*    right:0;*/
/*}*/

.load-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.container.ar .load-div {
    flex-direction: column-reverse;
}

.load-div-dark {
    background-color: #121212;
}

.load-div img {
    width: 125px;
    height: 90px;
}

.load-div h4 {
    color: #7e72f2;
    font-family: Montserrat, serif;
    font-style: normal;
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
}

.animated-bar-container {
    width: 250px;
    height: 2px;
    background-color: #8c97b255;
}

.animated-bar {
    background-color: #7a97f4;
    height: 2px;
    width: 50px;
}
.animated-bar{
    -webkit-animation: translation 2s infinite ;
            animation: translation 2s infinite ;
}

.container-ar .animated-bar{
    -webkit-animation: translationResverse 2s infinite ;
            animation: translationResverse 2s infinite ;
}

@-webkit-keyframes translation {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(400%);
                transform: translateX(400%);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes translation {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(400%);
                transform: translateX(400%);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes translationResverse {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-400%);
                transform: translateX(-400%);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes translationResverse {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-400%);
                transform: translateX(-400%);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.fixed-side {
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    -webkit-transition: background 200ms, width 200ms;
    transition: background 200ms, width 200ms;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 22px 20px 20px;
    min-height: 100vh;
    -ms-overflow-style: none;
}


.dashboard-welcome {
    margin-bottom: 48px;
    text-align: center;
}

.side-mobile .dashboard-welcome {
    display: none;
}

.welcome {
    width: 200px;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
}

.dashboard-menu-container {
    width: 100%;
    align-items: center;
}

.dashboard-menu {
    background: rgba(126, 114, 242, 0.578726) !important;
}

.dashboard-menu-item {
    margin-bottom: 1.2em;
    align-self: center !important;
    justify-self: center !important;
}

.menu-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #ececec;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu-dashboard img {
    width: 80px;
    margin-bottom: 4px;
    height: 80px;
}

.menu-dashboard h3 {
    font-size: 16px;
    text-align: center;
    padding: 4px;
    margin: 0;
}

.side-mobile .menu-dashboard img {
    width: 40px;
    height: 40px;
}

.side-mobile .menu-dashboard h3 {
    display: none;
}

.side-mobile .menu-dashboard {
    width: 48px;
    height: 48px;
    border-radius: 4px;
}

.logo > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo > a > h4 {
    margin-left: 8px;
}

.overflow-visible {
    -ms-overflow-style: none;
    flex-direction: column;
}

.logo {
    display: flex;
    margin-bottom: 75px;
    justify-content: center;
}

.logo img {
    width: 140px;
    height: 68px;
}

.side-mobile .logo img {
    width: 48px;
    height: 48px;
}

.logo h4 {
    color: #7e72f2;
    font-family: Montserrat, serif;
    font-style: normal;
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.menu {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.menu span.item {
    margin-bottom: 10px;
}

.sous-menu .has-menu .item a {
    display: flex;
}

.sous-menu .has-menu .item a span {
    display: flex;
}

i.black.circle.mini.icon.nav-default-icon {
    align-self: center;
}

.menu span.item a {
    display: block;
    padding-left: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #2c2c2c;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 34px;
    cursor: pointer;
}

span.item a.active i {
    color: white !important;
}

.menu span.item a.active,
.menu span.item a.active {
    background: linear-gradient(188.54deg, #9e94ff 70.01%, #7e72f2 169.21%);
    box-shadow: -1px 1px 8px rgba(126, 114, 242, 0.578726);
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 34px;
    color: #fff;
}

.menu span.item a i {
    margin-right: 4px;
    margin-left: 8px;
}

.menu .item-end {
    /* margin-top: auto; */
}

.menu span.item:hover {
    background: #ececec;
    border-radius: 4px;
}

.fixed-side::-webkit-scrollbar {
    display: none;
}

.overflow-visible::-webkit-scrollbar {
    display: none;
}

.sous-menu {
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    z-index: 44 !important;
}

.has-menu {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    position: relative;
}

.display-none {
    display: none;
}

i.last {
    text-align: right !important;
}

.side-mobile h4 {
    display: none;
}

.overflow-visible {
    display: flex;
    width: 100%;
    flex: 1 1;
}

.side-mobile {
    overflow-x: visible !important;
}

.side-mobile .menu .sous-menu span.item a {
    display: flex !important;
    text-align: left;
    align-items: center;
    justify-content: center;
}

.side-mobile .menu .sous-menu span.item a i {
    margin-right: 12px;
    font-size: 8px;
}

.side-mobile .menu span.item a i {
    font-size: 18px;
}

.side-mobile .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;
}

.side-mobile .menu span.item a i {
    margin-right: 0;
    margin-left: 0;
}

.side-mobile span.item a {
    padding: 8px !important;
    width: 100%;
    text-align: center;
    border-radius: 0;
}

.side-mobile .menu span.item:hover {
    background: #ececec;
    border-radius: 0px;
}

.side-mobile span.item a:hover {
    border-radius: 0 !important;
    /* position: relative; */
}

.side-mobile span.item a.active {
    border-radius: 0;
    /* position: relative; */
}

.side-mobile .menu .item span {
    display: none;
}

.side-mobile {
    align-items: center;
    padding: 18px 0 0;
}

.side-mobile i {
    font-size: 20px;
}

.side-mobile .menu {
    width: 100%;
    display: flex;
    /*justify-content: center;*/
    flex: 1 1;
}

/***
* remove this if u want sous menu to show up when user click on the link
*/
.side-mobile .menu .has-menu:last-child:hover .sous-menu.display-none {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu .has-menu:not(:last-child):hover .sous-menu.display-none {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

/**/

.side-mobile .menu .has-menu:last-child:hover .sous-menu {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu .has-menu:not(:last-child):hover .sous-menu {
    display: block !important;
    position: absolute;
    left: 60px;
    z-index: 1000;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.170509);
    padding: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.side-mobile .menu span.item {
    margin-bottom: 0;
}

.side-mobile .menu .has-menu .sous-menu {
    display: none;
}

.sous-menu span.item a i {
    vertical-align: middle !important;
}

.side-mobile .menu .has-menu:hover .sous-menu span {
    display: flex;
    width: 250px;
}

.side-mobile .menu .has-menu:hover .sous-menu.display-none span {
    display: flex;
    width: 250px;
}

/* .side-mobile span.item:hover.sous-menu {
    position:absolute;
    left:100px;
    background-color:red;
} */

.dark .item a {
    color: white !important;
}

.dark .item a:hover {
    color: #333 !important;
}
.side-mobile .menu  .menu .has-menu .sous-menu .has-menu .sous-menu.display-none {
    display: none !important;
}

.side-mobile .menu .has-menu .sous-menu .has-menu{
    position:relative;
    display:flex;
    flex-direction:column;
}
.side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu.display-none {
    display:none !important;
}

.side-mobile .menu .has-menu .sous-menu .has-menu .item:hover, .side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu.display-none{
    position:static;

}

.side-mobile .menu .has-menu .sous-menu .has-menu .item:hover, .side-mobile .menu .has-menu .sous-menu .has-menu .sous-menu{
    position:static;
}

.side-mobile .menu .sous-menu span.item a i.black.angle {
    font-size: 12px;
}
:root {
    --PLANNING-LEISURE-HEIGHT : 72px;
    --PLANNING-LEISURE-HEIGHT-JS : 72;
    --PLANNING-LEISURE-PARTICIPANT : 40px;
    --PLANNING-LEISURE-PARTICIPANT-MARGIN-TOP : 34px;
}

.container-ar .ui * {
    direction: rtl;
    /*text-align: right;*/
}
.container.mobile-layout {
    overflow-x: auto !important;
}

.version-update {
    z-index: 1000;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #1e2b37;
    padding: 5px;
    justify-content: center;
    align-items: center;
}

.version-update span {
    color: white;
    padding-right: 10px;
    font-size: 1rem;
}

.dark.container {
    background-color: #121212;
}

.dark .table.ui.small.unstackable.compact.padded.left.aligned.table {
    background-color: #333;
    color: white !important;
}

.Toastify__toast--warning {
    background: #ffa900;
}

.dark td.td-text {
    color: white;
}

img {
    image-rendering: auto;
}

.td-img img {
    image-rendering: optimizeQuality;
}

.ui.search.dropdown > .text {
    cursor: text;
    position: relative;
    left: 1px;
    z-index: 3;
    padding-top: 2px;
}

.ui.loader:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
}

.ui.loader:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: loader 0.6s linear;
            animation: loader 0.6s linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent !important;
}

.btn-close {
    background-color: #db2828 !important;
}

.btn-close:hover {
    opacity: .9;
    background-color: #db2828 !important;
}

.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img,
.ui.dropdown > .text > .image,
.ui.dropdown > .text > img {
    width: 28px !important;
    height: 28px !important;
}

.dark th.tab-header.bg-tab {
    background: #121212 !important;
    color: rgba(255, 255, 255, 0.9) !important;
}

.dark h3.my-header {
    color: white;
}

button.ui.red.circular.icon.button.close-modal {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translate(50%, -40%);
            transform: translate(50%, -40%);
}

.dark button.ui.basic.button {
    color: white !important;
    border-color: white !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 1) inset;
    background-color: #333 !important;
}

.dark button.ui.basic.button:hover {
    color: #333 !important;
    border-color: white !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 1) inset;
    background-color: #fff !important;
}

.dark .ui.definition.table thead:not(.full-width) th:first-child {
    box-shadow: none !important;
}

.dark span.item {
    color: white !important;
}

.dark .sous-menu {
    background-color: #121212 !important;
}

.dark span.item i {
    color: white !important;
}

.dark i.black.icon {
    color: white !important;
}

.dark .fixed-side {
    background-color: #121212;
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.170509);
}

.dark span.item:hover i.black.icon {
    color: #121212 !important;
}

.dark span.item:hover {
    color: #121212 !important;
}

.input-large-bg input {
    background-color: #eef1f7 !important;
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none !important;
}

.dark .navbar {
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.135216) !important;
    background-color: #333;
    color: white;
}

.dark span.header {
    color: white !important;
}

.dark .name {
    color: white !important;
}


.responsive-filter {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.filter-input {
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    font-family: Montserrat, serif;
    font-size: 18px;
    color: #c4c4c4;
}

.search-input {
    width: 21% !important;
    height: 35px;
    margin: 4px !important;
    font-size: 16px !important;
}

.search-input::-webkit-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input::-moz-placeholder {
    color: #c4c4c4 !important;
}

.search-input:-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input::-ms-input-placeholder {
    color: #c4c4c4 !important;
}

.search-input::placeholder {
    color: #c4c4c4 !important;
}

/* .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
    opacity: .9 !important;
} */

@media screen and (max-width: 1000px) {
    .responsive-filter {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .filter-input {
        flex-direction: column;
        flex: 1 1;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .filter-input .search-input {
        width: 100% !important;
    }
}

.container-ar {
    direction: rtl;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Montserrat, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: auto !important;
    font-size: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.round-btn {
    border-radius: 20px !important;
    background-color: #9e94ff !important;
    color: white !important;
}

.round-btn:hover {
    background-color: rgb(134, 123, 237) !important;
}

.export-btn {
    border-radius: 20px !important;
    float: right;
    /* color: white !important;
    background: rgb(33, 200, 246) !important; */
}

.table-container {
    overflow-x: auto;
    margin-top: 18px;
}

.dark .table-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    outline: 1px solid slategrey;
}

.timeInput-container.custom-input.input-bg.false.search-input.search-date {
    background: #fff !important;
    border: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: .28571429rem !important;
}


.div-form {
    display: flex;
    margin: 16px 45px;
    min-width: 450px;
    width: 100%;
    flex-direction: column;
}

.btn-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.btn-right button {
    margin-left: 14px;
}

.div-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dark .responsive-filter {
    background-color: #323232 !important;
}

.tab-header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600 !important;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.2px;
    color: #666 !important;
    word-break: keep-all;
    word-spacing: normal;
    white-space: nowrap;
}

.bg-tab {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* padding-left: 8px !important; */
    background-color: #fff !important;
}

.no-border {
    /* border: none; */
}

.td-text {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.filtre {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.dark .filtre {
    background-color: #212121;
}

.dark .filtre > span {
    color: white;
}

.dark .filtre .secondary {
    background-color: white !important;
    color: black !important;
}

.dark .filtre .secondary:hover {
    background-color: #e8e8e8 !important;
    color: black !important;
}

.filtre > span {
    font-family: Montserrat, Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    margin-right: 8px;
    color: #000000;
}

.filtre-input {
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    font-family: Montserrat, serif;
    font-size: 18px;
    color: #c4c4c4;
}

.filtre-dropdown {
    min-height: auto !important;
    height: 45px;
}

.filtre-dropdown .text {
    top: -3px;
    font-family: Montserrat, serif;
    font-size: 16px;
}

.filtre-dropdown i.dropdown.icon {
    top: 10px !important;
}

.td-img {
    display: flex;
    align-items: center;
}

.td-img img {
    margin-right: 4px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
}

.ui.form {
    flex: 1 1;
}

.ui .button .round-btn {
    justify-self: flex-start;
    align-self: flex-start;
}

.container-ar .ui.grid {
    flex-direction: row-reverse !important;
}

.round-btn {
    border-radius: 20px !important;
    background-color: #9e94ff !important;
    color: white !important;
}

.venu {
    background-color: #72f286 !important;
}

.avatar-cover{
    object-fit: cover;
}

::-webkit-scrollbar {
    width: 8px;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

::-webkit-scrollbar-button {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    border-radius: 20px;
}

@media screen and (max-width: 800px) {
    html{
        font-size: 12px;
    }
    .filtre {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .filtre .dropdown {
        width: 100%;
        margin-bottom: 2px;
    }

    .filtre .input,
    .filtre input {
        width: 100%;
        margin-left: 0 !important;
    }
}
@media screen and (max-width:300px) {
    html{
        font-size: 10px;
    }
}

