
.ticket-list {
    font-family: Montserrat, serif !important;
    height: 100vh;
}

.container-ar .empty-row td.left.aligned {
    text-align: right;
}

.container-ar .empty-row td.right.aligned {
    text-align: left;
}

.ticket-list-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 20px;
}

.ticket-list-body {
    padding: 10px;
}

.ticket-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 31% 6% 8% 7% 7% 7% 7% 8%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-activity-container {
    display: flex;
}

.ticket-list-item-reservation {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 16% 6% 8% 7% 7% 7% 7% 8%;
    margin-bottom: 14px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item-details-reservation {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 27% 28% 12% 12% 15%;
    margin-bottom: 14px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 55% 12% 12% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
}

.ticket-list-item-pending div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
}

.ticket-family {
    height: 100% !important;
    margin: 0px !important;
    border-radius: 4px 0px 0px 4px;
    color: #ffffff !important;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    text-orientation: upright;
    padding: 3px !important;
    align-items: center;
    border-right: none !important;
}

.ticket-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-information span {
    font-size: 12px !important;
    color: #252733 !important;
    font-weight: 400 !important;
}

.list-ticket-loading {
    display: flex;
    width: 100%;
    flex: 1 1;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.ticket-sub-family {
    display: flex;
    align-items: center;
    text-align: left;
    color: #252733 !important;
    font-weight: 600 !important;
    font-size: 13px;
}

.ticket-sub-family .image {
    margin-left: 8% !important;
    margin-right: 5% !important;
}

.ticket-amount {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-amount span {
    color: #252733 !important;
    font-weight: 600 !important;
    margin-top: 15px !important;
}

.ticket-activity {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.ticket-activity span {
    color: #252733 !important;
    font-weight: 600 !important;
}

.ticket-article {
    color: rgba(173, 0, 255, 0.65) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-article-pending {
    border: none !important;
}

.ticket-article span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-price {
    color: rgba(11, 108, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticket-price .image {
    margin: 0 auto !important;
    height: 25px !important;
}

.ticket-price span {
    color: #252733 !important;
    font-weight: 600 !important;
    margin-top: 12px !important;
}

.ticket-food {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #252733;
    flex: 1;
}

.center-img {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ticket-buttons {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 8px 16px !important;
    margin: 0 !important;
    /* text-align: right !important; */
    border-right: none !important;
}

.ticket-buttons button {
    font-size: 12px !important;
    line-height: 14px !important;
    color: #ffffff !important;
    align-items: center !important;
    width: 72px !important;
    height: 30px;
    padding: 0px !important;
    margin-bottom: 3px !important;
}

.ticket-btn-detail {
    background: rgb(0, 184, 118) !important;
}

.ticket-btn-edit {
    background: rgba(11, 108, 255, 0.65) !important;
}

.ticket-btn-payment {
    background: #ff7070 !important;
}

/* css for pagination */
.ticket-list-body {
    min-height: calc(80vh - 60px);
}

.ticket-pagination {
    display: flex;
    flex: 1;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.ticket-pagination .ui.mini.pagination.menu {
    display: flex;
    flex: 1;
    padding: 8px 0;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row;
}

.ticket-pagination .ui.mini.pagination.menu a {
    border-color: transparent !important;
    margin: 4px;
}

.ticket-pagination .ui.mini.pagination.menu a:after, .ticket-pagination .ui.mini.pagination.menu a:before {
    background-color: transparent !important;
    border-color: transparent !important;
}

.search-block {
    margin: 20px 10px;
}

/*** reload *****/
.reload-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 16% 12% 10% 10% 10% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

.reload-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 30% 30% 20% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

/*** credit list ****/
.credits-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 25% 24% 24% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    margin-bottom: 14px;
}

.credits-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
}

/***Advance list*****/
.advance-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 20% 19% 19% 15%;
    margin-bottom: 15px;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}

.advance-list-item-details {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 24% 28% 13% 14% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}


.link-to-reservation-dropdown {
    width: 20em !important;
}

/***Invoice list ****/
.invoices-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 25% 25% 18% 10%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
}

.invoices-list-item div {
    margin: 5px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
}

/**credit account*/
.credit_account-list-item {
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 15% 15% 20% 13% 13% 12% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
    margin-bottom: 14px;
}

/* 
@media screen and (max-width: 1000px) {
    .ticket-list-item {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-reservation {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-details-reservation {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-list-item-details {
        font-size: 12px;
        line-height: 18px;
    }

    .ticket-buttons button {
        width: 50px;
        font-size: 10px !important;
    }
}


@media screen and (max-width: 800px) {
    .ticket-list-item {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-list-item-reservation {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-list-item-details-reservation {
        font-size: 10px;
        line-height: 10px;
    }

    .ticket-list-item-details {
        font-size: 10px;
        line-height: 18px;
    }

    .ticket-buttons button {
        width: 40px;
        font-size: 8px !important;
    }
} */

.caisse-input {
    height: 35px !important;
}

.ticket-list-item-note {
    width: 96%;
    border: 0.5px solid #cccccc88 !important;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 18px 21% 18% 10% 10% 10% 7% 7% 7% 8%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}
.ticket-list-item-note-details {
    width: 90%;
    border: 0.5px solid #ccc !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 18px 40% 20% 20% 15%;
    align-items: center;
    background-color: white;
    min-height: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    font-weight: 500;
}

.ticket-list-main {
    margin-bottom: 14px;
    position: relative;
}

.ticket-list-notes {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}


span.ticket-icon-note {
    position: absolute;
    right: 10px;
}