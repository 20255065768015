@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
/**********MainMenu********/
.main-menu {
    background: #333232;
    width: 100%;
}

.main-menu-item {
    font-family: Montserrat, serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    width: 100%;
    min-height: 70px;
    border-radius: 0px !important;
    padding: 8px !important;
    margin-bottom: 1% !important;
}

.ui.large.modal > .header {
    display: flex;
    justify-content: center;
}

button.ui.button.main-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-menu-item:hover {
    background-color: #AFD7FA !important;
    color: #000000 !important;
}

.main-menu-item .image {
    width: 3em;
    height: 3em;
    margin: 0px auto 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menu-item:hover .image {
    -webkit-filter: invert(0);
    filter: invert(100%);
}

.main-menu-item-bottom {
    height: 55px !important;
}

.main-menu-item-bottom .image {
    width: 2.3em !important;
    height: 2.3em !important;
}

.main-menu-item:active .image {
    -webkit-filter: invert(0);
    filter: invert(100%);
}

button.ui.button.payment-btn-active.payment-btn-green {
    color: white !important;
    background: #00B876 !important;
}

/**********ChildMenu********/
.child-menu {
    background-color: #AFD7FA !important;
    width: 100%;
}

.child-menu-item {
    width: 98%;
    height: 65px;
    box-sizing: border-box !important;
    border-radius: 0px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 140px auto !important;
    background-color: #AFD7FA !important;
    padding: 1% !important;
    margin: 1% !important;
}

.child-menu-item:hover {
    border: 3px solid #7EB5E5 !important;
}

.child-menu-item span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    /* line-height: 19px; */
    text-align: center;
    color: #FFFFFF !important;
    mix-blend-mode: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/***************ContentMenu*******************/
.content-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 86px;
}

.content-menu-item {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    width: 31%;
    min-width: 180px !important;
    min-height: 160px !important;
    height: auto;
    box-sizing: border-box !important;
    border-radius: 20px 20px 0px 0px !important;
    background-color: #F3F3F3 !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 350px auto !important;
    margin: 2px 5px !important;
    padding: 1px !important;
    border: 3px solid transparent !important;

}

.content-menu-item:hover {
    border: 3px solid #7EB5E5 !important;
}

img.content-menu-item-avatar {
    position: absolute;
    max-width: 64px;
    max-height: 80px;
    bottom: 1.6rem;
    left: 0.4rem;
}


.content-menu-item span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #333333;
}

.content-menu-item-price {
    align-self: flex-end;
    background-color: #AFD7FA !important;
    padding: 1.4rem 0 !important;
}

span.content-menu-item-price span {
    background: #69747E;
    color: white !important;
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius: 50%;
    right: 1.2rem;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-menu-item-name {
    align-self: flex-start;
    padding: 1rem 0;
    width: 100% !important;
}

.content-menu-item-price {
    align-self: flex-end;
    width: 100% !important;
    background: #7E88E5;
    padding: 1rem 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: right !important;
    color: #FFFFFF !important;
    padding-right: 0.4rem !important;
}

/*******Payment & discount*****************/
.payment-title {
    height: 5vh;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: rgba(11, 108, 255, 0.65);
    text-align: center;
    position: relative;
}

.cancel-note {
    position: absolute;
    top: 5px;
    right: 10px;
}

.payment-sub-title {
    position: absolute;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal !important;
    color: rgba(11, 108, 255, 0.65);
    text-align: center;
    padding-left: 20px;
}

.container-ar .payment-sub-title {
    padding-left: 0;
    padding-right: 20px;
}

.payment-content {
    /* background-color: #fffcfc; */
    overflow: hidden;
    padding: 2%;
    height: 76vh;
}

.payment-content-left {
    padding: 1% 3%;
}

.payment-content-left-bottom {
    padding: 0 20%;
}

.solde-btn {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif !important;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #00B876 !important;
    margin-bottom: 20px !important;
}

.payment-content-total {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    border-top: 1px solid rgba(11, 108, 255, 0.65);
    padding-top: 10px !important;
    margin: 8% !important;
}

.discount-content-quantity {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: rgba(11, 108, 255, 0.65);
    padding-top: 10px !important;
    margin: 8% 0px !important;
}

.payment-content-right {
    padding-left: 15% !important;
}

.payment-block-input-group {
    overflow: auto;
    height: 67vh;
}

.payment-block-input-disabled {
    /*opacity: 0.4;*/
}

.alert-note {
    color: darkred;
}

span.alert-note {
    position: absolute;
    bottom: calc(50% - 9px);
    right: 124px;
}

span.alert-note img {
    width: 18px;
    height: 18px;
}

.payment-block-input {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center !important;
    width: 210px !important;
    height: 45px;
    margin: 5px !important;
    padding: 1% 2% !important;
    border-radius: 5px !important;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF !important;
    background-color: #297EFF;
}

.margin-block-input {
    margin-top: 13px !important;
}

.return-article-block {
    background-color: #C7C7C7;
}

.orange-block {
    margin-top: 15px !important;
    background-color: #F2994A !important;
}

.payment-block-input-left {
    position: relative;
    width: 45% !important;
    padding: 2px;
    text-align: center;
}

.payment-block-remove {
    position: absolute;
    right: 3px;
    top: 13px;
    background-color: transparent !important;
    color: #db2828 !important;
    padding: 0 !important;
}

.payment-block-input-left input {
    background: #FFFFFF !important;
    border: none !important;
    font-size: 14px !important;
    width: 85px;
    height: 34px;
    color: rgba(11, 108, 255, 0.65) !important;
}

.payment-block-input-right {
    width: 55% !important;
    text-align: center;
    padding: 5px 0px;
}

.payment-block-input-right .image {
    padding-bottom: 5px;
    margin: 1px auto !important;
    height: 25px !important;
}

.center-btn {
    text-align: center;
}

.payment-btn-active {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 14px !important;
    color: #00B876 !important;
    margin: 2% !important;
}

.payment-btn-desactive {
    background: rgba(0, 184, 118, 0.1) !important;
    font-family: Montserrat, serif;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 14px !important;
    margin: 2% !important;
}

/* .payment-btn-active{
	background: rgba(0, 184, 118, 0.1);
	border-radius: 5px;
	color: #00B876;
} */

.loading-input-main {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}

.loading-input-main .ui.loader {
    top: 10%;
}

.discount-btn-group {
    display: flex;
    justify-content: center;
}

.payment-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.advance-modal {
    background-color: #72F286 !important;
    border-radius: 20px !important;
    justify-content: center !important;
    width: 400px !important;
    padding: 20px !important;
}

.payment-modal span {
    font-size: 80px !important;
    line-height: 80px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advance-modal span {
    font-size: 42px !important;
    line-height: 70px !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*******End payment*************/

i.close.icon.discount-close-btn {
    position: absolute;
    right: 56%;
    color: #333;
    transform: translateY(-37%);
}


/********Credit modal*******/
.modal-ok-btn {
    background: #CCFFED !important;
    color: #00B876 !important;
    font-style: normal !important;
    border-radius: 10px !important;
    font-weight: 900 !important;
    font-size: 15px !important;
    margin: 0px 10px !important;
    height: 45px;
}

.block-credit-modal {
    margin: 20px 90px;
    text-align: center;
}

.block-credit-modal h5 {
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #333232;
}

.block-credit-modal .ui.input > input {
    background: #DFE0EB;
    border-radius: 8px;
    height: 45px;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #333232;
}

.block-credit-modal h5, .block-credit-modal span {
    margin-bottom: 8px !important;
}

/******Note division*****/
.header-note-list{
    position : relative;
}
.delete-note-botton{
    margin-left: 4px;
    background-color: transparent !important;
    color: #db2828 !important;
    padding: 0 !important;
}

.edit-note-botton{
    margin-left: 4px;
    background-color: transparent !important;
    color: #1e578d  !important;
    padding: 0 !important;
}

.division {
    position: relative;
    margin-top: 10px;
}

.cancel_note {
    position: absolute;
    top: 0px;
    right: 5px;
}

.division-content {
    padding: 1.2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    max-height: 80vh;
    overflow: auto;
}

.division-list{
}

.orange-arrow {
    width: 50px;
    height: 50px;
    margin-left: 30px;
}

.division-home {
    margin-top: calc(28px);
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #333333;
}

.division-list-user {
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    padding-bottom: 25px;
}


.division-edit-user > button {
    margin-right: 1.4rem !important;
}

.note-item-label {
    font-size: 16px;
    line-height: 26px;
    color: rgba(11, 108, 255, 0.65);
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.qty-modal-note {
    text-align: center;
}

.choose-qty {
    color: #000000 !important;
    font-size: 12px;
}

.total-note {
    border-top: 1px solid #333333;
    font-style: italic !important;
    font-weight: bold !important;
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    color: #333333;
    padding-top: 15px;
    padding-bottom: 0.6rem;
    display: block;

}

.payed-note {
    font-style: italic !important;
    /*font-weight: bold !important;*/
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    color: #00B876;
    padding-top: 20px;

}

.badge-qte-main input {
    height: 30px !important;
}

.note-item-sub-label {
    color: #252733 !important;
    text-align: left !important;
}

.division-edit-user {
    display: flex;
    height: 100px;
    align-items: center;
}

.division-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
