
.welcome-caisse {
    margin: 10% auto;
    text-align: center;
}

.welcome-caisse h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 70px;
    letter-spacing: 0.2px;
    color: #626262;
}

.welcome-caisse button {
    border-radius: 100px !important;
    font-size: 32px !important;
    line-height: 35px !important;
    margin-top: 4rem !important;
}

button.ui.button.round-btn.btn-blue, button.ui.button.round-btn.btn-light-green {
    border-radius: 4px !important;
    margin: 2rem;
}

button.ui.button.round-btn.btn-blue:hover, button.ui.button.round-btn.btn-light-green:hover {
    border-radius: 4px !important;
    opacity: 0.8 !important;
}

button.ui.button.round-btn.btn-blue p, button.ui.button.round-btn.btn-light-green p {
    font-size: 1.2rem;
    width: 60%;
    margin: auto;
    padding: 1rem;
}

.round-btn.btn-blue {
    background-color: #15A4FA !important;
}

.round-btn.btn-light-green {
    background-color: #72F286 !important;
}

.round-btn.btn-blue:disabled,.round-btn.btn-light-green:disabled{
    filter: contrast(0.5);
}


.round-btn.btn-blue:hover {
    background-color: #15A4FA !important;
}

.round-btn.btn-light-green:hover {
    background-color: #72F286 !important;
}

.caisse-main {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    width: 100vw;
    height: 100vh;
    background-color: #fffcfc;
    overflow: hidden;
}

.caisse-main-content {
    grid-column: 2/17;
    display: grid;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-menu-first {
    display: flex;
    flex-direction: column;
    grid-column: 1/2;
    background-color: #333232;
    flex: 2 1;
    height: 100vh;
}

.caisse-content {
    grid-column: 2/11;
    height: 100vh;
    display: grid;
    position: relative;
}

.content-main {
    /*height: 100vh;*/
    display: grid;
    grid-template-columns: 130px 130px auto;
}

.caisse-main-menu-second {
    grid-column: 1/2;
    background-color: #AFD7FA;
}

.caisse-main-menu-third {
    grid-column: 2/3;
    background-color: #fffcfc;
}

.caisse-main-menu-middle-activity {
    grid-column: 3/17;
    background-color: #fffcfc;
}

.caisse-main-menu-middle {
    grid-column: 2/17;
    background-color: #fffcfc;
}

.caisse-main-body {
    grid-column: 1/11;
    background-color: rgba(255, 255, 255, 0.97);;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-body.with-margin {
    height: calc(100% - 66px);
}

.stat-nav-bar {
    position: absolute;
    bottom: 0;
    background-color: #333232;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Montserrat, serif;
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin: 0px 1px;
    padding: 10px;
    width: 100%;
}

.stat-bloc {
    width: 130px;
    background: #7EB5E5;
    border-radius: 5px;
    padding: 3px;
}

.stat-client {
    font-weight: 200;
    font-size: 14px;
    text-align: center;
}

.stat-client .image {
    margin: 0 auto !important;
    width: 20px;
}

.stat-article {
    font-weight: 200;
    font-size: 14px;
}


.caisse-main-menu-right {
    grid-column: 11/17;
    background: #333232;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main-menu-bottom {
    border-top: 1px solid #81818138;
}

.caisse-main-menu-top {
    flex: 1;
    height: 50%;
    overflow-x: hidden;
    overflow-y: auto;
}

.caisse-main *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 1px;
}

.caisse-main *::-webkit-scrollbar {
    width: 1px;
    background-color: #e40f0f44;
}

/* Print Ticket*/
.print-ticket {
    width: 44mm !important;
    padding: 0 !important;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
}

.round-btn.btn-green {
    color: white !important;
    background-color: #21ba45 !important;
}

.round-btn.btn-green:hover {
    background-color: #21ba45 !important;
}

.round-btn.btn-red {
    color: white !important;
    background-color: #db2828 !important;
}

.round-btn.btn-red:hover {
    background-color: #db2c1f !important;
}


.sold {
    font-style: italic;
    font-weight: 200;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #7EB5E5;
}

.welcome-caisse a {
    text-align: center;
}

.homepage-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}
