.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.loader-table-body {
    position: relative !important;
}

th.tab-header.bg-tab {
     padding: 8px !important;
 }

.dark tfoot.full-width tr th {
    background-color: #121212 !important;
}

.td-text {
    font-size: 1.2rem;
    min-height: 75px;
    padding-left: 8px !important;
    word-break:keep-all;
    text-overflow: ellipsis;
}

.tools a {
    align-self: center;
}

.with-dimmer {
    min-height: 80px;
    padding: 14px 8px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAnEAAABCCAMAAAAPInAgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZFOEY5NDREQTMxMUU0OEI0MDhCMEM5RjBBNDFEMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZFOEY5NTREQTMxMUU0OEI0MDhCMEM5RjBBNDFEMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0NkU4RjkyNERBMzExRTQ4QjQwOEIwQzlGMEE0MUQzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0NkU4RjkzNERBMzExRTQ4QjQwOEIwQzlGMEE0MUQzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+jI2B7AAAAF1QTFRFvL7AwMLEyMnL5ufoycrMxsjKwcPFzc7Q8PDx+fn68fHy0dLUyszNxsjJvsDB7+/w5OXmxMXH6uvrxsfJ+vr7wsTG+fn50NHTvsDCxcbIysvN7e3u4+Tl1dfY////DQ3b9AAAAB90Uk5T////////////////////////////////////////AM0ZdhAAAADiSURBVHja7NrHDcMwEEVBOknOOaf+y/TdhnkiFwI0U8Li3fany2kIUZpXmiSI0yoOxaE4UByKA8WhOBQHgcWtHIHQ4vbbEUR5PtIbIikOxaE4UByKA8WhOBQHikNxoDgUB4qjU8Xd0wD+Ws9LF2cDTNZUcSgOxSkOxaE4xaE4FIfiFIfiUJzi6GJxNzclZ1O6uKVPPhnX4p988xns41AcKA7FgeJQHIoDxaE4UByKA8WhOPpb3MECjHqa809xNsDULG6mOBSH4kBxKA4Uh+JQnOJQHL0pbrEbQy3t8bu4jwADAOWOuJvLET/EAAAAAElFTkSuQmCC);
}

.table-footer-component .text {
    color: #9fa2b4;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
    margin-left: 8px;
}

.table-footer span {
    color: #9fa2b4;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
}
.table-footer-component {
    white-space: nowrap;
}

.table-footer .table-footer-component {
    margin-right: 60px;
}

.table-footer-component .text,
.table-footer-component {
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
}

.table-footer-component i {
    color: #9fa2b4;
}

.full-width th {
    background: white !important;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: center;
}


/***  tabler pagibnation **/


.table-footer-component.table-pagination .ui.mini.pagination.menu {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    border: none;

    background: none;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a.active.item {
    border-radius: 4px !important;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a {
    color: #9fa2b4 !important;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
    border: none !important;
}

.table-footer-component.table-pagination .ui.mini.pagination.menu a::before {
    content: none
}


tr.empty-row img {
    width: 54px;
    height: 54px;
    opacity: .5;
    /* fill: red; */
    /* display: flex; */
}

tr.empty-row td {
}

.empty-row td p {
    font-family: Montserrat,serif;
    font-size: 18px;
    font-weight: 500;
    opacity: .7;
    color: #323232;
}

.empty-row {
    background-color: #25273303;
}

.table-footer-component.table-footer-total {
    /* margin-right: 54%; */
    display: flex;
    flex: 1;
}

tfoot.full-width th {
    padding: 12px !important;
}

.dark .empty-row td p {
    color: #fff;
}

.dark .empty-row td img {
    filter: invert(1);
}