.my-header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height, or 121% */
    color: #2c2c2c;
}

.divider-div {
    padding-bottom: 0.21428571rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    margin-bottom: 45px;
}

.dark .divider-div {
    padding-bottom: 0.21428571rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 45px;
}

.header-main {
    width: 100%;
    display: flex;
    margin-top: 56px;
    height: 62px;
    flex-direction: column;
}

.header-row {
    display: flex;
    padding-bottom: 12px;
    justify-content: space-between;
    flex-direction: row;
}
