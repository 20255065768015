/***Planning Page***/
.planning-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 34px;
}

.container-ar .tab-custom.modal-tab .ui.text.menu {
  flex-direction: row-reverse !important;
}

.container-ar .planning-container {
  flex-direction: row-reverse;
}

.competition-icon {
  background-color: mediumseagreen !important;
  width: calc(1.4rem - 2px) !important;
  height: calc(1.4rem - 2px) !important;
}

.vertical-padding {
  padding-top: 0 !important;
}

.header-search .custom-dropdown-main {
  min-height: 40px !important;
}

div#sticky-header th.fc-axis.fc-widget-header {
  width: 41px !important;
  background-color: transparent !important;
}

.recurrence-icon {
  position: absolute;
  bottom: 0.8rem;
  left: 0.2rem;
}

.input-modal .timeInput-container.custom-input.input-bg {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem !important;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

label.label-modal.no-padding.participant-margin {
  margin-left: 20px;
}

.planning-list {
  display: flex;
  width: 100%;
}

.tab-th {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  transform: translateY(35px);
  background-color: #ececec;
  cursor: pointer;
  height: 35px;
}

.tab-th.active {
  background-color: #9e94ff !important;
  color: #fff !important;
}

.planning-left {
  max-width: 230px;
  width: 230px;
  display: flex;
  transition: all 0.4s;
  flex-direction: column;
}

.fc th,
.fc td {
  white-space: nowrap;
  text-overflow: ellipsis;
}

th.fc-resource-cell.header-res {
  overflow: hidden;
}

.modal-container-column .timeInput-container.custom-input.input-bg.false {
  height: 40.7px !important;
  display: flex;
  align-items: center;
}

.planning-right {
  position: relative;
}

.event-overflow {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.input-modal .date-dropdown {
  min-width: 20px;
  width: 100% !important;
}

.reservation-modal .custom-dropdown-main {
  min-height: 36px !important;
}

.reservation-modal .custom-dropdown-container {
  width: 105% !important;
}

.planning-right-header {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-ar .planning-right-header {
  flex-direction: row-reverse;
}

.planning-right-header h2 {
  display: block;
  border: none !important;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem !important;
  text-align: center;
  vertical-align: center;
  color: #444c63;
  margin: 4px;
}

.dark .planning-header,
.dark .fc-toolbar {
  background-color: #333 !important;
}

.dark .planning-right-header h2 {
  color: white;
}

.dark .react-calendar {
  background-color: #2c2c2c !important;
  color: white;
  padding: 4px;
}

.planning-header-toggle {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #444c63;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
}

.container-ar .planning-header-toggle {
  flex-direction: row-reverse;
}

.planning-header {
  margin: 0 !important;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef1f7 !important;
  height: 90px;
}

.ajourdhui {
  background: #97b3ce !important;
  border-radius: 20px;
}

.ajourdhui:hover {
  background: #8da7ce !important;
  border-radius: 20px;
}

.error-msg-div {
  color: #9f3a38 !important;
  width: 30%;
  margin-left: auto;
  padding-bottom: 1rem;
}

.error-msg-div-opening {
  color: #9f3a38 !important;
  width: 30%;
  margin-left: 25%;
  padding-bottom: 1rem;
}

.planning-terrain {
  display: flex;
  flex-direction: column;
}

.planning-container-left {
  margin: 24px;
}

.display-none {
  display: none !important;
}

.planning-right-toggle {
  position: absolute;
  left: 10px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #444c63;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  top: 90px;
  z-index: 10;
}

.planning-terrain-header {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #444c63;
}

.planning-terrain-items {
  display: flex;
  flex-direction: column;
}

.container-ar .planning-terrain-items {
}

.planning-terrain-item {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8c97b2;
}

.container-ar .terrain-round {
  margin-right: 0;
  margin-left: 8px;
}

.terrain-title {
  flex: 1;
}

.terrain-round {
  display: inline-block;
  height: 12px !important;
  width: 12px !important;
  border-radius: 10px;
  margin-right: 8px;
  border: 1px solid #2c2c2c36;
}

.stats-round {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px;
  margin-right: 8px;
  padding-top: 2px;
}

.container-ar .stats-round {
  margin-right: 0;
  margin-left: 8px;
}

.planning_information_container span {
  display: block;
  text-align: center;
}

.planning-information-loader {
  margin-top: 23px;
}

/**Calendar**/
.react-calendar {
  font-family: Roboto, serif;
  width: 300px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #444c63;
  border: none !important;
  padding-bottom: 20px;
}

.react-calendar__month-view__weekdays {
  font-size: 12px !important;
  line-height: 14px;
  color: #8c97b2;
  opacity: 0.4;
  border-bottom: 2px solid #97b3ce;
}

.react-calendar button {
  height: 26px !important;
  /*margin: 3px 0 !important;*/
}

.react-calendar__tile--active {
  border-radius: 50%;
  background-color: #9e94ff !important;
}

button.ui.button.round-btn.ajourdhui.right-side-btn {
  position: absolute;
  left: 30px;
  top: 30px;
}

.react-calendar__tile--active:hover {
  background-color: #928ae6 !important;
}

.react-calendar__tile {
  padding: 0 !important;
  /*padding:18px 2px !important;*/
}

button.react-calendar__navigation__label {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #444c63;
}

/*** Full Calendar Layout     **/
.fc-event {
  border-radius: 3px;
  border: none !important;
  /*background: transparent !important;*/
}

.fc-axis {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border: none !important;
  color: #8c97b2;
  text-align: center;
  /*width: 65px !important;*/
}

.fc-row.fc-widget-header {
  margin-right: 0 !important;
}

.fc-scroller.fc-time-grid-container {
  overflow-y: hidden !important;
  height: auto !important;
}

.header-res {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #8c97b2;
  /*position: relative;*/
  border-color: #dadfea !important;
  padding: 10px !important;
}

.fc-time-grid .fc-slats td {
  /* height: 1.5em !important; */
}

.fc-center > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fc-toolbar.fc-header-toolbar {
  margin: 0 !important;
  padding: 24px;
  background-color: #eef1f7;
  height: 90px;
}

button.fc-next-button.fc-button.fc-button-primary,
button.fc-prev-button.fc-button.fc-button-primary,
button.fc-prev-button.btn.btn-primary,
button.fc-next-button.btn.btn-primary {
  padding: 4px 12px !important;
  border-radius: 100%;
  color: #8c97b2;
  margin: 8px;
  background-color: white;
  border: none;
}

button.fc-customNext-button.fc-button.fc-button-primary,
button.fc-customPrev-button.fc-button.fc-button-primary {
  padding: 1px 10px !important;
  border-radius: 100%;
  background-color: white;
  border-color: white !important;
}

button.fc-customHeader-button.fc-button.fc-button-primary {
  background-color: transparent !important;
  border: none !important;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em !important;
  line-height: 42px;
  /*display: flex;*/
  /*align-items: flex-end;*/
  text-align: right;
  color: #444c63;
}

.fc-button-primary:hover {
  color: #fff;
  background-color: #1e2b37;
  border-color: transparent !important;
}

.fc-icon {
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 14px;
  color: #8c97b2;
}

.actions.modal-history-footer {
  height: 64px !important;
}

.actions.reverse-modal-history-footer {
  height: 64px !important;
}

button.ui.secondary.button.btn-enregister {
  margin-left: 2.75em !important;
  margin-bottom: 0 !important;
  margin-top: 0.2em !important;
}

/********Event ****/
.event-container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Roboto, serif;
  height: 100%;
  font-style: normal;
  font-weight: 900;
  padding: 2px 2px;
  font-size: 10px;
  /* line-height: 20px; */
  color: #fff;
  overflow: hidden !important;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.event-container span {
  display: inline;
  padding-bottom: 0;
  font-size: 0.8em;
  font-weight: 400;
  line-snap: contain;
  white-space: pre-line;
}

.event-description {
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  height: 94%;
  width: calc(100% - 1.5rem);
  word-break: break-all;
  overflow-wrap: break-word;
  line-break: anywhere;
  overflow: hidden;
}

.event-description.venu-text {
  color: #000;
}

.event-container span {
  padding-bottom: 0;
}

.img-container {
  display: flex;
  flex-direction: column;
  width: 1.4rem;
  height: 94%;
  justify-content: space-between;
}

.event-container img {
  width: calc(1.4rem - 3px);
  height: calc(1.4rem - 3px);
  border-radius: 50%;
  padding: 2px;
  background-color: white;
}

.fc-nonbusiness.fc-bgevent {
  background: #ffafaf;
  opacity: 1;
}

.img-event {
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 24%!important;*/
  max-width: 25px;
  height: 24% !important;
  max-height: 25px !important;
  padding: 0.28em 0.05em;
  align-self: flex-end;
  border-radius: 50%;
}

.price-input {
  margin-left: 0 !important;
  padding-right: 0px !important;
}

.modal-tab .ui.bottom.attached.segment.active.tab {
  border: none;
}

.loading-deleted-reservation {
  position: relative;
  height: 52px;
  padding-top: 40px;
}

.reservation-modal-title {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row !important;
}

.reservation-modal-title span {
  max-width: 25%;
  color: #25273388;
  font-size: 1.2rem;
}

.reservation-modal-title h2 {
  flex: 1 !important;
  margin: 0 !important;
}

.history-modal-dropdown {
  width: 50% !important;
}

.tab-custom.modal-tab.modal-tab-player .item {
  border-bottom: none !important;
  /* background: #12121266; */
  /* color: white; */
}

.code-input {
  background: radial-gradient(
    ellipse at center,
    rgba(76, 76, 76, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(17, 17, 17, 1) 100%
  );
  background-size: cover;
  color: white !important;
  width: 100%;
  line-height: 1em;
  white-space: normal;
  font-size: 1em !important;
  outline: 0;
  transform: rotateZ(0);
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  letter-spacing: 1.8px;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

.top.aligned.row.row-player.border-bottom.no-padding-right {
  padding-bottom: 0;
  padding-top: 0;
}

.search-part .custom-dropdown-container {
  width: 87% !important;
}

img.modal-team-logo {
  width: 84px !important;
  height: 84px !important;
  border-radius: 50%;
}

.-close.planning-search-btn-focused {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

img.ui.image.modal-team-logo {
  width: 45px;
  height: 45px;
}

.calender-loading {
  width: 100%;
  height: 40px;
  background-color: #33333308;
  z-index: 1000;
  position: absolute;
  top: 130px;
}

.animated-border {
  position: relative;
}

.animated-border::before {
  content: "";
  top: 0;
  left: 0;
  border: 10px dashed transparent;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  animation-name: border-animation;
  animation-duration: 4s;
}

@keyframes border-animation {
  0% {
    border-color: transparent;
  }
  25% {
    border-color: white;
  }
  50% {
    border-color: transparent;
  }
  75% {
    border-color: white;
  }
  100% {
    border-color: transparent;
  }
}

.center-row {
  justify-content: center !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

/****Label ******/
.label-container {
  min-width: 240px;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.label-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #eef1f7;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px 10px 0 0;
  color: #000;
}

.label-content {
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  background-color: #f9f9f9;
}

.container-ar .label-content {
  flex-direction: column-reverse !important;
}

.label-content span {
  margin-bottom: 4px;
  font-weight: 400;
}

.label-name {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 14px;
  color: #444c63;
}

.bold-label {
  font-weight: 600 !important;
}

.label-footer {
  display: flex;
  flex-direction: column;
  background: #eef1f7;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 0 0 10px 10px;
}

.label-footer div {
  padding: 4px;
}

.supprimer {
  background-color: white !important;
  color: black !important;
}

.venu,
.round-btn.venu {
  background-color: #72f286 !important;
}

.pas-venu,
.round-btn.pas-venu {
  background-color: #4f4f4f !important;
  color: white !important;
}

.supprimer:hover {
  background-color: #fef9ff !important;
  color: #292929 !important;
}

.venu:hover,
.round-btn.venu:hover {
  background-color: #67f2a2 !important;
}

.pas-venu:hover,
.round-btn.pas-venu:hover {
  background-color: #494a4f !important;
}

.planning-btn-right {
  position: absolute;
  right: 0;
  top: 40px;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
}

.btn-right-plan {
  width: 40px;
  height: 40px;
  margin-right: 8px !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.btn-right-plan:hover {
  box-shadow: none !important;
  background: transparent !important;
  border: none !important;
}

.__react_component_tooltip {
  opacity: 1 !important;
  padding: 0 !important;
  border-radius: 10px;
}

.modal-history-container {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", serif;
}

.modal-history-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.modal-history-header > span {
  margin: 10px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #979797;
}

.search-input input {
  border-radius: 4px !important;
}

.reservation-modal .modal-history-footer,
.reservation-modal .reverse-modal-history-footer {
  padding: 0 !important;
  height: 75px;
  justify-content: center;
}

.modal-history-footer {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column !important;
  align-items: flex-start;
}

.reverse-modal-history-footer {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse !important;
  align-items: flex-start;
}

.actions.modal-history-footer .pagination {
  display: flex;
  flex-direction: row;
  box-shadow: none !important;
}

.modal-history-content {
  display: flex;
  flex-direction: column;
  min-height: 250px;
}

.modal-history-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.history-content {
  padding: 0 !important;
}

.history-item-status {
  width: 65px;
  height: 40px;
  /*background: forestgreen;*/
  border: 1px solid #eee;
  margin-right: 18px;
  border-radius: 4px;
}

.modal-history-item {
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
}

.history-item-right {
  display: flex;
  flex: 1;
  align-items: center;
}

.history-item-right > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.history-item-right > button {
  justify-self: flex-end;
}

.history-item-right span {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 4px;
  color: #2c2c2c !important;
}

.centered-title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.95) !important;
}

.input-modal {
  padding: 12px 0 12px 12px;
}

.input-modal .form {
  margin-bottom: 0 !important;
}

.prev-btn,
.next-btn {
  position: absolute;
  height: 100%;
  color: white !important;
  margin: 0 !important;
  border-radius: 0 !important;
  min-width: 134px;
  background-color: #dadfea !important;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 17px;
}

.input-modal .form-input-margin {
  margin-bottom: 0px !important;
}

.modal-history-footer .prev-btn {
  left: 0;
  top: 0;
  bottom: 0;
  border-bottom-left-radius: 4px !important;
}

.modal-history-footer .next-btn {
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom-right-radius: 4px !important;
}

.reverse-modal-history-footer .prev-btn {
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom-left-radius: 4px !important;
}

.reverse-modal-history-footer .next-btn {
  left: 0;
  top: 0;
  bottom: 0;
  border-bottom-right-radius: 4px !important;
}

.reverse-modal-history-footer .next-btn i {
  transform: rotate(180deg);
}

.reverse-modal-history-footer .prev-btn i {
  transform: rotate(180deg);
}

.input-modal input {
  padding: 8px 4px !important;
  width: 100% !important;
}

.input-modal .field label {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4e4c4c !important;
}

.input-modal .ui.grid + .grid {
  margin-top: 0;
}

.input-modal .ui.input {
  flex: 1 1;
  width: 101% !important;
}

.input-modal .ui.input input {
  width: 101% !important;
  height: 38px !important;
}

.input-modal .ui.input input[type="tel"] {
  width: auto !important;
}

.form-input-modal-line .modal-input .field input {
  width: 90% !important;
}

.form-input-inline-modal .field label {
  display: none !important;
}

.tab-custom.modal-tab > .menu {
  margin: 0 !important;
  display: flex !important;
  flex: 1 !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.tab-custom.modal-tab .menu a {
  flex: 1 !important;
  display: flex;
  justify-content: center;
  margin: 2px !important;
  border-bottom: 2px solid #dadfea;
}

.modal-col .ui.selection.dropdown {
  width: 100% !important;
  min-width: 100% !important;
}

.modal-title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #979797 !important;
}

.react-calendar__tile--active abbr {
  padding-right: 1px !important;
}

.modal-input textarea {
  max-width: 100%;
  flex: 1;
  width: 100% !important;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.btn-description {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
}

.no-style,
.no-style:focus {
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 0.28571429rem !important;
  width: 101% !important;
}

.modal-btn-close {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.venir {
  background-color: #7e72f2 !important;
  color: white !important;
}

.form-input-inline-modal {
  display: flex;
}

.header-search {
  padding-left: 12px;
  padding-top: 12px;
}

.header-search .ui.search.search-input {
  flex: 1 !important;
  padding-right: 18px;
}

.adding-client {
  display: flex;
  width: 70%;
  flex: 0.4 !important;
  justify-content: center;
}

.adding-client button {
  border: none !important;
}

.header-search .ui.right.icon.input {
  width: 100%;
}

.input-modal
  .form-input-inline-modal
  .form-input-inline:first-of-type
  .field
  div
  > input {
  margin-left: -10px !important;
}

.form-input-modal-line {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
}

.form-input-modal-line .form-input-inline {
  margin-bottom: 0 !important;
}

.form-input-inline-modal {
  margin-bottom: 0 !important;
}

.box-info {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fdf9ff;
  padding: 8px 14px;
  border: 1px solid #dadfea;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.reservation-warning {
  position: absolute;
  right: 10%;
  top: 50%;
  color: black;
  transform: translateY(-50%);
  font-size: 2rem !important;
}

.reservation-modal .tab-custom {
  min-height: 480px;
  overflow-y: hidden !important;
}

.input-modal .ui.selection.dropdown {
  min-width: 12px !important;
  width: 104.8% !important;
}

.historique-container {
  padding: 12px;
}

.btn-enregister {
  align-self: center;
  justify-self: center;
  background: #7e72f2 !important;
  width: 160px;
  /*height: 32px;*/
  font-family: Montserrat, serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border-radius: 4px !important;
}

.info-title {
  padding-bottom: 11px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  justify-self: center;
  align-self: center;
  color: #979797;
}

.info-description {
  padding-bottom: 11px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.link-btn {
  margin-top: 8px !important;
  margin-left: 8px;
}

.form-input-inline-modal > span,
.label-modal {
  font-family: Montserrat, serif;
  font-style: normal;
  font-size: 14px;
  color: #4e4c4c !important;
}

.results .transition {
  height: 320px !important;
  overflow-y: auto !important;
}

.modal-container-column {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
}

.modal-right,
.modal-left {
  display: flex;
  /*padding-bottom:120px;*/
  min-height: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.btn-add-player {
  text-align: center;
}

.modal-col {
  display: flex;
  /*justify-content: space-between;*/
}

.padding-modal {
  margin: 0 !important;
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 2px !important;
  padding-right: 0 !important;
}

.no-padding {
  padding: 4px !important;
}

.modal-tab .ui.grid {
  width: 100%;
}

.modal .input {
  width: 100% !important;
}

.padding-modal {
}

span.info-description.btn-description button {
  margin-bottom: 2px;
}

.modal-tab .ui.text.menu {
  margin: 0 !important;
  flex: 0.1 !important;
  min-height: 50px;
}

.ui.middle.aligned.sixteen.column.grid {
  width: 100%;
}

.eight.wide.column.link-btn {
  margin: 0 !important;
  padding-top: 4px !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.modal-border-left {
  border-right: 1px solid rgba(34, 36, 38, 0.09);
}

.modal-border-right {
  border-left: 1px solid rgba(34, 36, 38, 0.09);
}

.modal-right {
  border-left: 0.5px solid rgba(34, 36, 38, 0.09);
}

.modal-input.bottom-modal {
  position: absolute;
  bottom: 18px;
  left: 18px;
  right: 18px;
}

/*Custom Context Menu */
.menu-context {
  width: 140px;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.02);
  position: absolute;
  overflow: hidden;
  display: none;
  transition: height 0.5s;
  z-index: 100;
  /*color:white ;*/
  background-color: white;
}

.menu-context .menu-options {
  list-style: none;
  padding-top: 8px;
}

.menu-context .menu-option {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  color: #2c2c2c;
}

.menu-context .menu-option i {
  color: #2c2c2c;
  opacity: 0.8;
}

.menu-context .menu-option:hover {
  background: rgba(0, 0, 0, 0.2);
}

li.menu-option.menu-disabled {
  color: darkgray;
  user-select: none;
  cursor: not-allowed;
}

span.menu-spinner {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
}

li.menu-option.menu-disabled:hover {
  background: transparent;
}

li.menu-option.menu-disabled i {
  color: darkgray;
}

/**/

.fc-bg {
  z-index: 1 !important;
}

@media screen and (max-width: 980px) {
  .modal-border-left,
  .modal-border-right {
    border-left: none;
    border-right: none;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(35, 35, 35, 0.09);
  }
}

@media only screen and (max-width: 1024px) {
  .caisse-main div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 8%) !important;
    width: calc(100% - (3.2rem + 0px + 8%)) !important;
    display: flex;
  }

  .caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 220px + 8%) !important;
    width: calc(100% - (3.2rem + 220px + 8%)) !important;
    display: flex;
  }
}

@media only screen and (max-width: 840px) {
  .planning-container {
    flex-direction: column;
  }

  .mobile-layout .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 60px) !important;
    width: calc(100% - (3.2rem + 0px + 60px)) !important;
    display: flex;
  }

  .caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
    left: calc(1.6rem + 0px + 8%) !important;
    width: calc(100% - (3.2rem + 0px + 8%)) !important;
    display: flex;
  }

  .planning-left {
    max-width: 100%;
    width: 100% !important;
    display: flex;
    flex: 1 !important;
    flex-direction: row !important;
    /*display: none !important;*/
  }

  .planning-container-left {
    margin-top: 24px;
  }

  .planning-container-left > div {
    margin-left: auto;
    margin-right: auto;
  }

  .planning-right-header {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%) translateY(-50%) !important;
    display: flex;
    width: 320px;
    justify-content: flex-start;
    align-items: center;
  }
}

.input-planning-name {
  display: flex;
}

.input-planning-name *:first-child {
  margin-right: 8px;
}

.input-planning-name *:last-child {
  margin-left: 8px;
}

.container-ar .input-planning-name *:first-child {
  margin-right: 0px;
  margin-left: 8px;
}

.container-ar .input-planning-name *:last-child {
  margin-right: 8px;
  margin-left: 0px;
}

.reccurence-planning {
  background: #ffffff;
  padding: 8px 14px;
  border: 1px solid #dadfea;
  margin-top: 14px;
  margin-bottom: 14px;
}

.inline.fields.choice_recurrence {
  display: flex;
}

.inline.fields.choice_recurrence .field {
  margin: 14px 4px;
}

.five.wide.column {
  padding-right: 0px !important;
}

.padded-top {
  padding-top: 18px !important;
}

.padding-bottom {
  padding-top: 12px !important;
  padding-bottom: 35px !important;
}

.planning-search-btn {
  width: 100%;
  height: 37px;
  background: #1c1c1ccc;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 5px;
  margin-left: 0 !important;
  border-radius: 2px;
}

.planning-search-btn:focus {
  border: 1px solid #7e72f2;
}

i.ellipsis.vertical.icon.padding-dots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7e72f2;
}

.padding-dots > i {
  display: flex;
  color: #7e72f2;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1em;
  margin-top: 8px !important;
}

.planning-search-btn-focused {
  background-color: #ffffff44;
  color: #000;
  height: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.capteur-drp {
  width: 100% !important;
  min-width: 100% !important;
}

.search-part {
  display: flex;
}

.search-part .planning-search-btn.planning-search-btn-focused {
  display: flex;
  flex: 1;
  background-color: #323232;
  border-radius: 0;
  height: 100%;
  min-height: 40px;
  max-width: 46px;
  color: white;
}

.row-user {
  display: flex;
  align-items: center;
  font-size: 1em !important;
}

.row-user img {
  width: 24px;
  height: 24px;
}

.search-part .search-input {
  min-width: 80%;
}

.capteur-drp .custom-dropdown-control-last i {
  font-size: 0.8em !important;
}

.search-part .custom-dropdown-main {
  max-height: 39px !important;
}

img.ui.image.modal-team-logo {
  width: 84px;
  height: 84px;
  border-radius: 84px;
}

.label-header img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

@media screen and (max-width: 1200px) {
  .padding-bottom {
    margin-left: 12px;
  }
}

.currency-status {
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-bottom: 1px;
  text-align: center;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 670px) {
  .planning-right-header {
    display: flex;
    width: 145px;
  }

  .planning-right-header h2 {
    font-size: 1.4rem !important;
  }
}

div#sticky-header .fc-row.fc-widget-header {
  position: fixed;
  background: #fff;
  top: 0;
  left: calc(1.6rem + 0px + 250px);
  width: calc(100% - (3.2rem + 0px + 250px));
  display: flex;
  z-index: 1000;
}

div#sticky-header .fc-row.fc-widget-header table {
  width: 100%;
  table-layout: fixed;
  background-color: #dadfea !important;
}

div#sticky-header .fc-row.fc-widget-header table td {
  width: 100%;
}

div#sticky-header {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.caisse-main div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.4rem + 0px + 6.5%);
  width: calc(100% - (3rem + 0px + 6.5%));
  display: flex;
}

.mobile-layout div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 0px + 60px);
  width: calc(100% - (3.2rem + 0px + 60px));
  display: flex;
}

.mobile-layout .planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 230px + 60px);
  width: calc(100% - (3.2rem + 230px + 60px));
  display: flex;
}

.planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.6rem + 230px + 250px);
  width: calc(100% - (3.2rem + 230px + 250px));
  display: flex;
}

div#sticky-header .fc-row.fc-widget-header {
  height: 36px;
  margin-top: -4px;
}

div#sticky-header .fc-row.fc-widget-header .header-res {
  background-color: #fff;
}

div#sticky-header .fc-axis.fc-widget-header {
  background-color: #fff;
  margin-left: -4px;
  width: 38px;
}

.mobile-layout div#sticky-header .fc-row.fc-widget-header .header-res {
}

.caisse-main .planning-open div#sticky-header .fc-row.fc-widget-header {
  left: calc(1.4rem + 230px + 6.5%);
  width: calc(100% - (3rem + 230px + 6.5%));
  display: flex;
}

@media only screen and (max-width: 480px) {
  .planning-btn-right {
    display: none;
  }
  .planning-right-header {
    margin-left: 2.8rem;
  }
}
