.custom-text-input-container div.input-bg {
    background-color: #eef1f7;
    flex: 1 1;
    padding: 0.8em 4px !important;
    border-radius: 2px !important;
    border: none;
}

.custom-p{
    min-height:19px;
}

.custom-text-input-container{
    position: relative;
}

.custom-text-input-dropdown{
    position: absolute;
    top: 16%;
    right: 20px;
}