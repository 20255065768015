.modal-reservation-border{
    border-left: 1px solid #DFE0EB;
    height:100%;
}

.modal-caisse-reservation{
    width: 80% !important;
    padding:12px !important;
    min-height: 100%;
}
.modal-caisse-reservation .input-bg,.modal-caisse-reservation .custom-dropdown-container {
    width: 104% !important;
}

.modal-caisse-reservation .ui.input{
    width: 101% !important;
}
.modal-caisse-reservation .ui.disabled.input{
    width: 101% !important;
}


.modal-caisse-reservation .timeInput-container.custom-input.input-bg.false {
    width: 102% !important;
}

.modal-caisse-left{
    padding-right:30px !important;
}
.modal-caisse-reservation .eight.wide.column.link-btn {
    margin: 8px !important;
    padding-top: 4px !important;
}

.modal-caisse-reservation .full-width-input{
    width: 100% !important;
}

.modal-caisse-reservation .ui.middle.aligned.sixteen.column.grid {
    width: auto;
}

@media screen and (max-width: 1000px ){
    .modal-reservation-border{
        border-left: none;
        border-top: 1px solid #DFE0EB;
    }
}