@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Montserrat, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: auto !important;
    font-size: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.round-btn {
    border-radius: 20px !important;
    background-color: #9e94ff !important;
    color: white !important;
}

.round-btn:hover {
    background-color: rgb(134, 123, 237) !important;
}

.export-btn {
    border-radius: 20px !important;
    float: right;
    /* color: white !important;
    background: rgb(33, 200, 246) !important; */
}

.table-container {
    overflow-x: auto;
    margin-top: 18px;
}

.dark .table-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    outline: 1px solid slategrey;
}

.timeInput-container.custom-input.input-bg.false.search-input.search-date {
    background: #fff !important;
    border: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: .28571429rem !important;
}


.div-form {
    display: flex;
    margin: 16px 45px;
    min-width: 450px;
    width: 100%;
    flex-direction: column;
}

.btn-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.btn-right button {
    margin-left: 14px;
}

.div-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dark .responsive-filter {
    background-color: #323232 !important;
}

.tab-header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600 !important;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.2px;
    color: #666 !important;
    word-break: keep-all;
    word-spacing: normal;
    white-space: nowrap;
}

.bg-tab {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* padding-left: 8px !important; */
    background-color: #fff !important;
}

.no-border {
    /* border: none; */
}

.td-text {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: 0.2px;

    /* grayscale / black */

    color: #252733;
}

.filtre {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.dark .filtre {
    background-color: #212121;
}

.dark .filtre > span {
    color: white;
}

.dark .filtre .secondary {
    background-color: white !important;
    color: black !important;
}

.dark .filtre .secondary:hover {
    background-color: #e8e8e8 !important;
    color: black !important;
}

.filtre > span {
    font-family: Montserrat, Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    margin-right: 8px;
    color: #000000;
}

.filtre-input {
    display: flex;
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    font-family: Montserrat, serif;
    font-size: 18px;
    color: #c4c4c4;
}

.filtre-dropdown {
    min-height: auto !important;
    height: 45px;
}

.filtre-dropdown .text {
    top: -3px;
    font-family: Montserrat, serif;
    font-size: 16px;
}

.filtre-dropdown i.dropdown.icon {
    top: 10px !important;
}

.td-img {
    display: flex;
    align-items: center;
}

.td-img img {
    margin-right: 4px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
}

.ui.form {
    flex: 1;
}

.ui .button .round-btn {
    justify-self: flex-start;
    align-self: flex-start;
}

.container-ar .ui.grid {
    flex-direction: row-reverse !important;
}

.round-btn {
    border-radius: 20px !important;
    background-color: #9e94ff !important;
    color: white !important;
}

.venu {
    background-color: #72f286 !important;
}

.avatar-cover{
    object-fit: cover;
}

::-webkit-scrollbar {
    width: 8px;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

::-webkit-scrollbar-button {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(126, 114, 242, 0.578726) !important;
    border-radius: 20px;
}

@media screen and (max-width: 800px) {
    html{
        font-size: 12px;
    }
    .filtre {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .filtre .dropdown {
        width: 100%;
        margin-bottom: 2px;
    }

    .filtre .input,
    .filtre input {
        width: 100%;
        margin-left: 0 !important;
    }
}
@media screen and (max-width:300px) {
    html{
        font-size: 10px;
    }
}
