.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
}

.dark .navbar-complex-container {
    background-color: #323232;
    /* border: 1px solid #323232; */
}

.text.navbar-lang {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 900;
    /*height: 20px;*/
    padding-bottom: 8px;
}

.navbar-lang span {
    padding: 4px;
}

.header-date {
    flex: 2;
    font-family: Montserrat, serif;
    font-weight: 300;
    font-size: 1.6rem;
}

.navbar-lang span:hover {
    color: #7a97f4;
}

.navbar-lang-active {
    color: #7e72f2;
}

.navbar-avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.dark .navbar-complex-container {
    background: #121212;
}

.custom-navbar-item:hover {
    background-color: white !important;
}

.ui.dropdown .menu > .item.custom-navbar-item {
    padding: 0 !important;
}

.navbar-left,
.navbar-right {
    display: flex;
    flex: 2;
    align-items: center;
}

img.ui.circular.image.navbar-avatar {
    min-width: 34px;
}

.navbar-right {
    flex: 1;
    justify-content: flex-end;
    margin-right: 12px;
}

.navbar-icon {
    position: relative;
    margin-right: 12px !important;
    margin-left: 12px !important;
}
.badge-nb{
    position: absolute;
    top: -1px;
    right: 1px;
    background-color: #db2828;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.name {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 0 12px;
    cursor: pointer;
    user-select: none;
    /* identical to box height, or 121% */

    color: #2c2c2c;
}

.header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 17px;
    /* or 71% */

    color: #2c2c2c;
}

.navbar-icon {
    font-weight: 400;
    font-size: 1.3em !important;
}

i {
    cursor: pointer;
}

.switch-mode {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-ar .switch-mode {
    flex-direction: row-reverse;
}

.navbar-container {
    width: 100%;
    position: relative;
}

.profile-dropdown .left.visible.menu.transition {
    margin-top: 4px;
}

.navbar-ellipsis {
    position: relative;
}

.navbar-complex-container {
    z-index: 80;
    position: absolute;
    top: 45px;
    left: -330px;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.container-ar .navbar-complex-container {
    z-index: 80;
    position: absolute;
    top: 45px;
    right: -330px;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e5e588;
    box-shadow: 0 0 2px #32323277;
}

.navbar-complex-header {
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e5e588;
}

.navbar-complex-item {
    display: flex;
    align-items: center;
    padding: 14px 8px;
    border-bottom: 1px solid #e5e5e588;
    cursor: pointer;
}
.container-ar .navbar-complex-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 14px 8px;
    border-bottom: 1px solid #e5e5e588;
    cursor: pointer;
}

.navbar-complex-item:hover {
    background-color: #e5e5e577;
}

.navbar-complex-item:last-child {
    border-bottom: none;
}

.navbar-complex-item span {
    padding-left: 12px;
    font-family: Montserrat, serif;
    font-size: 16px;
    flex: 3;
    text-align: left;
}

.navbar-complex-selected {
    text-align: right !important;
    flex: 1;
}


.side-menu-item-link {
    margin: 0.8em;
}

.side-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80px !important;
    height: 80px !important;
    color: #ececec;
    transition: all .2s;

    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.side-menu-item-link :hover {
    transform: scale(1.1);
}

.side-menu-item h3 {
    word-break: break-word;
}

.side-menu-item-link :hover h3 {
    display: block;
}

.side-menu-item h3 {
    margin: 2px 0 0;
    display: none;
    font-size: 10px;
    transition: all .5s;
    padding: 4px;
    text-align: center;
}

.side-menu-item img {
    width: 30px;
    height: 30px;
}

.module-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}

.module-container a {
    display: block;
    margin: .8em;
}